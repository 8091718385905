import axiosInstance, { AxiosError, AxiosHeaders, AxiosInstance } from 'axios';
import mem from 'mem';
import { clearToken, updateTokens } from '../Slices/AuthSlice';
import { StoreType } from '../Store';
import { API_PATH } from './constants';

export let axios: AxiosInstance = axiosInstance.create();
export const setupAxiosInstance = (store: StoreType) => {
    axios = axiosInstance.create();

    axios.interceptors.request.use(
        async (req) => {
            (req.headers as unknown as AxiosHeaders).set(
                'Authorization',
                `Bearer ${store.getState().auth.token}`
            );

            return req;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        async (res) => {
            return res;
        },
        async (error) => {
            const { status } = error?.response;

            const config = error?.config;

            if (status === 401 && !config?.sent) {
                config.sent = true;
                const result = await memoizedRefreshToken();

                if (result?.token) {
                    store.dispatch(updateTokens(result));
                    config.headers = {
                        ...config.headers,
                        authorization: `Bearer ${result?.token}`,
                    };
                    return axios(config);
                }
            } else if (config?.sent) {
                store.dispatch(clearToken());
            }
            return Promise.reject(error);
        }
    );
};

const refreshToken = async () => {
    const localRefreshToken = localStorage.getItem('refreshToken');

    try {
        const response = await axios.post(`${API_PATH}/auth/validate_refresh`, {
            token: localRefreshToken,
        });

        const { token, refreshToken, role } = response.data;

        if (!token || !refreshToken || !role) {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('role');
        }

        return {
            token,
            refreshToken,
            role,
        };
    } catch (error) {
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('role');
    }
};

export const axiosErrorHandler = (e: any) => {
    interface BackendError {
        message: string;
    }

    if (axiosInstance.isAxiosError(e)) {
        const error = e as AxiosError<BackendError>;
        return error?.response?.data?.message;
    } else return e;
};

const MAX_TOKEN_REFRESH_TIME = 25000;

export const memoizedRefreshToken = mem(refreshToken, {
    maxAge: MAX_TOKEN_REFRESH_TIME,
});
