import { FORM_STATUS } from '../../types';
import StatusLabel from '../StatusLabel';

import styles from './styles.module.scss';

type FormStatusProps = {
    appId: string;
    name: string;
    fein: string;
    ssn: string;
    postmarkDate: string;
    determinationDate: string;
    status: FORM_STATUS;
    signedBy: string;
};

const FormStatus: React.FC<FormStatusProps> = ({
    appId,
    name,
    fein,
    ssn,
    postmarkDate,
    determinationDate,
    status,
    signedBy,
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.formColumn}>
                <div className={styles.formItem}>
                    <div className={styles.formValueLabel}>App ID</div>
                    <div className={styles.formValue}>{appId}</div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.formValueLabel}>Name</div>
                    <div className={styles.formValue}>{name}</div>
                </div>
            </div>

            <div className={styles.formColumn}>
                <div className={styles.formItem}>
                    <div className={styles.formValueLabel}>FEIN</div>
                    <div className={styles.formValue}>{fein}</div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.formValueLabel}>SSN</div>
                    <div className={styles.formValue}>{ssn}</div>
                </div>
            </div>

            <div className={styles.formColumn}>
                <div className={styles.formItem}>
                    <div className={styles.formValueLabel}>Postmark Date</div>
                    <div className={styles.formValue}>
                        {new Date(postmarkDate).toLocaleDateString('en-US')}
                    </div>
                </div>
                <div className={styles.formItem}>
                    <div className={styles.formValueLabel}>
                        Determination Date
                    </div>
                    <div className={styles.formValue}>
                        {new Date(determinationDate).toLocaleDateString(
                            'en-US'
                        )}
                    </div>
                </div>
            </div>

            <div className={styles.formColumn}>
                <div className={styles.formItem}>
                    <div className={styles.formValueLabel}>Status</div>
                    <StatusLabel status={status} />
                </div>
                <div className={styles.formItem}>
                    <div className={styles.formValueLabel}>Signed by</div>
                    <div className={styles.formValue}>{signedBy}</div>
                </div>
            </div>
        </div>
    );
};

export default FormStatus;
