import * as yup from 'yup';
import { US_STATE_CODES } from '../../Common/common';

const commonRule = yup // for 12, 18, 19
	.date()
	.typeError('Should be a valid date in MM/DD/YYYY format')
	.max(new Date(), 'Date should be before current day');

const validationSchema = yup.object().shape({
	txtFirstName: yup
		.string()
		.matches(
			/^[A-Za-z '-.]{1,30}$/i,
			`The name can contain only letters, a space, a period, a hyphen, an apostrophe and/or a dash`,
		)
		.required(
			`The name can contain only letters, a space, a period, a hyphen, an apostrophe and/or a dash`,
		),
	txtLastName: yup
		.string()
		.matches(
			/^[A-Za-z '-.]{1,30}$/i,
			`Last name may only contain letters, space, period, apostrophe, and/or dash`,
		)
		.required(
			`The name can contain only letters, a space, a period, a hyphen, an apostrophe and/or a dash`,
		),
	txtSSN: yup
		.string()
		.matches(/^(\d{3}-\d{2}-\d{4})$/, 'Must be in format (xxx-xx-xxxx)')
		.max(11, 'Must be max 11 symbols')
		.required('Required'),
	txtStreet: yup
		.string()
		.matches(
			/^[a-z0-9 ,.'\-#/()]+$/i,
			`Street adress may only contain letters, numbers, and the following: , . ' - # / ( )`,
		)
		.required(
			`Street adress is required and may only contain letters, numbers, and the following: , . ' - # / ( )`,
		),
	txtCity: yup
		.string()
		.matches(
			/^[a-z .'-]+$/i,
			`City or town may only contain letters, space, period, apostrophe, and/or dash.`,
		)
		.required(
			`City or town is required and may only contain letters, space, period, apostrophe, and/or dash.`,
		),
	txtState: yup
		.string()
		.min(2, 'Must be exactly 2 symbols')
		.max(2, 'Must be exactly 2 symbols')
		.required(
			'Postal state code is required. US State code must be 2 letter abbreviation of US State or territory.',
		)
		.test(
			'is valid US state',
			'US State code must be 2 letter abbreviation of US State or territory.',
			(value) => {
				return US_STATE_CODES.includes(value?.toUpperCase() || '');
			},
		),
	txtZip: yup
		.string()
		.required('Required')
		.matches(/^[0-9]+$/, 'Must be only digits')
		.min(5, 'Must be exactly 5 digits')
		.max(5, 'Must be exactly 5 digits'),

	txtFEIN: yup
		.string()
		.matches(/^(\d{2}-\d{7})$/, 'Must be in format (xx-xxxxxxx)')
		.max(10, 'Must be max 10 symbols')
		.required('Required'),
	txtErName: yup
		.string()
		.matches(
			/^[a-z ,.?'";:!@#$%&*()_=+-]+$/i,
			`Employer's name may only contain letters and the following: , . ? / ' " ; : ! @ # $ % & * ( ) - _ = +`,
		)
		.required(
			`Employer's name is required and may only contain letters and the following: , . ? / ' " ; : ! @ # $ % & * ( ) - _ = +`,
		),
	txtErPhone: yup
		.string()
		.matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Must be in format ((xxx) xxx-xxxx)')
		.max(14, 'Must be max 14 symbols')
		.required(
			'Employer phone number is required. Phone number must begin with a 3 digit area code followed by a 7 digit number.',
		),
	txtErStreet: yup
		.string()
		.matches(
			/^[a-z0-9 ,.'\-#/()]+$/i,
			`Street adress may only contain letters, numbers, and the following: , . ' - # / ( )`,
		)
		.required(
			`Street adress is required and may only contain letters, numbers, and the following: , . ' - # / ( )`,
		),
	txtErCity: yup
		.string()
		.matches(
			/^[a-z .'-]+$/i,
			`City or town may only contain letters, space, period, apostrophe, and/or dash.`,
		)
		.required(
			`City or town is required and may only contain letters, space, period, apostrophe, and/or dash.`,
		),
	txtErState: yup
		.string()
		.min(2, 'Must be exactly 2 symbols')
		.max(2, 'Must be exactly 2 symbols')
		.required(
			'Postal state code is required. US State code must be 2 letter abbreviation of US State or territory.',
		)
		.test(
			'is valid US state',
			'US State code must be 2 letter abbreviation of US State or territory.',
			(value) => {
				return US_STATE_CODES.includes(value?.toUpperCase() || '');
			},
		),
	txtErZip: yup
		.string()
		.required('Required')
		.matches(/^[0-9]+$/, 'Must be only digits')
		.min(5, 'Must be exactly 5 digits')
		.max(5, 'Must be exactly 5 digits'),
	txtContactName: yup
		.string()
		.matches(
			/^[a-z ,.'-]+$/i,
			`Contact name may only contain letters, space, period, apostrophe, and/or dash`,
		)
		.required(
			`Contact name is required and may only contain letters, space, period, apostrophe, and/or dash`,
		),
	txtContactPhone: yup
		.string()
		.matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Must be in format ((xxx) xxx-xxxx)')
		.max(14, 'Must be max 14 symbols')
		.required(
			'Employer phone number is required. Phone number must begin with a 3 digit area code followed by a 7 digit number.',
		),
	txtContactStreet: yup
		.string()
		.matches(
			/^[a-z0-9 ,.'\-#/()]+$/i,
			`Street adress may only contain letters, numbers, and the following: , . ' - # / ( )`,
		)
		.required(
			`Street adress is required and may only contain letters, numbers, and the following: , . ' - # / ( )`,
		),
	txtContactCity: yup
		.string()
		.matches(
			/^[a-z .'-]+$/i,
			`City or town may only contain letters, space, period, apostrophe, and/or dash.`,
		)
		.required(
			`City or town is required and may only contain letters, space, period, apostrophe, and/or dash.`,
		),
	txtContactState: yup
		.string()
		.min(2, 'Must be exactly 2 symbols')
		.max(2, 'Must be exactly 2 symbols')
		.required(
			'Postal state code is required. US State code must be 2 letter abbreviation of US State or territory.',
		)
		.test(
			'is valid US state',
			'US State code must be 2 letter abbreviation of US State or territory.',
			(value) => {
				return US_STATE_CODES.includes(value?.toUpperCase() || '');
			},
		),
	txtContactZip: yup
		.string()
		.required('Required')
		.matches(/^[0-9]+$/, 'Must be only digits')
		.min(5, 'Must be exactly 5 digits')
		.max(5, 'Must be exactly 5 digits'),

	txtInfoDate: yup
		.string()
		.matches(
			/^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/,
			'Should be a valid date in MM/DD/YYYY format',
		)
		.test('is after 01/01/2014', 'Info date must be 01/01/2014 or later', (value) => {
			return new Date(value || '') >= new Date('01/01/2014');
		})
		.test('is after 01/01/2014', 'Info date should not be date in future', (value) => {
			return new Date(value || '') <= new Date();
		})
		.required('Required'),
	txtOfferDate: yup
		.string()
		.matches(
			/^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/,
			'Should be a valid date in MM/DD/YYYY format',
		)
		.test('is after 01/01/2014', 'Offer date must be 01/01/2014 or later', (value) => {
			return new Date(value || '') >= new Date('01/01/2014');
		})
		.test('is after 01/01/2014', 'Offer date should not be date in future', (value) => {
			return new Date(value || '') <= new Date();
		})
		.required('Required'),
	txtHireDate: yup
		.string()
		.matches(
			/^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/,
			'Should be a valid date in MM/DD/YYYY format',
		)
		.test('is after 01/01/2014', 'Hire date must be 01/01/2014 or later', (value) => {
			return new Date(value || '') >= new Date('01/01/2014');
		})
		.test('is after 01/01/2014', 'Hire date should not be date in future', (value) => {
			return new Date(value || '') <= new Date();
		})
		.required('Required'),

	txtStartDate: yup
		.string()
		.matches(
			/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/,
			'Should be a valid date in MM/DD/YYYY format',
		)
		.test('is after 01/01/2014', 'Date must be 01/01/2014 or later', (value) => {
			return new Date(value || '') >= new Date('01/01/2014');
		})
		.test('is after 01/01/2014', 'Start date should not be date in future', (value) => {
			return new Date(value || '') <= new Date();
		})
		.required('Required'),

	optPreviousEmployer: yup.string().required('Required'), //8

	optReceivingWages: yup.string().when('optPreviousEmployer', {
		is: (value: string) => value === 'no',
		then: yup.string().required('Required'),
		otherwise: yup.string().notRequired(),
	}),

	txtLastEmploymentDate: yup
		.date()
		.typeError('Should be a valid date in MM/DD/YYYY format')
		.max(new Date(), 'Date should be before current day')
		.when('optPreviousEmployer', {
			is: (value: string) => value === 'yes',
			then: yup.date().required('Required'),
			otherwise: yup.date().notRequired(),
		}),

	txtStartWage: yup
		.number()
		.min(7.5, 'Should be at least 7.5 USD')
		.required('Required and should be at least 7.5 USD'), //8
	ddlOccupation: yup.string().required('Required'), //11

	optVeteran: yup //13
		.string()
		.required('Required')
		.test(
			'checked answers',
			'Answer cannot be "No" while answer to check item 3, 4, or 5 is "Yes"',
			(value, context) => {
				const isInvalid =
					value === 'no' &&
					(context.parent.chkItem3 || context.parent.chkItem4 || context.parent.chkItem5);
				return !isInvalid;
			},
		),

	txtDischargeDate: yup.string().when('optVeteran', {
		is: (value: string) => value === 'yes',
		then: yup.string().required('Required'),
		otherwise: yup.string().notRequired(),
	}),

	optVeteranReceivedSnap: yup.string().when('optVeteran', {
		//13
		is: (value: string) => value === 'yes',
		then: yup.string().required('Required'),
		otherwise: yup.string().notRequired(),
	}),

	txtVeteranSnapPrimaryRecipient: yup //13
		.string()
		.when(['optVeteran', 'optVeteranReceivedSnap'], {
			is: (optVeteran: string, optVeteranReceivedSnap: string) =>
				optVeteran === 'yes' && optVeteranReceivedSnap === 'yes',
			then: yup.string().required('Required'),
			otherwise: yup.string().notRequired(),
		}),
	txtVeteranSnapRecipientLocation: yup //13
		.string()
		.when(['optVeteran', 'optVeteranReceivedSnap'], {
			is: (optVeteran: string, optVeteranReceivedSnap: string) =>
				optVeteran === 'yes' && optVeteranReceivedSnap === 'yes',
			then: yup.string().required('Required'),
			otherwise: yup.string().notRequired(),
		}),

	optVeteranDisability: yup.string().when('optVeteran', {
		//13
		is: (value: string) => value === 'yes',
		then: yup.string().required('Required'),
		otherwise: yup.string().notRequired(),
	}),

	optVeteranDischarged: yup //13
		.string()
		.when(['optVeteran', 'optVeteranDisability'], {
			is: (optVeteran: string, optVeteranDisability: string) =>
				optVeteran === 'yes' && optVeteranDisability === 'yes',
			then: yup.string().required('Required'),
			otherwise: yup.string().notRequired(),
		}),
	optVeteranUnemployed: yup //13
		.string()
		.when(['optVeteran', 'optVeteranDisability'], {
			is: (optVeteran: string, optVeteranDisability: string) =>
				optVeteran === 'yes' && optVeteranDisability === 'yes',
			then: yup.string().required('Required'),
			otherwise: yup.string().notRequired(),
		}),

	optSnap6Months: yup //14
		.string()
		.required('Required'),
	optSnap3Months: yup //14
		.string()
		.required('Required'),
	txtSnapPrimaryRecipient: yup //14
		.string()
		.when(['optSnap6Months', 'optSnap3Months'], {
			is: (optSnap6Months: string, optSnap3Months: string) =>
				optSnap6Months === 'yes' || optSnap3Months === 'yes',
			then: yup.string().required('Required'),
			otherwise: yup.string().notRequired(),
		}),
	txtSnapRecipientLocation: yup //14
		.string()
		.when(['optSnap6Months', 'optSnap3Months'], {
			is: (optSnap6Months: string, optSnap3Months: string) =>
				optSnap6Months === 'yes' || optSnap3Months === 'yes',
			then: yup.string().required('Required'),
			otherwise: yup.string().notRequired(),
		}),

	// optAssignmentOrganization: yup //15
	//     .string()
	//     .required('Required'),

	optTanf18Months: yup //16
		.string()
		.required('Required'),
	optTanfAny18Months: yup //16
		.string()
		.required('Required'),
	optTanfNotEligible: yup //16
		.string()
		.required('Required'),
	optTanfAny9Months: yup //16
		.string()
		.required('Required'),

	txtTanfPrimaryRecipient: yup //16
		.string()
		.when(['optTanf6Months', 'optTanfAny18Months', 'optTanfNotEligible', 'optTanfAny9Months'], {
			is: (
				optTanf6Months: string,
				optTanfAny18Months: string,
				optTanfNotEligible: string,
				optTanfAny9Months: string,
			) =>
				optTanf6Months === 'yes' ||
				optTanfAny18Months === 'yes' ||
				optTanfNotEligible === 'yes' ||
				optTanfAny9Months === 'yes',
			then: yup.string().required('Required'),
			otherwise: yup.string().notRequired(),
		}),
	txtTanfRecipientLocation: yup //16
		.string()
		.when(['optTanf6Months', 'optTanfAny18Months', 'optTanfNotEligible', 'optTanfAny9Months'], {
			is: (
				optTanf6Months: string,
				optTanfAny18Months: string,
				optTanfNotEligible: string,
				optTanfAny9Months: string,
			) =>
				optTanf6Months === 'yes' ||
				optTanfAny18Months === 'yes' ||
				optTanfNotEligible === 'yes' ||
				optTanfAny9Months === 'yes',
			then: yup.string().required('Required'),
			otherwise: yup.string().notRequired(),
		}),

	optExFelon: yup.string().required('Required'), //17
	txtConvictionDate: yup.date().when('optExFelon', {
		//17
		is: (value: string) => value === 'yes',
		then: yup
			.date()
			.typeError('Should be a valid date in MM/DD/YYYY format')
			.max(new Date(), 'Date should be before current day')
			.required('Required'),
		otherwise: yup.date().notRequired(),
	}),
	txtReleaseDate: yup.date().when('optExFelon', {
		//17
		is: (value: string) => value === 'yes',
		then: yup
			.date()
			.typeError('Should be a valid date in MM/DD/YYYY format')
			.max(new Date(), 'Date should be before current day')
			.required('Required'),
		otherwise: yup.date().notRequired(),
	}),
	optConvictionType: yup.string().when('optExFelon', {
		//17
		is: (value: string) => value === 'yes',
		then: yup.string().required('Required'),
		otherwise: yup.string().notRequired(),
	}),

	//optEmpowermentZone: yup.string().required('Required'), //19
	optReceivedSSI: yup.string().required('Required'), //19

	optLTUrecipient27weeks: yup //22
		.string()
		.required('Required')
		.test(
			'checked answers',
			'Answer cannot be "No" while answer to check item 7 is "Yes"',
			(value, context) => {
				const isInvalid = value === 'no' && context.parent.ChkItem7 === true;
				return !isInvalid;
			},
		),
	txtLtuBenefitsState: yup //23
		.string()
		.when('optLTUrecipient27weeks', {
			is: (value: string) => value === 'yes',
			then: yup
				.string()
				.required(
					'Postal state code is required. US State code must be 2 letter abbreviation of US State or territory.',
				)
				.test(
					'is valid US state',
					'US State code must be 2 letter abbreviation of US State or territory.',
					(value) => {
						return US_STATE_CODES.includes(value?.toUpperCase() || '');
					},
				),
			otherwise: yup.string().notRequired(),
		}),

	txtSafStartDate: yup //20
		.date()
		.notRequired()
		.when('optLTUrecipient27weeks', {
			is: (value: string) => value === 'yes',
			then: yup.date().typeError('Should be a valid date in MM/DD/YYYY format'),
			otherwise: yup.date().notRequired(),
		}),

	txtBirthDate: yup
		.date()
		.typeError('Should be a valid date in MM/DD/YYYY format') //11 update
		.max(new Date(), 'Date should be before current day')
		.required('Required'),

	jobAppBirthdayRRC: yup.date().when(['isRuralRenewalCounty', 'isEmprowermentZone'], {
		is: (isRuralRenewalCounty: boolean, isEmprowermentZone: boolean) =>
			isRuralRenewalCounty || isEmprowermentZone,
		then: yup
			.date()
			.typeError('Should be a valid date in MM/DD/YYYY format') //17 update
			.max(new Date(), 'Date should be before current day')
			.max(yup.ref('txtBirthDate'), 'The dates in fields 18,17,11 must match')
			.min(yup.ref('txtBirthDate'), 'The dates in fields 18,17,11 must match'),
		otherwise: yup.date().notRequired(),
	}),

	jobAppBirthdayQSYE: yup.date().when('isQualifSummerYouthEmpl', {
		is: (value: boolean) => value,
		then: yup
			.date()
			.typeError('Should be a valid date in MM/DD/YYYY format') //18 update
			.max(new Date(), 'Date should be before current day')
			.max(yup.ref('txtBirthDate'), 'The dates in fields 18,17,11 must match')
			.min(yup.ref('txtBirthDate'), 'The dates in fields 18,17,11 must match'),
		otherwise: yup.date().notRequired(),
	}),
});

export default validationSchema;
