import { ChangeEvent } from 'react';
import CustomRadioCheckbox from '../../CustomRadioCheckbox';
import styles from './styles.module.scss';
import {useTranslation} from "react-i18next";

interface FormCheckedLineProps {
    id: string;
    counter: string;
    text: string;
    isChecked: boolean;
    disabled?: boolean;
    onChange: (e: ChangeEvent<any>) => void;
}

const FormCheckedLine: React.FC<FormCheckedLineProps> = ({
    id,
    counter,
    text,
    isChecked,
    disabled = false,
    onChange,
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.infoContainer}>
                <div className={styles.counter}>{counter}</div>
                <div className={styles.formLine}>
                    <div>{text}</div>
                    <CustomRadioCheckbox
                        id={id}
                        checked={isChecked}
                        onChange={onChange}
                        label={t('Checked')}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
};
export default FormCheckedLine;
