import React from 'react';

import Icons from '../../Assets';
import { NOTIFICATION_STATUS } from '../../types';
import styles from './styles.module.scss';

type ToastProps = {
    text: string;
    type: NOTIFICATION_STATUS;
};

const ToastNotification: React.FC<ToastProps> = ({ text, type }) => {
    const getNotificationIcon = () => {
        switch (type) {
            case NOTIFICATION_STATUS.SUCCESS: {
                return (
                    <Icons.ToastSuccessIcon
                        className={styles.notificationIcon}
                    />
                );
            }
            default:
                return (
                    <Icons.ToastErrorIcon className={styles.notificationIcon} />
                );
        }
    };

    const getBackgoundColor = () => {
        switch (type) {
            case NOTIFICATION_STATUS.SUCCESS: {
                return '#DBEFDE';
            }
            default:
                return '#F7D5D7';
        }
    };

    const icon = getNotificationIcon();
    const backgroundColor = getBackgoundColor();

    return (
        <div
            className={styles.notificationContainer}
            style={{ backgroundColor: backgroundColor }}
        >
            {icon}
            <div className={styles.notificationBody}>{text}</div>
        </div>
    );
};

export default ToastNotification;
