import { ChangeEvent } from 'react';
import { CustomButton, CustomInput } from '../../../../Components';
import styles from './styles.module.scss';

interface EnterEmailScreenProps {
    email: string;
    emailError?: string;
    setEmail: (e: ChangeEvent<HTMLInputElement>) => void;
    onLoginClick: () => void;
    onSendClick: () => void;
}

const EnterEmailScreen: React.FC<EnterEmailScreenProps> = ({
    email,
    emailError,
    setEmail,
    onLoginClick,
    onSendClick,
}) => {
    return (
        <div className={styles.formContainer}>
            <div className={styles.formTitle}>Forgot your password?</div>
            <div className={styles.formSubtitle}>
                Enter your email and we will send you verification code
            </div>
            <div className={styles.inputsContainer}>
                <CustomInput
                    id='email'
                    title='Email'
                    value={email}
                    onChange={setEmail}
                    error={emailError}
                    inputClassName={styles.enterEmailInput}
                />
            </div>
            <CustomButton
                title='Send'
                onClickHandler={onSendClick}
                disabled={(emailError?.length || 0) > 0 || !email.length}
            />
            <div className={styles.backContainer}>
                <div className={styles.backLabel}>Back to</div>
                <div className={styles.backLink} onClick={onLoginClick}>
                    Log in
                </div>
                <div className={styles.backLabel}>Screen</div>
            </div>
        </div>
    );
};

export default EnterEmailScreen;
