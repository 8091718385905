import styles from './styles.module.scss';

import { ConsItem, UnauthorizedHeader } from '../../Components';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StoreRootState } from '../../Store';

const MainPage = () => {
    const navigate = useNavigate();

    const onLoginClick = () => {
        navigate('/login');
    };

    const { role } = useSelector((state: StoreRootState) => state.auth);

    return (
        <>
            {role !== null && <Navigate to='/reports' />}
            <div className={styles.container}>
                <div className={styles.topContainer}>
                    <div className={styles.infoContainer}>
                        <UnauthorizedHeader
                            buttonAction={onLoginClick}
                            buttonTitle='Log in'
                        />
                        <div className={styles.separator} />
                        <div className={styles.descriptionContainer}>
                            <div className={styles.descriptionHeader}>
                                WOTC Wiz Portal
                            </div>
                            <div className={styles.descriptionText}>
                                Our WOTC Wiz portal let’s you stay up-to-date on
                                your credit progress throughout the year, by
                                allowing your to:
                            </div>
                        </div>
                    </div>
                    <div className={styles.consContainer}>
                        <ConsItem title='View Prior Submissions' />
                        <ConsItem title='Download Certifications' />
                        <ConsItem title='View Employee Certification Status' />
                    </div>
                </div>

                <div className={styles.mainImage} />
            </div>
        </>
    );
};

export default MainPage;
