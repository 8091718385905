import React, {FC, useState} from 'react';
import {Dialog, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import styles from "./PrintModal.module.scss";
import Icons from "../../Assets";
import {CustomButton} from "../index";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../Store";
import {getFormToPrint} from "../../Api/Forms";

interface PrintModalProps {
    open: boolean;
    close: () => void;
    formId: number;
}

const PrintModal: FC<PrintModalProps> = ({open, close, formId}) => {
    const [option, setOption] = useState('')
    const dispatch = useDispatch<AppDispatch>();

    const date = {
        formId,
        option
    }

    const onSubmit = () => {
        dispatch(getFormToPrint(date))
        close()
    }

    return (
        <Dialog open={open} onClose={close}>
            <div className={styles.container} style={{boxSizing: "border-box"}}>
                <Icons.CloseModalIcon
                    onClick={close}
                    className={styles.closeIcon}
                />
                <div className={styles.title}>Print the Form</div>

                <RadioGroup
                    aria-labelledby="radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    defaultValue="English"
                >
                    <FormControlLabel value="9061" control={<Radio onClick={() => setOption('download_pdf_9061')}/>}
                                      label="Form 9061"/>
                    <FormControlLabel value="8850" control={<Radio onClick={() => setOption('download_pdf_8850')}/>}
                                      label="Form 8850"/>
                    <FormControlLabel value="both" control={<Radio onClick={() => setOption('download_both_pdf')}/>}
                                      label="Form both"/>
                </RadioGroup>

                <div className={styles.buttonContainer}>
                    <CustomButton
                        title={'Print'}
                        onClickHandler={onSubmit}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default PrintModal;