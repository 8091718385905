import { ChangeEvent } from 'react';
import CustomInput from '../../CustomInput';
import styles from './styles.module.scss';

interface FormInputLineProps {
    id: string;
    counter: string;
    text: string;
    value: string;
    type?: 'text' | 'number';
    error?: string;
    disabled?: boolean;
    onChange: (e: ChangeEvent<any>) => void;
}

const FormInputLine: React.FC<FormInputLineProps> = ({
    id,
    counter,
    text,
    value,
    type = 'text',
    error = '',
    onChange,
    disabled = false,
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.infoContainer}>
                <div className={styles.counter}>{counter}</div>
                <div>{text}</div>
            </div>
            <CustomInput
                id={id}
                value={value}
                onChange={onChange}
                type={type}
                error={error}
                disabled={disabled}
                maxLength={6}
                inputClassName={styles.input}
                containerClassName={styles.mobileInputContainer}
            />
        </div>
    );
};
export default FormInputLine;
