import { MoonLoader, PulseLoader, ClipLoader } from 'react-spinners';

export enum LOADER_VERSION {
    MOON = 'moon',
    PULSE = 'pulse',
    CLIP = 'clip',
}

interface LoadingIndicatorProps {
    size?: number;
    color?: string;
    version?: LOADER_VERSION;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
    color = '#0B5394',
    size = 30,
    version = LOADER_VERSION.MOON,
}) => {
    return (
        <>
            {version === LOADER_VERSION.MOON && (
                <MoonLoader color={color} size={size} loading />
            )}
            {version === LOADER_VERSION.PULSE && (
                <PulseLoader color={color} size={size} loading />
            )}
            {version === LOADER_VERSION.CLIP && (
                <ClipLoader color={color} size={size} loading />
            )}
        </>
    );
};

export default LoadingIndicator;
