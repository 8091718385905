import React, { ChangeEvent } from 'react';
import { CustomInput } from '../../';
import styles from './styles.module.scss';
import {useTranslation} from "react-i18next";

interface JobApplicantHeaderProps {
    disabled?: boolean;
    onChange: (e: ChangeEvent<any>) => void;
    values: {
        txtFirstName: string;
        txtLastName: string;
        txtSSN: string;
        txtStreet: string;
        txtCity: string;
        txtState: string;
        txtZip: string;
    };
    errors: {
        txtFirstName?: string;
        txtLastName?: string;
        txtSSN?: string;
        txtStreet?: string;
        txtCity?: string;
        txtState?: string;
        txtZip?: string;
    };
    companyName?: string;
}

const JobApplicantHeader: React.FC<JobApplicantHeaderProps> = ({
    disabled = false,
    companyName = '',
    onChange,
    values,
    errors,
}) => {

    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <div className={styles.headerText}>
                    {companyName} {t('participates in the Federal Work Opportunity Tax Credit (WOTC) program')}.
                </div>
                <div className={styles.headerText}>
                    {t('This program provides special tax savings for hiring people that meet one or more of the conditions for this helpful government tax program. This Survey is designed to identify qualified employees for the tax credit program. Your participation in this survey is voluntary. Any answers you provide will remain strictly confidential and will not affect your employment in any way. Please complete the information below. The information you provide is used exclusively to determine eligibility for the tax credit.')}
                </div>
            </div>

            <div className={styles.line}>
                <CustomInput
                    id='txtFirstName'
                    value={values.txtFirstName}
                    title={t('First Name')}
                    onChange={onChange}
                    error={errors.txtFirstName}
                    disabled={disabled}
                    maxLength={40}
                    inputClassName={styles.nameContainer}
                />
                <CustomInput
                    id='txtLastName'
                    value={values.txtLastName}
                    title={t('Last Name')}
                    onChange={onChange}
                    error={errors.txtLastName}
                    disabled={disabled}
                    maxLength={40}
                    inputClassName={styles.nameContainer}
                />
                <CustomInput
                    id='txtSSN'
                    value={values.txtSSN}
                    title={t('Social Security No.')}
                    onChange={onChange}
                    error={errors.txtSSN}
                    disabled={disabled}
                    hint='(xxx-xx-xxxx)'
                    inputClassName={styles.ssnContainer}
                    mask='999-99-9999'
                    isMask={true}
                />
            </div>
            <div className={styles.line}>
                <CustomInput
                    id='txtStreet'
                    value={values.txtStreet}
                    title={t('Street Address')}
                    onChange={onChange}
                    error={errors.txtStreet}
                    disabled={disabled}
                    maxLength={50}
                    inputClassName={styles.addressContainer}
                />
                <CustomInput
                    id='txtCity'
                    value={values.txtCity}
                    title={t('City or Town')}
                    onChange={onChange}
                    error={errors.txtCity}
                    disabled={disabled}
                    maxLength={50}
                    inputClassName={styles.cityContainer}
                />
                <CustomInput
                    id='txtState'
                    title={t('State')}
                    onChange={onChange}
                    value={values.txtState}
                    error={errors.txtState}
                    disabled={disabled}
                    maxLength={2}
                    inputClassName={styles.stateContainer}
                />
                <CustomInput
                    id='txtZip'
                    title={t('ZIP Code')}
                    onChange={onChange}
                    value={values.txtZip}
                    error={errors.txtZip}
                    disabled={disabled}
                    maxLength={5}
                    inputClassName={styles.zipContainer}
                />
            </div>
        </div>
    );
};

export default JobApplicantHeader;
