import * as yup from 'yup';

const validationSchema = yup.object().shape({
	signedBy: yup
		.string()
		.matches(/^[A-Za-z '-.]{1,60}$/i)
		.required(`Signed by is required and may only contain letters, spaces and the following: -.'`),
});

export default validationSchema;
