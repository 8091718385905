import { FormControlLabel, Checkbox } from '@mui/material';

import styles from './styles.module.scss';
import Icons from '../../Assets';

type CustomCheckboxProps = {
    label?: string;
    checked: boolean;
    onChange: () => void;
};

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
    label,
    checked,
    onChange,
}) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    icon={<Icons.CheckboxIcon />}
                    checkedIcon={<Icons.CheckboxCheckedIcon />}
                    checked={checked}
                    onChange={onChange}
                />
            }
            label={label}
            classes={{
                label: styles.label,
            }}
        />
    );
};

export default CustomCheckbox;
