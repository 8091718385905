export enum FORM_STATUS {
	APPROVE_WAITING,
	PENDING,
	CERTIFIED,
	DENIED,
	UNKNOWN,
}

export enum NOTIFICATION_STATUS {
	SUCCESS = 'success',
	ERROR = 'error',
}

export enum USER_TYPES {
	ADMIN = 'admin',
	MANAGER = 'manager',
}

export type FormStatusData = {
	appId: string;
	name: string;
	FEIN: string;
	SSN: string;
	postmarkDate: string;
	determanationDate: string;
	status: FORM_STATUS;
};

export type TabData = {
	id: string;
	name: string;
	component: React.ReactNode;
};

export type CommonFormData = {
	company?: {
		id: string;
	};
	id?: number;
	txtFirstName: string;
	txtLastName: string;
	txtSSN: string;
	txtStreet: string;
	txtCity: string;
	txtState: string;
	txtZip: string;

	txtFEIN: string;
	txtErName: string;
	txtErPhone: string;
	txtErStreet: string;
	txtErCity: string;
	txtErState: string;
	txtErZip: string;
	txtContactName: string;
	txtContactPhone: string;
	txtContactStreet: string;
	txtContactCity: string;
	txtContactState: string;
	txtContactZip: string;
	ddlGroupNumber: string;
	txtInfoDate: string;
	txtOfferDate: string;
	txtHireDate: string;
	txtStartDate: string;

	chkItem1: boolean; //1
	chkItem2: boolean;
	chkItem3: boolean;
	chkItem4: boolean;
	chkItem5: boolean;
	chkItem6: boolean;
	ChkItem7: boolean;

	txtLastEmploymentDate: string; //8
	txtStartWage: string; //10
	ddlOccupation: string; //11
	txtBirthDate: string; //12
	txtVeteranSnapPrimaryRecipient: string; // 13
	txtVeteranSnapRecipientLocation: string; // 13
	txtSnapPrimaryRecipient: string; //14
	txtSnapRecipientLocation: string; //14
	txtTanfPrimaryRecipient: string; //16
	txtTanfRecipientLocation: string; //16
	txtConvictionDate: string; //17
	txtReleaseDate: string; //17
	txtLtuBenefitsState: string; //23
	txtSafStartDate: string; //23

	txtEligibilitySources: string; //last field
};

export type EditableFormData = {
	id: number;
	chkItem1: boolean;
	chkItem2: boolean;
	chkItem3: boolean;
	chkItem4: boolean;
	chkItem5: boolean;
	chkItem6: boolean;
	ChkItem7: boolean;
	txtLastEmploymentDate: string; //8
	optReceivingWages: boolean; //8

	txtStartWage: string; //10
	ddlOccupation: string; //11
	txtBirthDate: string; //12

	txtVeteranSnapPrimaryRecipient: string; // 13
	txtVeteranSnapRecipientLocation: string; // 13
	txtDischargeDate: string;

	txtSnapPrimaryRecipient: string; //14
	txtSnapRecipientLocation: string; //14
	txtTanfPrimaryRecipient: string; //16
	txtTanfRecipientLocation: string; //16
	txtConvictionDate: string; //17
	txtReleaseDate: string; //17
	txtLtuBenefitsState: string; //23
	txtSafStartDate: string; //23

	txtEligibilitySources: string; //last field
	txtFirstName: string;
	txtLastName: string;
	txtSSN: string;
	txtStreet: string;
	txtCity: string;
	txtState: string;
	txtZip: string;

	txtFEIN: string;
	txtErName: string;
	txtErPhone: string;
	txtErStreet: string;
	txtErCity: string;
	txtErState: string;
	txtErZip: string;
	txtContactName: string;
	txtContactPhone: string;
	txtContactStreet: string;
	txtContactCity: string;
	txtContactState: string;
	txtContactZip: string;
	txtInfoDate: string;
	txtOfferDate: string;
	txtHireDate: string;
	txtStartDate: string;

	optPreviousEmployer: boolean;
	optVeteran: boolean; //13
	optVeteranReceivedSnap: boolean; //13
	optVeteranDisability: boolean; //13
	optVeteranDischarged: boolean; //13
	optVeteranUnemployed: boolean; //13
	optSnap6Months: boolean; //14
	optSnap3Months: boolean; //14
	optTanf18Months: boolean; //16
	optTanfAny18Months: boolean; //16
	optTanfNotEligible: boolean; //16
	optTanfAny9Months: boolean; //16
	optExFelon: boolean; //17
	optConvictionType: boolean; //17
	isRuralRenewalCounty: boolean; //18
	isEmprowermentZone: boolean; //18
	jobAppBirthdayRRC: string; //18
	isQualifSummerYouthEmpl: boolean; //19
	jobAppBirthdayQSYE: string; //19
	optReceivedSSI: boolean; //20
	optLTUrecipient27weeks: boolean; //23

	optAssignmentOrganization?: string; //15
	optVocationalRehab?: boolean; //15
	optEmploymentNetwork?: boolean; //15
	optVeteransAffair?: boolean; //15
};

export type BackFormData = CommonFormData & {
	url?: string;
	signedBy?: string;
	optPreviousEmployer: boolean;
	optReceivingWages: boolean;
	optAgeUnder40: boolean; //12
	optVeteran: boolean; //13
	optVeteranReceivedSnap: boolean; //13
	optVeteranDisability: boolean; //13
	optVeteranDischarged: boolean; //13
	optVeteranUnemployed: boolean; //13
	optSnap6Months: boolean; //14
	optSnap3Months: boolean; //14
	optTanf18Months: boolean; //16
	optTanfAny18Months: boolean; //16
	optTanfNotEligible: boolean; //16
	optTanfAny9Months: boolean; //16
	optExFelon: boolean; //17
	optConvictionType: boolean; //17
	isRuralRenewalCounty: boolean; //18
	isEmprowermentZone: boolean; //18
	optEmpowermentZone: boolean; //19
	optReceivedSSI: boolean; //20
	optUnemployedVeteran6Months: boolean; //21
	optUnemployedVeteran4Weeks: boolean; //22
	optLTUrecipient27weeks: boolean; //23
	optVocationalRehab: boolean; //15
	optEmploymentNetwork: boolean; //15
	optVeteransAffair: boolean; //15
};

export type FrontFormData = CommonFormData & {
	optReceivingWages: string; //8
	txtDischargeDate: string; //13//13//13//13
	optPreviousEmployer: string;
	optVeteran: string; //13
	optVeteranReceivedSnap: string; //13
	optVeteranDisability: string; //13
	optVeteranDischarged: string; //13
	optVeteranUnemployed: string; //13
	optSnap6Months: string; //14
	optSnap3Months: string; //14
	optAssignmentOrganization: string; //15
	optTanf18Months: string; //16
	optTanfAny18Months: string; //16
	optTanfNotEligible: string; //16
	optTanfAny9Months: string; //16
	optExFelon: string; //17
	optConvictionType: string; //17
	isRuralRenewalCounty: string; //18
	isEmprowermentZone: string; //18
	jobAppBirthdayRRC: string; //18
	isQualifSummerYouthEmpl: boolean; //19
	jobAppBirthdayQSYE: string; //19
	optReceivedSSI: string; //20
	optLTUrecipient27weeks: string; //23
	signedBy: string; //modal
};
