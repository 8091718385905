import {useState} from 'react';
import cc from 'classcat';
import {CustomErrorTooltip} from '../';
import Icons from '../../Assets';
import useResponsive from '../../Hooks/useResponsive';
import styles from './styles.module.scss';
import {useTranslation} from "react-i18next";
import InputMask, {Props} from 'react-input-mask';


type CustomInputProps1 = {
    id?: string;
    name?: string;
    value?: string;
    title?: string;
    width?: string;
    error?: string;
    placeholder?: string;
    type?: 'text' | 'password' | 'number' | 'hidden';
    leftLabel?: boolean;
    disabled?: boolean;
    maxLength?: number;
    hint?: string;
    inputClassName?: string;
    containerClassName?: string;
    labelClassName?: string;
    onChange: (e: React.ChangeEvent<any>) => void;
    mask?: string;
    isMask?: boolean
};

type CustomInputProps = CustomInputProps1 & Omit<Props, 'mask'>

const CustomInput: React.FC<CustomInputProps> = (
    {
        id = '',
        value = '',
        title = '',
        type = 'text',
        error = '',
        placeholder = '',
        leftLabel = false,
        disabled = false,
        maxLength = 100,
        hint = '',
        name = '',
        onChange,
        inputClassName = '',
        containerClassName = '',
        labelClassName = '',
        width = 'initial',
        mask = '',
        isMask = false
    }
) => {
    const color = disabled ? '#656565' : error ? '#b90000' : '';

    const [isHidden, setIsHidden] = useState(true);
    const changeVisibility = () => {
        setIsHidden(!isHidden);
    };

    const {Mobile, Default} = useResponsive();
    const isPassword = type === 'password';
    const isHiddenPassword = isPassword && isHidden;

    const passwordInputStyle = isHiddenPassword
        ? styles.passwordInput
        : styles.input;

    const labelContainerStyle = leftLabel
        ? styles.leftLabeledInputContainer
        : styles.inputContainer;

    const {t} = useTranslation();

    const filteredValue = value?.split('-').join('')

    return (
        <div className={labelContainerStyle} style={{width: width}}>
            {title && (
                <div className={cc([styles.inputLabel, labelClassName])}>
                    {title}
                </div>
            )}
            <div className={cc([styles.verticalContainer, containerClassName])}>
                <div className={styles.errorContainer}>
                    <div
                        className={styles.inputBorder}
                        style={{
                            borderColor: color,
                        }}
                    >
                        <div className={styles.passwordContainer}>

                            {isMask ?
                                <InputMask
                                    mask={mask}
                                    id={id}
                                    name={name}
                                    className={cc([
                                        passwordInputStyle,
                                        inputClassName,
                                    ])}
                                    onChange={onChange}
                                    value={filteredValue}
                                    type='text'
                                    disabled={disabled}
                                    placeholder={placeholder}
                                    autoComplete={'new-password'}/> :
                                <input
                                    id={id}
                                    name={name}
                                    className={cc([
                                        passwordInputStyle,
                                        inputClassName,
                                    ])}
                                    onChange={onChange}
                                    value={value}
                                    type='text'
                                    disabled={disabled}
                                    placeholder={placeholder}
                                    maxLength={maxLength}
                                    autoComplete={'new-password'}
                                />}

                            <Mobile>
                                {!disabled && (
                                    <CustomErrorTooltip text={t(error)}/>
                                )}
                            </Mobile>
                            {isHidden && isPassword && (
                                <Icons.PasswordHiddenIcon
                                    className={styles.passwordIcon}
                                    onClick={changeVisibility}
                                />
                            )}
                            {!isHidden && isPassword && (
                                <Icons.PasswordFreeIcon
                                    className={styles.passwordIcon}
                                    onClick={changeVisibility}
                                />
                            )}
                        </div>
                    </div>
                    <Default>
                        {!disabled && <CustomErrorTooltip text={t(error)}/>}
                    </Default>
                </div>
                {hint && <div className={styles.underLabel}>{hint}</div>}
            </div>
        </div>
    );
};

export default CustomInput;
