import {Dialog} from '@mui/material';
import {useFormik} from 'formik';
import validationSchema from './validation';
import {Company} from '../../Slices/CompanySlice';
import Icons from '../../Assets';
import {CustomButton, CustomInput} from '..';
import styles from './styles.module.scss';
import React from "react";

export enum CompanyMode {
    EDIT = 'edit',
    CREATE = 'create',
    VIEW = 'view'
}

interface CompanyInfoModalProps {
    open: boolean;
    handleClose: () => void;
    company?: Company;
    mode?: CompanyMode;
    onAction: (values: Company, id?: number) => void;
}

const CompanyInfoModal: React.FC<CompanyInfoModalProps> = ({
                                                               open,
                                                               handleClose,
                                                               onAction,
                                                               company,
                                                               mode = CompanyMode.EDIT,
                                                           }) => {
    const onDialogClose = (_: any, reason: string) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
            formik.resetForm();
        }
    };

    const onClose = () => {
        handleClose();
        formik.resetForm();
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyName: company?.companyName || '',
            fein: company?.fein || '',
            state: company?.state || '',
            city: company?.city || '',
            zip: company?.zip || '',
            street: company?.street || '',
            contactName: company?.contactName || '',
            phone: company?.phone || '',
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            onAction(values, company?.id);
            formik.resetForm();
        },
    });

    const {errors, values} = formik;

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            maxWidth='lg'
            classes={{paper: styles.dialog}}
        >
            <div className={styles.container}>
                <Icons.CloseModalIcon
                    onClick={onClose}
                    className={styles.closeIcon}
                />
                <div className={styles.title}>Company Information</div>
                <div className={styles.fieldsContainer}>
                    <CustomInput
                        id='companyName'
                        onChange={formik.handleChange}
                        title='Company name'
                        value={values.companyName}
                        error={errors.companyName}
                        maxLength={50}
                        inputClassName={styles.bigInput}
                        disabled={mode === CompanyMode.VIEW}
                    />
                    <CustomInput
                        id='fein'
                        onChange={formik.handleChange}
                        title='FEIN'
                        value={values.fein}
                        error={errors.fein}
                        maxLength={10}
                        hint='(xx-xxxxxxx)'
                        inputClassName={styles.bigInput}
                        disabled={mode === CompanyMode.VIEW}
                    />
                    <div className={styles.fieldLine}>
                        <CustomInput
                            id='state'
                            onChange={formik.handleChange}
                            title='State'
                            value={values.state}
                            error={errors.state}
                            maxLength={2}
                            inputClassName={styles.smallInput}
                            disabled={mode === CompanyMode.VIEW}
                        />
                        <CustomInput
                            id='city'
                            onChange={formik.handleChange}
                            title='City'
                            value={values.city}
                            error={errors.city}
                            maxLength={50}
                            inputClassName={styles.averageInput}
                            disabled={mode === CompanyMode.VIEW}
                        />
                    </div>
                    <div className={styles.fieldLine}>
                        <CustomInput
                            id='zip'
                            onChange={formik.handleChange}
                            title='ZIP'
                            value={values.zip}
                            error={errors.zip}
                            maxLength={5}
                            inputClassName={styles.smallInput}
                            disabled={mode === CompanyMode.VIEW}
                        />
                        <CustomInput
                            id='street'
                            onChange={formik.handleChange}
                            title='Street'
                            value={values.street}
                            error={errors.street}
                            maxLength={50}
                            inputClassName={styles.averageInput}
                            disabled={mode === CompanyMode.VIEW}
                        />
                    </div>
                    <CustomInput
                        id='contactName'
                        onChange={formik.handleChange}
                        title='Contact name'
                        value={values.contactName}
                        error={errors.contactName}
                        maxLength={50}
                        inputClassName={styles.bigInput}
                        disabled={mode === CompanyMode.VIEW}
                    />
                    <CustomInput
                        id='phone'
                        onChange={formik.handleChange}
                        title='Phone'
                        value={values.phone}
                        error={errors.phone}
                        maxLength={14}
                        hint='((xxx) xxx-xxxx)'
                        inputClassName={styles.bigInput}
                        disabled={mode === CompanyMode.VIEW}
                    />
                </div>
                {mode === CompanyMode.VIEW ? null :
                    <div className={styles.buttonContainer}>
                        <CustomButton
                            title={mode === CompanyMode.CREATE ? 'Create' : 'Edit'}
                            onClickHandler={formik.submitForm}
                            disabled={
                                !formik.isValid || values.companyName.length === 0
                            }
                        />
                    </div>}

            </div>
        </Dialog>
    );
};

export default CompanyInfoModal;
