//@ts-nocheck
import { Slide, toast, ToastOptions } from 'react-toastify';
import { ToastNotification } from '../Components';
import { BackFormData, FORM_STATUS, FrontFormData, NOTIFICATION_STATUS } from '../types';

const copyToClipboard = async (message: string, hr?: boolean = false) => {
	const copyToClipboardMain = (s: string) => {
		let textarea;
		let result;

		try {
			textarea = document.createElement('textarea');
			textarea.setAttribute('readonly', true);
			textarea.setAttribute('contenteditable', true);
			textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
			textarea.value = s;

			document.body.appendChild(textarea);

			textarea.focus();
			textarea.select();

			const range = document.createRange();
			range.selectNodeContents(textarea);

			const sel = window.getSelection();
			sel.removeAllRanges();
			sel.addRange(range);

			textarea.setSelectionRange(0, textarea.value.length);
			result = document.execCommand('copy');
		} catch (err) {
			console.error(err);
			result = null;
		} finally {
			document.body.removeChild(textarea);
		}

		if (!result) {
			const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
			const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
			result = prompt(`Press ${copyHotkey}`, s); // eslint-disable-line no-alert
			if (!result) {
				return false;
			}
		}
		return true;
	};

	try {
		await navigator.clipboard.writeText(message);

		if (!hr) {
			sendNotification(
				'Link has been generated successfully and is on the clipboard!',
				NOTIFICATION_STATUS.SUCCESS,
			);
		}
	} catch (e) {
		sendNotification(e?.message || 'Cannot copy link!', NOTIFICATION_STATUS.ERROR);
	}
};

const sendNotification = (message: string, type: NOTIFICATION_STATUS) => {
	const toastOptions: ToastOptions = {
		position: 'top-center',
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		progress: undefined,
		transition: Slide,
		rtl: false,
		closeButton: false,
	};

	toast.dismiss();

	const item = <ToastNotification text={message} type={type} />;

	toast(item, toastOptions);
};

const BackendToFrontendFormDataConverter = (data: BackFormData): FrontFormData => {
	let organization = '';
	if (data.optVocationalRehab) organization = 'optVocationalRehab';
	else if (data.optEmploymentNetwork) organization = 'optEmploymentNetwork';
	else if (data.optVeteransAffair) organization = 'optVeteransAffair';

	const optFromConverter = (value: boolean): string => {
		if (value) return 'yes';
		else return 'no';
	};

	return {
		...data,
		isEmprowermentZone: optFromConverter(data.isEmprowermentZone),
		isRuralRenewalCounty: optFromConverter(data.isRuralRenewalCounty),
		optAssignmentOrganization: organization,
		optReceivingWages: optFromConverter(data.optReceivingWages),
		optAgeUnder40: optFromConverter(data.optAgeUnder40),
		optPreviousEmployer: optFromConverter(data.optPreviousEmployer),
		optVeteran: optFromConverter(data.optVeteran),
		optVeteranReceivedSnap: optFromConverter(data.optVeteranReceivedSnap),
		optVeteranDisability: optFromConverter(data.optVeteranDisability),
		optVeteranDischarged: optFromConverter(data.optVeteranDischarged),
		optVeteranUnemployed: optFromConverter(data.optVeteranUnemployed),
		optSnap6Months: optFromConverter(data.optSnap6Months),
		optSnap3Months: optFromConverter(data.optSnap3Months),
		optTanf18Months: optFromConverter(data.optTanf18Months),
		optTanfAny18Months: optFromConverter(data.optTanf18Months),
		optTanfAny9Months: optFromConverter(data.optTanfAny9Months),
		optTanfNotEligible: optFromConverter(data.optTanfNotEligible),
		optExFelon: optFromConverter(data.optExFelon),
		optConvictionType: optFromConverter(data.optConvictionType),
		optInRuralRenewalCounty: optFromConverter(data.optInRuralRenewalCounty),
		optEmpowermentZone: optFromConverter(data.optEmpowermentZone),
		optLTUrecipient27weeks: optFromConverter(data.optLTUrecipient27weeks),
		optReceivedSSI: optFromConverter(data.optReceivedSSI),
		optUnemployedVeteran6Months: optFromConverter(data.optUnemployedVeteran6Months),
		optUnemployedVeteran4Weeks: optFromConverter(data.optUnemployedVeteran4Weeks),
		signedBy: '',
	};
};

const FrontendToBackendFormDataConverter = (data: FrontFormData): BackFormData => {
	const addAnswers = (value: string) => {
		return {
			optVocationalRehab: value === 'optVocationalRehab',
			optEmploymentNetwork: value === 'optEmploymentNetwork',
			optVeteransAffair: value === 'optVeteransAffair',
		};
	};

	const optToConverter = (value: string): boolean => {
		return value === 'yes';
	};

	const { optAssignmentOrganization, ...rest } = data;
	const convertedOrganization = addAnswers(optAssignmentOrganization);

	const result = {
		...rest,
		...convertedOrganization,
		optReceivingWages: optToConverter(data.optReceivingWages),
		isEmprowermentZone: optToConverter(data.isEmprowermentZone),
		isRuralRenewalCounty: optToConverter(data.isRuralRenewalCounty),
		optAgeUnder40: optToConverter(data.optAgeUnder40),
		optPreviousEmployer: optToConverter(data.optPreviousEmployer),
		optVeteran: optToConverter(data.optVeteran),
		optVeteranReceivedSnap: optToConverter(data.optVeteranReceivedSnap),
		optVeteranDisability: optToConverter(data.optVeteranDisability),
		optVeteranDischarged: optToConverter(data.optVeteranDischarged),
		optVeteranUnemployed: optToConverter(data.optVeteranUnemployed),
		optSnap6Months: optToConverter(data.optSnap6Months),
		optSnap3Months: optToConverter(data.optSnap3Months),
		optTanf18Months: optToConverter(data.optTanf18Months),
		optTanfAny18Months: optToConverter(data.optTanf18Months),
		optTanfAny9Months: optToConverter(data.optTanfAny9Months),
		optTanfNotEligible: optToConverter(data.optTanfNotEligible),
		optExFelon: optToConverter(data.optExFelon),
		optConvictionType: optToConverter(data.optConvictionType),
		optInRuralRenewalCounty: optToConverter(data.optInRuralRenewalCounty),
		optEmpowermentZone: optToConverter(data.optEmpowermentZone),
		optLTUrecipient27weeks: optToConverter(data.optLTUrecipient27weeks),
		optReceivedSSI: optToConverter(data.optReceivedSSI),
		optUnemployedVeteran6Months: optToConverter(data.optUnemployedVeteran6Months),
		optUnemployedVeteran4Weeks: optToConverter(data.optUnemployedVeteran4Weeks),
		txtStartWage: data.txtStartWage.toString(),
	};

	return result;
};

const US_STATE_CODES = [
	'AA',
	'AE',
	'AK',
	'AL',
	'AP',
	'AR',
	'AZ',
	'CA',
	'CO',
	'CT',
	'DC',
	'DE',
	'FL',
	'GA',
	'GU',
	'HI',
	'IA',
	'ID',
	'IL',
	'IN',
	'KS',
	'KY',
	'LA',
	'MA',
	'MD',
	'ME',
	'MI',
	'MN',
	'MO',
	'MS',
	'MT',
	'NC',
	'ND',
	'NE',
	'NH',
	'NJ',
	'NM',
	'NV',
	'NY',
	'OH',
	'OK',
	'OR',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VA',
	'VI',
	'VT',
	'WA',
	'WI',
	'WV',
	'WY',
];

const convertFormStatus = (value: string): FORM_STATUS => {
	switch (value) {
		case 'UNPROCESSED':
			return FORM_STATUS.PENDING;
		case 'Denied':
			return FORM_STATUS.DENIED;
		case 'In progress':
			return FORM_STATUS.APPROVE_WAITING;
		case 'Certified':
			return FORM_STATUS.CERTIFIED;
		default:
			return FORM_STATUS.UNKNOWN;
	}
};

export {
	copyToClipboard,
	sendNotification,
	convertFormStatus,
	BackendToFrontendFormDataConverter,
	FrontendToBackendFormDataConverter,
	US_STATE_CODES,
};
