import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Icons from '../../Assets';

import styles from './styles.module.css';
import useResponsive from '../../Hooks/useResponsive';

interface CustomErrorTooltipProps {
    text?: string;
}

const CustomErrorTooltip: React.FC<CustomErrorTooltipProps> = ({
    text = '',
}) => {
    const { Touch, Desktop } = useResponsive();

    return (
        <div
            className={styles.errorPlaceholder}
            style={{ visibility: text ? 'visible' : 'hidden' }}
        >
            <Desktop>
                <Tooltip
                    title={text}
                    placement='right'
                    enterTouchDelay={200}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                userSelect: 'none',
                                padding: '10px',
                                fontSize: '14px',
                                fontFamily: 'Poppins',
                                background: '#DCEDFD',
                                color: '#001940',
                                '& .MuiTooltip-arrow': {
                                    color: 'linear-gradient(180deg, #F8F8F8 0%, #F7F7F7 100%);',
                                },
                            },
                        },
                    }}
                >
                    <Icons.ValidationErrorIcon />
                </Tooltip>
            </Desktop>

            <Touch>
                <Tooltip
                    title={text}
                    placement='left'
                    enterTouchDelay={200}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                userSelect: 'none',
                                padding: '10px',
                                fontSize: '14px',
                                fontFamily: 'Poppins',
                                background: '#DCEDFD',
                                color: '#001940',
                                maxWidth: '150px',
                                '& .MuiTooltip-arrow': {
                                    color: 'linear-gradient(180deg, #F8F8F8 0%, #F7F7F7 100%);',
                                },
                            },
                        },
                    }}
                >
                    <Icons.ValidationErrorIcon />
                </Tooltip>
            </Touch>
        </div>
    );
};

export default CustomErrorTooltip;
