import { createSlice } from '@reduxjs/toolkit';
import {
	generateHrLinkToForm,
	getCompanyInfoByLink,
	submitForm,
	updateFormValues,
	submitExistingForm,
} from '../../Api/Forms';
import { sendNotification } from '../../Common/common';
import { NOTIFICATION_STATUS } from '../../types';

export interface ReportsState {
	companyInfo: {
		id: number;
		companyName: string;
		fein: string;
		state: string;
		city: string;
		zip: string;
		street: string;
		contactName: string;
		phone: string;
		url: string;
	};
	formSubmitList: Array<number>;
	isSubmitted: boolean;
	isLoading: boolean;
	isLinkGenerated: boolean;
}

const initialState: ReportsState = {
	companyInfo: {
		id: -1,
		companyName: '',
		fein: '',
		state: '',
		city: '',
		zip: '',
		street: '',
		contactName: '',
		phone: '',
		url: '',
	},
	formSubmitList: [],
	isSubmitted: false,
	isLoading: false,
	isLinkGenerated: false,
};

export const formSubmitSlice = createSlice({
	name: 'formSubmit',
	initialState,
	reducers: {
		resetLink: (state) => {
			state.isLinkGenerated = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(submitForm.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(submitForm.fulfilled, (state) => {
			state.isSubmitted = true;
			state.isLoading = false;
			sendNotification('Form successfully submitted!', NOTIFICATION_STATUS.SUCCESS);
		});

		builder.addCase(submitForm.rejected, (state, action) => {
			state.isSubmitted = false;
			state.isLoading = false;
			sendNotification(
				(action.payload as string) || 'Error in Form Submission',
				NOTIFICATION_STATUS.ERROR,
			);
		});

		builder.addCase(submitExistingForm.pending, (state, action) => {
			state.formSubmitList.push(action.meta.arg);
		});

		builder.addCase(submitExistingForm.fulfilled, (state, action) => {
			sendNotification('Form successfully submitted!', NOTIFICATION_STATUS.SUCCESS);
			const formId = action.meta.arg;
			state.formSubmitList = state.formSubmitList.filter((item) => item !== formId);
		});

		builder.addCase(submitExistingForm.rejected, (state, action) => {
			const formId = action.meta.arg;
			state.formSubmitList = state.formSubmitList.filter((item) => item !== formId);
			sendNotification(
				(action.payload as string) || 'Error in Form Submission',
				NOTIFICATION_STATUS.ERROR,
			);
		});

		builder.addCase(updateFormValues.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(updateFormValues.fulfilled, (state) => {
			state.isLoading = false;
			sendNotification('Updated form values!', NOTIFICATION_STATUS.SUCCESS);
		});
		builder.addCase(updateFormValues.rejected, (state, action) => {
			state.isLoading = false;
			sendNotification(
				(action.payload as string) || 'Error in values update!',
				NOTIFICATION_STATUS.ERROR,
			);
		});

		builder.addCase(generateHrLinkToForm.fulfilled, (state, action) => {
			state.isLinkGenerated = true;
		});

		builder.addCase(getCompanyInfoByLink.fulfilled, (state, action) => {
			const { payload } = action;
			state.companyInfo.city = payload.city;
			state.companyInfo.companyName = payload.companyName;
			state.companyInfo.contactName = payload.contactName;
			state.companyInfo.fein = payload.fein;
			state.companyInfo.id = payload.id;
			state.companyInfo.phone = payload.phone;
			state.companyInfo.state = payload.state;
			state.companyInfo.street = payload.street;
			state.companyInfo.zip = payload.zip;
		});

		builder.addCase(getCompanyInfoByLink.rejected, (state, action) => {
			sendNotification(
				(action.payload as string) || 'Cannot load all form data!',
				NOTIFICATION_STATUS.ERROR,
			);
		});
	},
});

export const { resetLink } = formSubmitSlice.actions;

export default formSubmitSlice.reducer;
