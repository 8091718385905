import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../../Api/Auth';
import { updatePasswordOnBackend } from '../../Api/User';
import Icons from '../../Assets';
import { AppDispatch, StoreRootState } from '../../Store';
import AuthorizedHeader from '../AuthorizedHeader';
import ChangePasswordModal from '../ChangePasswordModal';
import styles from './styles.module.scss';

const AdminHeader: React.FC = () => {
    const [changePasswordModalOpen, setChangePasswordModalOpen] =
        useState(false);
    const { refreshToken } = useSelector((state: StoreRootState) => state.auth);
    const dispatch = useDispatch<AppDispatch>();

    const onChangePasswordModalOpen = () => {
        setChangePasswordModalOpen(true);
    };

    const onChangePasswordModalClose = () => {
        setChangePasswordModalOpen(false);
    };

    const onPasswordChange = (password: string) => {
        dispatch(updatePasswordOnBackend(password));
    };

    const actions = [
        {
            text: 'Change password',
            icon: <Icons.ChangePasswordIcon className={styles.logoutIcon} />,
            onClick: () => {
                onChangePasswordModalOpen();
            },
        },
        {
            text: 'Log out',
            icon: <Icons.MenuLogoutIcon className={styles.logoutIcon} />,
            onClick: () => {
                dispatch(signOut(refreshToken || ''));
            },
        },
    ];

    return (
        <div className={styles.container}>
            <AuthorizedHeader actions={actions} />
            <ChangePasswordModal
                open={changePasswordModalOpen}
                handleClose={onChangePasswordModalClose}
                onChangeClick={onPasswordChange}
            />
        </div>
    );
};

export default React.memo(AdminHeader);
