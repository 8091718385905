import { Dayjs } from 'dayjs';
import React, { ChangeEvent } from 'react';
import { CustomDatePicker, CustomRadioCheckbox } from '../../';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

interface FormCheckedWithDatePickerProps {
	id1: string;
	id2: string;
	counter: string;
	title: string;
	datePickerText: string;
	isChecked: boolean;
	question: string;
	datePickerValue: string;
	onChange: (e: ChangeEvent<any>) => void;
	onDateChange: (value: Dayjs | string) => void;
	errors: {
		dateError?: string;
	};
	disabled?: boolean;
}

const FormOneCheckedWithDatePicker: React.FC<FormCheckedWithDatePickerProps> = ({
	id1,
	id2,
	counter,
	title,
	question,
	isChecked,
	onChange,
	datePickerText,
	datePickerValue,
	onDateChange,
	errors,
	disabled = false,
}) => {
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<div className={styles.line}>
				<div className={styles.counter}>{counter}</div>
				<div className={styles.infoContainer}>
					<div>{title}</div>
					<div>{question}</div>
					{isChecked && (
						<div className={styles.dateLine}>
							<div className={styles.datePickerText}>{datePickerText}</div>
							<CustomDatePicker
								value={datePickerValue}
								id={id1}
								name={id1}
								onChange={onDateChange}
								error={errors.dateError}
								disabled={disabled}
							/>
						</div>
					)}
				</div>
				<div className={styles.formLine}>
					<CustomRadioCheckbox
						id={id2}
						checked={isChecked}
						onChange={onChange}
						label={t('Checked')}
						disabled={disabled}
					/>
				</div>
			</div>
		</div>
	);
};
export default FormOneCheckedWithDatePicker;
