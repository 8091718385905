import * as React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Dayjs } from 'dayjs';

import Icons from '../../Assets';
import styles from './styles.module.css';
import { CustomErrorTooltip } from '..';

interface CustomDatePickerProps {
	value: string;
	id: string;
	name: string;
	title?: string;
	error?: string;
	disabled?: boolean;
	onChange: (value: Dayjs | string) => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
	onChange,
	value,
	id,
	name,
	title,
	error = '',
	disabled = false,
}) => {
	const handleChange = (newValue: Dayjs | null) => {
		if (newValue) {
			onChange(newValue.format('MM/DD/YYYY'));
		} else {
			onChange('');
		}
	};

	let datePickerStyle = styles.container;
	if (disabled) datePickerStyle = styles.disabledContainer;
	else if (error) datePickerStyle = styles.errorContainer;

	return (
		<div className={styles.labelContainer}>
			<div className={styles.inputLabel}>{title}</div>
			<div className={styles.commonContainer}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DesktopDatePicker
						inputFormat='MM/DD/YYYY'
						value={value || null}
						onChange={handleChange}
						InputProps={{
							className: datePickerStyle,
							classes: { root: styles.root },
						}}
						components={{ OpenPickerIcon: Icons.CalendarIcon }}
						renderInput={(params) => <TextField {...params} id={id} name={name} />}
						disabled={disabled}
					/>
				</LocalizationProvider>
				{!disabled && <CustomErrorTooltip text={error} />}
			</div>
		</div>
	);
};

export default CustomDatePicker;
