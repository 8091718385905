import React, {ChangeEvent} from 'react';
import {CustomRadioGroup, CustomInput, CustomDatePicker} from '../../';
import useResponsive from '../../../Hooks/useResponsive';
import styles from './styles.module.scss';
import {useTranslation} from "react-i18next";
import {Dayjs} from "dayjs";

type FormVeteranQuestionProps = {
    id: string;
    counter: string;
    values: {
        optVeteran: string;
        optVeteranReceivedSnap: string;
        txtVeteranSnapPrimaryRecipient: string;
        txtVeteranSnapRecipientLocation: string;
        optVeteranDisability: string;
        optVeteranDischarged: string;
        optVeteranUnemployed: string;
        txtDischargeDate: string;
    };
    errors: {
        txtDischargeDate?: string;
        optVeteran?: string;
        optVeteranReceivedSnap?: string;
        txtVeteranSnapPrimaryRecipient?: string;
        txtVeteranSnapRecipientLocation?: string;
        optVeteranDisability?: string;
        optVeteranDischarged?: string;
        optVeteranUnemployed?: string;
    };
    disabled?: boolean;
    onChange: (e: ChangeEvent<any>) => void;
    datePickerText: string
    datePickerValue: string;
    onDateChange: (value: Dayjs | string) => void;
};

const FormVeteranQuestion: React.FC<FormVeteranQuestionProps> = ({
    id,
                                                                     counter,
                                                                     values,
                                                                     errors,
                                                                     disabled = false,
                                                                     onChange,
                                                                     datePickerText,
                                                                     onDateChange,
                                                                 }) => {
    const {Touch, Desktop} = useResponsive();
    const {t} = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.line}>
                <div className={styles.infoContainer}>
                    <div className={styles.counter}>{counter}</div>
                    <div>{t('Are you a Veteran of the U.S. Armed Forces?')}</div>
                </div>
                <div className={styles.radioGroup}>
                    <CustomRadioGroup
                        groupName={'optVeteran'}
                        onChange={onChange}
                        value={values.optVeteran}
                        error={errors.optVeteran}
                        disabled={disabled}
                    />
                </div>
            </div>
            {values.optVeteran === 'yes' && (
                <>
                    <div className={styles.dateLine}>
                        <div className={styles.datePickerText}>
                            {datePickerText}
                        </div>
                        <CustomDatePicker
                            value={values.txtDischargeDate}
                            id={id}
                            name={id}
                            onChange={onDateChange}
                            error={errors.txtDischargeDate}
                            disabled={disabled}
                        />
                    </div>

                    <div className={styles.line}>
                        <div className={styles.infoContainer}>
                            <div className={styles.counter}>{counter}.1</div>
                            <div>
                                {t('Are you a member of a family that received Supplemental Nutrition Assistance Program (SNAP) benefits (Food Stamps) for at least 3 months during the 15 months before you were hired?')}
                            </div>
                        </div>
                        <div className={styles.radioGroup}>
                            <CustomRadioGroup
                                groupName={'optVeteranReceivedSnap'}
                                onChange={onChange}
                                value={values.optVeteranReceivedSnap}
                                error={errors.optVeteranReceivedSnap}
                                disabled={disabled}
                            />
                        </div>
                    </div>



                    {values.optVeteranReceivedSnap === 'yes' && (
                        <div className={styles.textLine}>
                            <Touch>
                                <>
                                    <CustomInput
                                        id='txtVeteranSnapPrimaryRecipient'
                                        onChange={onChange}
                                        title={t('Primary recipient name')}
                                        value={
                                            values.txtVeteranSnapPrimaryRecipient
                                        }
                                        error={
                                            errors.txtVeteranSnapPrimaryRecipient
                                        }
                                        disabled={disabled}
                                        maxLength={50}
                                        inputClassName={styles.input}
                                    />
                                    <CustomInput
                                        id='txtVeteranSnapRecipientLocation'
                                        onChange={onChange}
                                        title={t('City and state')}
                                        value={
                                            values.txtVeteranSnapRecipientLocation
                                        }
                                        error={
                                            errors.txtVeteranSnapRecipientLocation
                                        }
                                        disabled={disabled}
                                        maxLength={50}
                                        inputClassName={styles.input}
                                    />
                                </>
                            </Touch>
                            <Desktop>
                                <CustomInput
                                    id='txtVeteranSnapPrimaryRecipient'
                                    onChange={onChange}
                                    title={t('Primary recipient name')}
                                    value={
                                        values.txtVeteranSnapPrimaryRecipient
                                    }
                                    error={
                                        errors.txtVeteranSnapPrimaryRecipient
                                    }
                                    leftLabel
                                    disabled={disabled}
                                    maxLength={50}
                                    labelClassName={styles.label}
                                />
                                <CustomInput
                                    id='txtVeteranSnapRecipientLocation'
                                    onChange={onChange}
                                    title={t('City and state')}
                                    value={
                                        values.txtVeteranSnapRecipientLocation
                                    }
                                    error={
                                        errors.txtVeteranSnapRecipientLocation
                                    }
                                    leftLabel
                                    disabled={disabled}
                                    maxLength={50}
                                    labelClassName={styles.label}
                                />
                            </Desktop>
                        </div>
                    )}
                    <div className={styles.line}>
                        <div className={styles.infoContainer}>
                            <div className={styles.counter}>{counter}.2</div>
                            <div>
                                {t('Are you a veteran entitled to compensation for a service-connected disability?')}
                            </div>
                        </div>
                        <div className={styles.radioGroup}>
                            <CustomRadioGroup
                                groupName={'optVeteranDisability'}
                                onChange={onChange}
                                value={values.optVeteranDisability}
                                error={errors.optVeteranDisability}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    {values.optVeteranDisability === 'yes' && (
                        <>
                            <div className={styles.line}>
                                <div className={styles.infoContainer}>
                                    <div className={styles.counter}>
                                        {counter}.3
                                    </div>
                                    <div>
                                        {t('Were you discharged or released from active duty within a year before you were hired?')}
                                    </div>
                                </div>
                                <div className={styles.radioGroup}>
                                    <CustomRadioGroup
                                        groupName={'optVeteranDischarged'}
                                        onChange={onChange}
                                        value={values.optVeteranDischarged}
                                        error={errors.optVeteranDischarged}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                            <div className={styles.line}>
                                <div className={styles.infoContainer}>
                                    <div className={styles.counter}>
                                        {counter}.4
                                    </div>
                                    <div>
                                        {t('Were you unemployed for a combined period of at least 6 months (whether or not consecutive) during the year before you were hired?')}
                                    </div>
                                </div>
                                <div className={styles.radioGroup}>
                                    <CustomRadioGroup
                                        groupName={'optVeteranUnemployed'}
                                        onChange={onChange}
                                        value={values.optVeteranUnemployed}
                                        error={errors.optVeteranUnemployed}
                                        disabled={disabled}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};
export default FormVeteranQuestion;
