import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { restorePassword, setNewPassword, verifyCode } from '../../Api/Auth';

import { UnauthorizedHeader } from '../../Components';
import { clearState } from '../../Slices/AuthSlice';
import { AppDispatch, StoreRootState } from '../../Store';
import ChangePasswordScreen from './Screens/ChangePasswordScreen';
import EnterEmailScreen from './Screens/EnterEmailScreen';
import VerificationCodeScreen from './Screens/VerificationCodeScreen';

import styles from './styles.module.scss';
import validationSchema from './validation';

enum ForgotPasswordScreens {
    ENTER_EMAIL_SCREEN = 'enter_email',
    VERIFICATION_CODE_SCREEN = 'verification_code',
    CHANGE_PASSWORD_SCREEN = 'change_password',
}

const ForgotPasswordPage: React.FC = () => {
    const [selectedScreen, setSelectedScreen] = useState(
        ForgotPasswordScreens.ENTER_EMAIL_SCREEN
    );

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const { auth } = useSelector((state: StoreRootState) => state);

    useEffect(() => {
        dispatch(clearState());
    }, [dispatch]);

    useEffect(() => {
        if (auth.isEmailFound) {
            setSelectedScreen(ForgotPasswordScreens.VERIFICATION_CODE_SCREEN);
        }
    }, [auth.isEmailFound]);

    useEffect(() => {
        if (auth.isVerificationCodeFound) {
            setSelectedScreen(ForgotPasswordScreens.CHANGE_PASSWORD_SCREEN);
        }
    }, [auth.isVerificationCodeFound]);

    useEffect(() => {
        if (auth.isPasswordChanged) {
            dispatch(clearState());
            navigate('/login');
        }
    }, [auth.isPasswordChanged, dispatch, navigate]);

    const onSendClick = () => {
        dispatch(restorePassword(formik.values.email));
    };

    const onMainPageClick = () => {
        navigate('/');
    };

    const onBackToLoginClick = () => {
        navigate('/login');
    };

    const onResendCodeClick = () => {
        dispatch(restorePassword(formik.values.email));
    };

    const onVerifyClick = () => {
        dispatch(
            verifyCode({
                email: values.email,
                code: +values.verificationCode,
            })
        );
    };

    const onChangeClick = () => {
        dispatch(
            setNewPassword({
                userId: auth.id,
                password1: values.password,
                password2: values.repeatedPassword,
            })
        );
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            verificationCode: '',
            password: '',
            repeatedPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {},
    });

    const { values, errors } = formik;

    return (
        <div className={styles.container}>
            <UnauthorizedHeader
                buttonAction={onMainPageClick}
                buttonTitle='Main Screen'
            />
            <div className={styles.contentContainer}>
                <div className={styles.mainImage} />
                {selectedScreen ===
                    ForgotPasswordScreens.ENTER_EMAIL_SCREEN && (
                    <EnterEmailScreen
                        email={values.email}
                        emailError={errors.email}
                        setEmail={formik.handleChange}
                        onLoginClick={onBackToLoginClick}
                        onSendClick={onSendClick}
                    />
                )}
                {selectedScreen ===
                    ForgotPasswordScreens.VERIFICATION_CODE_SCREEN && (
                    <VerificationCodeScreen
                        onResendCodeClick={onResendCodeClick}
                        verificationCode={values.verificationCode}
                        verificationCodeError={errors.verificationCode}
                        setVerificationCode={formik.handleChange}
                        onVerifyClick={onVerifyClick}
                    />
                )}
                {selectedScreen ===
                    ForgotPasswordScreens.CHANGE_PASSWORD_SCREEN && (
                    <ChangePasswordScreen
                        onChangeClick={onChangeClick}
                        password={values.password}
                        passwordError={errors.password}
                        setPassword={formik.handleChange}
                        repeatedPassword={values.repeatedPassword}
                        repeatedPasswordError={errors.repeatedPassword}
                        setRepeatedPassword={formik.handleChange}
                    />
                )}
            </div>
        </div>
    );
};

export default ForgotPasswordPage;

///email -> verif code -> pasword/conf.password
