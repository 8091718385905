import { ChangeEvent } from 'react';
import styles from './styles.module.scss';
import { CustomInput } from '../../';

interface EmployeersHeaderProps {
	values: {
		txtFEIN: string;
		txtErName: string;
		txtErPhone: string;
		txtErStreet: string;
		txtErCity: string;
		txtErState: string;
		txtErZip: string;
		txtContactName: string;
		txtContactPhone: string;
		txtContactStreet: string;
		txtContactCity: string;
		txtContactState: string;
		txtContactZip: string;
		txtInfoDate: string;
		txtOfferDate: string;
		txtHireDate: string;
		txtStartDate: string;
	};
	errors: {
		txtFEIN?: string;
		txtErName?: string;
		txtErPhone?: string;
		txtErStreet?: string;
		txtErCity?: string;
		txtErState?: string;
		txtErZip?: string;
		txtContactName?: string;
		txtContactPhone?: string;
		txtContactStreet?: string;
		txtContactCity?: string;
		txtContactState?: string;
		txtContactZip?: string;
		txtInfoDate?: string;
		txtOfferDate?: string;
		txtHireDate?: string;
		txtStartDate?: string;
	};
	onChange: (e: ChangeEvent<any>) => void;
	disabled?: boolean;
}

const EmployeersHeader: React.FC<EmployeersHeaderProps> = ({
	onChange,
	values,
	errors,
	disabled = false,
}) => {
	return (
		<div className={styles.container}>
			<div className={styles.textHeader}>For Employer's Use Only</div>
			<div className={styles.line}>
				<CustomInput
					title='FEIN'
					onChange={onChange}
					value={values.txtFEIN}
					error={errors.txtFEIN}
					id='txtFEIN'
					disabled={disabled}
					maxLength={10}
					hint='(xx-xxxxxxx)'
					containerClassName={styles.feinInput}
				/>
				<CustomInput
					title={`Employer's name`}
					onChange={onChange}
					value={values.txtErName}
					error={errors.txtErName}
					id='txtErName'
					disabled={disabled}
					maxLength={50}
					containerClassName={styles.nameInput}
				/>
				<CustomInput
					title='Phone'
					onChange={onChange}
					value={values.txtErPhone}
					error={errors.txtErPhone}
					id='txtErPhone'
					disabled={disabled}
					maxLength={14}
					hint='((xxx) xxx-xxxx)'
					containerClassName={styles.phoneInput}
				/>
			</div>
			<div className={styles.line}>
				<CustomInput
					title='Street Address'
					onChange={onChange}
					value={values.txtErStreet}
					error={errors.txtErStreet}
					id='txtErStreet'
					disabled={disabled}
					maxLength={50}
					containerClassName={styles.addressInput}
				/>
				<CustomInput
					title='City or Town'
					onChange={onChange}
					value={values.txtErCity}
					error={errors.txtErCity}
					id='txtErCity'
					disabled={disabled}
					maxLength={50}
					containerClassName={styles.cityInput}
				/>
				<CustomInput
					title='State'
					onChange={onChange}
					value={values.txtErState}
					error={errors.txtErState}
					id='txtErState'
					disabled={disabled}
					maxLength={2}
					containerClassName={styles.stateInput}
				/>
				<CustomInput
					title='ZIP Code'
					onChange={onChange}
					value={values.txtErZip}
					error={errors.txtErZip}
					id='txtErZip'
					disabled={disabled}
					maxLength={5}
					containerClassName={styles.zipInput}
				/>
			</div>
			<div className={styles.line}>
				<CustomInput
					title='Contact name'
					onChange={onChange}
					value={values.txtContactName}
					error={errors.txtContactName}
					id='txtContactName'
					disabled={disabled}
					maxLength={50}
					containerClassName={styles.nameInput}
				/>
				<CustomInput
					title='Phone'
					onChange={onChange}
					value={values.txtContactPhone}
					error={errors.txtContactPhone}
					id='txtContactPhone'
					disabled={disabled}
					maxLength={14}
					hint='((xxx) xxx-xxxx)'
					containerClassName={styles.phoneInput}
				/>
			</div>
			<div className={styles.line}>
				<CustomInput
					title='Street Address'
					onChange={onChange}
					value={values.txtContactStreet}
					error={errors.txtContactStreet}
					id='txtContactStreet'
					disabled={disabled}
					maxLength={50}
					containerClassName={styles.addressInput}
				/>
				<CustomInput
					title='City or Town'
					onChange={onChange}
					value={values.txtContactCity}
					error={errors.txtContactCity}
					id='txtContactCity'
					disabled={disabled}
					maxLength={50}
					containerClassName={styles.cityInput}
				/>
				<CustomInput
					title='State'
					onChange={onChange}
					value={values.txtContactState}
					error={errors.txtContactState}
					id='txtContactState'
					disabled={disabled}
					maxLength={2}
					containerClassName={styles.stateInput}
				/>
				<CustomInput
					title='ZIP Code'
					onChange={onChange}
					value={values.txtContactZip}
					error={errors.txtContactZip}
					id='txtContactZip'
					disabled={disabled}
					maxLength={5}
					containerClassName={styles.zipInput}
				/>
			</div>
			<div className={styles.line}>
				<CustomInput
					title='Gave Information:'
					onChange={onChange}
					value={values.txtInfoDate}
					error={errors.txtInfoDate}
					id='txtInfoDate'
					disabled
					placeholder='MM/DD/YYYY'
					maxLength={10}
					containerClassName={styles.nameInput}
				/>
				<CustomInput
					title='Was offered job:'
					onChange={onChange}
					value={values.txtOfferDate}
					error={errors.txtOfferDate}
					id='txtOfferDate'
					disabled
					placeholder='MM/DD/YYYY'
					maxLength={10}
					containerClassName={styles.nameInput}
				/>
				<CustomInput
					title='Was hired:'
					onChange={onChange}
					value={values.txtHireDate}
					error={errors.txtHireDate}
					id='txtHireDate'
					disabled
					placeholder='MM/DD/YYYY'
					maxLength={10}
					containerClassName={styles.nameInput}
				/>
				<CustomInput
					title='Started job:'
					onChange={onChange}
					value={values.txtStartDate}
					error={errors.txtStartDate}
					id='txtStartDate'
					disabled
					placeholder='MM/DD/YYYY'
					maxLength={10}
					containerClassName={styles.nameInput}
				/>
			</div>
		</div>
	);
};

export default EmployeersHeader;
