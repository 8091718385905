import {Dialog} from '@mui/material';
import {useFormik} from 'formik';
import {validationSchemaCreate, validationSchemaUpdate} from './validation';
import {HrUser} from '../../Slices/UserSlice';
import {Company} from '../../Slices/CompanySlice';
import Icons from '../../Assets';
import {CustomButton, CustomInput, CustomSelect} from '..';
import styles from './styles.module.scss';
import React from "react";

export enum UserMode {
    EDIT = 'edit',
    CREATE = 'create',
}

interface UserCreateModalProps {
    open: boolean;
    handleClose: () => void;
    user: HrUser;
    mode?: UserMode;
    companies: Array<Company>;
    onAction: (values: HrUser, id?: number) => void;
}

const UserInfoModal: React.FC<UserCreateModalProps> = (
    {
        open,
        handleClose,
        onAction,
        companies,
        mode = UserMode.EDIT,
        user
    }) => {

    const onDialogClose = (_: any, reason: string) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
            resetForm();
        }
    };

    const onClose = () => {
        handleClose();
        resetForm();
    };

    const initialValues = mode === UserMode.CREATE ? {
        name: "",
        email: "",
        password: "",
        companyNames: []
    } : {
        name: user.name,
        email: user.email,
        companyNames: user.companies.map(company => company.companyName),
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: mode === UserMode.CREATE ? validationSchemaCreate : validationSchemaUpdate,
        onSubmit: (values: any) => {
            onAction(values, mode === UserMode.CREATE ? undefined : user.id);
            resetForm();
        },
    });

    const {
        errors,
        values,
        resetForm,
        handleChange,
        submitForm,
        setFieldValue,
        setFieldTouched,
        isValid,
    } = formik;

    const selectValues = companies.map((item) => {
        return {
            text: item.companyName,
            value: item.companyName,
        };
    });

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 250,
                width: 250,
            },
        },
    };

    const isSubmitEnabled = isValid && values.email.length > 0 && values.companyNames.length > 0;

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            maxWidth='lg'
            classes={{paper: styles.dialog}}
        >
            <div className={styles.container}>
                <Icons.CloseModalIcon
                    onClick={onClose}
                    className={styles.closeIcon}
                />
                <div className={styles.title}>{mode === UserMode.CREATE ? `Create Company's User` : `Edit Company's User`}</div>
                <div className={styles.fieldsContainer}>
                    <CustomInput
                        id='name'
                        onChange={handleChange}
                        title='Name'
                        value={values.name}
                        error={errors.name}
                        width='100%'
                        inputClassName={styles.emailInput}
                    />
                    <CustomInput
                        id='email'
                        onChange={handleChange}
                        title='Email'
                        value={values.email}
                        error={errors.email}
                        width='100%'
                        inputClassName={styles.emailInput}
                    />
                    {
                        mode === UserMode.CREATE ?
                            <CustomInput
                                id='password'
                                onChange={handleChange}
                                title='Password'
                                value={values.password}
                                error={errors.password}
                                maxLength={16}
                                type='password'
                                width='100%'
                                inputClassName={styles.passwordInput}
                            /> : ""
                    }

                    <CustomSelect
                        id='companyName'
                        selectedValue={values.companyNames}
                        values={selectValues}
                        onChange={(e) => {
                            setFieldValue('companyNames', typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value);
                            setTimeout(() =>
                                setFieldTouched('companyName', true)
                            );
                        }}
                        title='Company'
                        width='calc(100% - 25px)'
                        MenuProps={MenuProps}
                        type={'multiselect'}
                    />
                </div>

                <div className={styles.buttonContainer}>
                    <CustomButton
                        title='Save'
                        onClickHandler={submitForm}
                        disabled={!isSubmitEnabled}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default UserInfoModal;
