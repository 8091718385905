import CustomButton from '../CustomButton';
import Logo from '../Logo';
import styles from './styles.module.scss';

type UnauthorizedHeaderProps = {
    buttonTitle: string;
    buttonAction: () => void;
};

const UnauthorizedHeader: React.FC<UnauthorizedHeaderProps> = ({
    buttonAction,
    buttonTitle,
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Logo />
                <CustomButton
                    title={buttonTitle}
                    onClickHandler={buttonAction}
                />
            </div>
            <div className={styles.separator} />
        </div>
    );
};

export default UnauthorizedHeader;
