import { AsyncThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import { axios, axiosErrorHandler } from './setupAxios';
import { BackFormData, EditableFormData } from '../types';
import { API_PATH } from './constants';

type PaginationData = {
	page: number;
	perPage: number;
};

export type CsvData = {
	fromDate: string;
	thruDate: string;
	companyName: string;
};

type SearchData = PaginationData & {
	search: string;
	direction?: string;
};

type SearchDataWithCompanyId = SearchData & {
	companyId: number;
};

export type UpdateFormData = {
	refreshFunction: () => AsyncThunkAction<any, any, any>;
};

export type DeleteFormData = {
	formId: number;
	refreshFunction: () => AsyncThunkAction<any, any, any>;
};

const getFormsWithPagination = createAsyncThunk(
	'forms/getFormsWithPagination',
	async (data: PaginationData) => {
		const take = data.perPage;
		const skip = (data.page - 1) * data.perPage;
		const result = await axios.get(`${API_PATH}/forms?take=${take}&skip=${skip}`);
		return result.data;
	},
);

const getFormsWithSearch = createAsyncThunk(
	'forms/getFormsWithSearch',
	async (data: SearchData) => {
		const direction = data.direction;
		const take = data.perPage;
		const skip = (data.page - 1) * data.perPage;
		const result = await axios.post(
			`${API_PATH}/forms/filter_by_name?take=${take}&skip=${skip}&sort=${direction}`,
			{
				name: data.search,
			},
		);
		return result.data;
	},
);

const getFormsByCompanyId = createAsyncThunk(
	'forms/getFormsByCompanyId',
	async (data: SearchDataWithCompanyId, { rejectWithValue }) => {
		try {
			const direction = data.direction;
			const take = data.perPage;
			const skip = (data.page - 1) * data.perPage;
			const { companyId } = data;
			const result = await axios.post(
				`${API_PATH}/forms/filter_by_company_id_and_name?take=${take}&skip=${skip}&companyId=${companyId}&sort=${direction}`,
				{
					name: data.search,
				},
			);
			return result.data;
		} catch (e) {
			return rejectWithValue(axiosErrorHandler(e));
		}
	},
);

const getHrForms = createAsyncThunk(
	'forms/getHrForms',
	async (data: SearchData, { rejectWithValue }) => {
		try {
			const direction = data.direction;
			const take = data.perPage;
			const skip = (data.page - 1) * data.perPage;
			const result = await axios.post(
				`${API_PATH}/forms/filter?take=${take}&skip=${skip}&sort=${direction}`,
				{
					name: data.search,
				},
			);
			return result.data;
		} catch (e) {
			return rejectWithValue(axiosErrorHandler(e));
		}
	},
);

const submitForm = createAsyncThunk(
	'forms/submitForm',
	async (data: BackFormData, { rejectWithValue }) => {
		try {
			const result = await axios.post(`${API_PATH}/client/forms/fill_the_form`, data);
			return result.data;
		} catch (e) {
			return rejectWithValue(axiosErrorHandler(e));
		}
	},
);

const updateFormsStatus = createAsyncThunk(
	'forms/updateFormsStatus',
	async (updateData: UpdateFormData, { dispatch, rejectWithValue }) => {
		try {
			const result = await axios.post(`${API_PATH}/forms/table_data`);
			dispatch(updateData.refreshFunction());
			return result.data;
		} catch (e) {
			return rejectWithValue(axiosErrorHandler(e));
		}
	},
);

const submitExistingForm = createAsyncThunk(
	'forms/submitExistingForm',
	async (id: number, { rejectWithValue }) => {
		try {
			const result = await axios.post(`${API_PATH}/forms/fill_existing_form?id=${id}`);
			return result.data;
		} catch (e) {
			return rejectWithValue(axiosErrorHandler(e));
		}
	},
);

const updateFormValues = createAsyncThunk(
	'forms/updateFormValues',
	// BackFormData EditableFormData
	async (data: EditableFormData, { rejectWithValue }) => {
		try {
			const result = await axios.patch(`${API_PATH}/forms/update_check_items?id=${data.id}`, data);
			return result.data;
		} catch (e) {
			return rejectWithValue(axiosErrorHandler(e));
		}
	},
);

const getFormDataById = createAsyncThunk(
	'forms/getFormDataById',
	async (formId: number, { rejectWithValue }) => {
		try {
			const result = await axios.get(`${API_PATH}/forms/get_by_id?id=${formId}`);
			return result.data;
		} catch (e) {
			return rejectWithValue(axiosErrorHandler(e));
		}
	},
);

const generateHrLinkToForm = createAsyncThunk(
	'forms/generateHrLink',
	async (companyId: number, { rejectWithValue }) => {
		try {
			return Promise.resolve('generated');
		} catch (e) {
			return rejectWithValue(axiosErrorHandler(e));
		}
	},
);

const getCompanyInfoByLink = createAsyncThunk(
	'forms/getCompanyInfoByLink',
	async (link: string, { rejectWithValue }) => {
		try {
			const result = await axios.post(`${API_PATH}/client/companies/check_link`, {
				url: link,
			});
			return result.data;
		} catch (e) {
			return rejectWithValue(axiosErrorHandler(e));
		}
	},
);

const getCSV = createAsyncThunk('forms/getCSV', async (values: CsvData, { rejectWithValue }) => {
	try {
		const result = await axios.post(`${API_PATH}/forms/export_to_csv`, {
			fromDate: values.fromDate,
			companyName: values.companyName === 'all' ? '' : values.companyName,
			thruDate: values.thruDate,
		});

		const link = document.createElement('a');
		link.href = result.data;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		return result.data;
	} catch (e) {
		return rejectWithValue(axiosErrorHandler(e));
	}
});

const deleteForm = createAsyncThunk(
	'forms/deleteForm',
	async (deleteData: DeleteFormData, { dispatch, rejectWithValue }) => {
		try {
			const { refreshFunction, formId } = deleteData;

			const result = await axios.delete(`${API_PATH}/forms/delete_by_id?id=${formId}`);
			dispatch(refreshFunction());
			return result.data;
		} catch (e) {
			return rejectWithValue(axiosErrorHandler(e));
		}
	},
);

const getFormToPrint = createAsyncThunk(
	'forms/printForm',
	async (data: any, { rejectWithValue }) => {
		try {
			const result = await axios.post(`${API_PATH}/client/forms/${data.formId}/${data.option}`);

			if (data.option === 'download_both_pdf') {
				window.open(result.data[0]);
				window.location.href = result.data[1];
			} else {
				window.open(result.data);
			}
		} catch (e) {
			return rejectWithValue(axiosErrorHandler(e));
		}
	},
);

export {
	getHrForms,
	getFormsWithPagination,
	submitForm,
	getFormDataById,
	generateHrLinkToForm,
	getCompanyInfoByLink,
	getFormsWithSearch,
	getCSV,
	getFormsByCompanyId,
	updateFormValues,
	submitExistingForm,
	deleteForm,
	updateFormsStatus,
	getFormToPrint,
};
