import { Dayjs } from 'dayjs';
import React, { ChangeEvent } from 'react';
import { CustomDatePicker } from '../../';
import styles from './styles.module.scss';

interface FormWithDatePickerProps {
	id: string;
	counter: string;
	topText: string;
	datePickerText: string;
	datePickerValue: string;
	onChange: (e: ChangeEvent<any>) => void;
	onDateChange: (value: Dayjs | string) => void;
	errors: {
		dateError?: string;
	};
	disabled?: boolean;
}

const FormWithDatePicker: React.FC<FormWithDatePickerProps> = ({
	id,
	counter,
	topText,
	datePickerText,
	datePickerValue,
	onDateChange,
	errors,
	disabled = false,
}) => {
	return (
		<div className={styles.container}>
			<div className={styles.line}>
				<div className={styles.counter}>{counter}</div>
				<div className={styles.infoContainer}>
					<div className={styles.dateLine}>
						<div className={styles.datePickerText}>{topText}</div>
						<CustomDatePicker
							value={datePickerValue}
							id={id}
							name={id}
							onChange={onDateChange}
							error={errors.dateError}
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default FormWithDatePicker;
