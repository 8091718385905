import {Tooltip} from '@mui/material';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {FORM_STATUS} from '../../types';
import {CustomTablePagination, StatusLabel, LoadingIndicator} from '..';
import Icons from '../../Assets';
import {StoreRootState} from '../../Store';
import {LOADER_VERSION} from '../LoadingIndicator';

import styles from './styles.module.scss';
import PrintModal from "../PrintModal/PrintModal";

type AdminEmployeesTableProps = {
    values: Array<{
        id: number;
        appId: string;
        name: string;
        company: {
            city: string;
            companyName: string;
            contactName: string;
            fein: string;
            id: number;
            phone: string;
            state: string;
            street: string;
            url: string;
            zip: string;
        } | null;
        fein: string;
        ssn: string;
        postmarkDate: string;
        status: FORM_STATUS;
        determinationDate: string;
        isAgeBetween: boolean;
        groupType: string;
    }>;
    pageCount?: number;
    currentPage: number;
    noResultsPlaceholder: string;
    onRowClick: (id: string) => void;
    onDownloadClick: (id: number) => void;
    onPageChange: (page: number) => void;
    onSendClick: (id: number) => void;
    onDeleteClick: (id: number) => void;
    onSortClick: () => void;
    isAsc: boolean;
};

const AdminEmployeesTable: React.FC<AdminEmployeesTableProps> = ({
                                                                     values = [],
                                                                     currentPage,
                                                                     noResultsPlaceholder,
                                                                     pageCount = 10,
                                                                     onRowClick,
                                                                     onDownloadClick,
                                                                     onPageChange,
                                                                     onSendClick,
                                                                     onDeleteClick,
                                                                     onSortClick,
                                                                     isAsc
                                                                 }) => {
    const [printFormModalOpen, setPrintFormModalOpen] = useState(false);
    const [printedFormId, setPrintedFormId] = useState(-1);

    const onPrintModalOpen = (e: React.MouseEvent<SVGSVGElement>, id: number) => {
        e.stopPropagation();
        setPrintedFormId(id)
        setPrintFormModalOpen(true)
    }

    const onPrintModalClose = () => {
        setPrintFormModalOpen(false)
    }


    const {formSubmitList} = useSelector(
        (state: StoreRootState) => state.formSubmit
    );

    const pageChangeHandler = (selectedItem: { selected: number }) => {
        onPageChange(selectedItem.selected);
    };

    const downloadClickHandler = (
        e: React.MouseEvent<SVGSVGElement>,
        id: number
    ) => {
        e.stopPropagation();
        onDownloadClick(id);
    };

    const sendClickHandler = (
        e: React.MouseEvent<SVGSVGElement>,
        id: number
    ) => {
        e.stopPropagation();
        onSendClick(id);
    };

    const isEmptyContainer = values.length === 0;

    return (
        <div className={styles.container}>
            <div className={styles.tableContainer}>
                {!isEmptyContainer && (
                    <table className={styles.table}>
                        <thead>
                        <tr className={styles.headerRow}>
                            <th className={styles.headerCell}>App ID</th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Name
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Company
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                FEIN
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                SSN
                            </th>

                            <th onClick={onSortClick}
                                className={styles.headerCell}
                                align='center'
                            >
                                <div className={styles.clickable}>
                                    <span>Date received</span>
                                    {isAsc ? <Icons.ArrowUpIcon style={{flexShrink: 0}}/> :
                                        <Icons.ArrowDownIcon style={{flexShrink: 0}}/>}
                                </div>

                            </th>

                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Status
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Determination Date
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {values.map((item) => {
                            const canBeDownloaded =
                                item.status === FORM_STATUS.CERTIFIED ||
                                item.status === FORM_STATUS.DENIED;

                            const canBeSent =
                                item.status ===
                                FORM_STATUS.APPROVE_WAITING ||
                                item.isAgeBetween;

                            const hasAppId = item.appId

                            const isInSentProgress =
                                formSubmitList.includes(item.id);

                            return (
                                <tr
                                    key={item.appId + item.name + item.ssn}
                                    className={styles.tableRow}
                                    onClick={() =>
                                        onRowClick(item.id.toString())
                                    }
                                >
                                    <td className={styles.cell}>
                                        {item.appId}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item.name}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item?.company?.companyName}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item.fein}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item.ssn}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {new Date(
                                            item.postmarkDate
                                        ).toLocaleDateString('en-US')}
                                    </td>
                                    <td align='center'>
                                        <StatusLabel status={item.status}/>
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item.determinationDate
                                            ? new Date(
                                                item.determinationDate
                                            ).toLocaleDateString('en-US')
                                            : 'In progress'}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {!isInSentProgress && (
                                            <Tooltip title={'Print form'}>
                                                <Icons.Print
                                                    className={styles.printIcon}
                                                    onClick={(e) => onPrintModalOpen(e, item.id)}
                                                />
                                            </Tooltip>
                                        )}

                                        {canBeDownloaded && (
                                            <Tooltip title='Download form'>
                                                <Icons.TableDownloadIcon
                                                    className={
                                                        styles.downloadIcon
                                                    }
                                                    onClick={(e) =>
                                                        downloadClickHandler(
                                                            e,
                                                            item.id
                                                        )
                                                    }
                                                />
                                            </Tooltip>
                                        )}

                                        {/*canBeSent && !hasAppId &&*/}
                                        {!isInSentProgress && (
                                            <Tooltip title='Delete form'>
                                                <Icons.DeleteFormIcon
                                                    className={hasAppId ?
                                                        `${styles.deleteIcon} ${styles.disabledIcon}` : styles.deleteIcon
                                                    }
                                                    onClick={hasAppId ? (e) => {
                                                        e.stopPropagation();
                                                    } : (e) => {
                                                        e.stopPropagation();
                                                        onDeleteClick(
                                                            item.id
                                                        );
                                                    }}
                                                />
                                            </Tooltip>
                                        )}

                                        {!isInSentProgress && (
                                            <Tooltip title='Send form'>
                                                <Icons.SendFormIcon
                                                    className={hasAppId ?
                                                        `${styles.sendIcon} ${styles.disabledIcon}` : styles.sendIcon
                                                    }
                                                    onClick={hasAppId ? (e) => {
                                                        e.stopPropagation();
                                                    } : (e) =>
                                                        sendClickHandler(
                                                            e,
                                                            item.id
                                                        )
                                                    }
                                                />
                                            </Tooltip>
                                        )}

                                        {canBeSent && isInSentProgress && (
                                            <LoadingIndicator
                                                size={20}
                                                version={
                                                    LOADER_VERSION.CLIP
                                                }
                                            />
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                )}
                {isEmptyContainer && (
                    <div className={styles.noResultsFound}>
                        {noResultsPlaceholder}
                    </div>
                )}
            </div>
            <PrintModal open={printFormModalOpen} close={onPrintModalClose} formId={printedFormId}/>

            <CustomTablePagination
                onPageChange={pageChangeHandler}
                pageCount={pageCount}
                currentPage={currentPage}
            />
        </div>
    );
};

export default AdminEmployeesTable;
