import * as React from 'react';

import styles from './styles.module.scss';

interface ICustomIconButtonProps {
    icon: React.ReactNode;
    title: string;
    disabled?: boolean;
    onClick: () => void;
    type?: string;
    form?: string;
}

const CustomIconButton: React.FC<ICustomIconButtonProps> = ({
    icon,
    title,
    disabled = false,
    onClick,
}) => {
    const onButtonClick = () => {
        if (disabled) return;
        else onClick();
    };

    return (
        <div
            className={disabled ? styles.disabledContainer : styles.container}
            onClick={onButtonClick}
        >
            {icon}
            <div className={styles.title}>{title}</div>
        </div>
    );
};

export default CustomIconButton;
