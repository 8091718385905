import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {PER_PAGE} from '../../Api/constants';
import {
    deleteForm,
    DeleteFormData,
    getFormsByCompanyId, getFormsWithSearch,
    submitExistingForm, UpdateFormData, updateFormsStatus,
} from '../../Api/Forms';
import {
    CustomSearchInput,
    AdminEmployeesTable,
    CustomTabs,
    LoadingIndicator,
    DeleteModal,
    AdminHeader, UpdateStatusButton
} from '../../Components';
import {LOADER_VERSION} from '../../Components/LoadingIndicator';
import useDebounce from '../../Hooks/useDebounce';
import {setReportForDelete, setReportsPage} from '../../Slices/ReportsSlice';
import {AppDispatch, StoreRootState} from '../../Store';
import {TabData} from '../../types';
import styles from './styles.module.scss';

const AdminCompanyPage: React.FC = () => {
    const {companyId} = useParams();

    const [employeesSearch, setEmployeesSearch] = useState('');
    const onEmployeesSearchChange = (e: React.ChangeEvent<any>) => {
        setEmployeesSearch(e.target.value);
    };

    const [deleteFormModalOpen, setDeleteFormModalOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const [selectedTab, setSelectedTab] = useState('employees')

    const [ asc, setAsc] = useState<boolean>(false);
    const sort = asc? "asc" : "desc";

    const changeSortDirection = () => {
        setAsc(!asc)
    }

    const onTabChange = (id: string) => {
        if (id === 'companies') {
            navigate('/reports');
        }
        setSelectedTab(id);
    };

    const showForm = (id: string) => {
        navigate(`/review/admin/${id}`);
    };

    const onEmployeePageChange = (page: number) => {
        dispatch(setReportsPage(page + 1));
    };

    const onFormSend = (formId: number) => {
        dispatch(submitExistingForm(formId));
    };

    const onDeleteFormModalOpen = (formId: number) => {
        setDeleteFormModalOpen(true);
        dispatch(setReportForDelete(formId));
    };

    const onDeleteFormModalClose = () => {
        setDeleteFormModalOpen(false);
    };

    const onFormDelete = () => {
        dispatch(setReportsPage(1));
        setEmployeesSearch('');
        let currentCompany = -1;
        if (companyId !== undefined) {
            currentCompany = +companyId;
        }

        const deleteData: DeleteFormData = {
            formId: selectedReportForDelete?.id || -1,
            refreshFunction: () =>
                getFormsByCompanyId({
                    direction: sort,
                    page: reportsPage,
                    perPage: PER_PAGE,
                    search: '',
                    companyId: currentCompany,
                }),
        };
        dispatch(deleteForm(deleteData));
        setDeleteFormModalOpen(false);
    };

    const {
        adminReports,
        reportsPage,
        totalReportsPages,
        isReportsLoading,
        selectedReportForDelete,
        isReportsStatusUpdating
    } = useSelector((state: StoreRootState) => state.reports);

    const debouncedEmployeesSearch = useDebounce(employeesSearch, 500);

    useEffect(() => {
        if (companyId) {
            dispatch(
                getFormsByCompanyId({
                    direction: sort,
                    page: reportsPage,
                    perPage: PER_PAGE,
                    search: debouncedEmployeesSearch,
                    companyId: +companyId,
                })
            );
        }
    }, [dispatch, companyId, reportsPage, debouncedEmployeesSearch, sort]);

    useEffect(() => {
        dispatch(setReportsPage(1));
    }, [dispatch, debouncedEmployeesSearch]);

    const onFormsStatusUpdate = () => {
        const updateData: UpdateFormData = {
            refreshFunction: () =>
                getFormsWithSearch({
                    direction: sort,
                    page: 1,
                    perPage: PER_PAGE,
                    search: '',
                }),
        };

        dispatch(updateFormsStatus(updateData));
        dispatch(setReportsPage(1));
        setEmployeesSearch('');
    };

    const employeesTabComponent = (
        <>
            <div className={styles.actionComponents}>
                <div className={styles.searchContainer}>
                    <CustomSearchInput
                        placeholder='Search by name'
                        value={employeesSearch}
                        onChange={onEmployeesSearchChange}
                        className={styles.searchInput}
                    />
                    {isReportsLoading && (
                        <LoadingIndicator
                            size={40}
                            version={LOADER_VERSION.CLIP}
                        />
                    )}
                </div>
                <div className={styles.buttonsContainer}>
                    <UpdateStatusButton
                        title='Update status'
                        onClick={onFormsStatusUpdate}
                        disabled={isReportsStatusUpdating}
                    />
                </div>
            </div>
            <div className={styles.tableContainer}>
                <AdminEmployeesTable
                    noResultsPlaceholder='No forms found'
                    currentPage={reportsPage}
                    values={adminReports}
                    onRowClick={showForm}
                    pageCount={totalReportsPages}
                    onPageChange={onEmployeePageChange}
                    onDownloadClick={() => ''}
                    onSendClick={onFormSend}
                    onDeleteClick={onDeleteFormModalOpen}
                    isAsc={asc}
                    onSortClick={changeSortDirection}/>
            </div>
            <DeleteModal
                open={deleteFormModalOpen}
                onAction={onFormDelete}
                title={`Are you sure you want to delete form '${selectedReportForDelete?.name}'?`}
                deleteButtonTitle='Delete form'
                handleClose={onDeleteFormModalClose}
            />
        </>
    );

    const tabs: Array<TabData> = [
        {
            name: 'Companies',
            id: 'companies',
            component: <div></div>,
        },
        {
            name: 'Employees',
            id: 'employees',
            component: employeesTabComponent,
        },
    ];

    return (
        <>
            <div className={styles.container}>
                <AdminHeader/>
                <div className={styles.content}>
                    <CustomTabs
                        tabs={tabs}
                        selectedId={selectedTab}
                        onTabChange={onTabChange}
                    />
                </div>
            </div>
        </>
    );
};

export default AdminCompanyPage;
