import ReactPaginate from 'react-paginate';
import styles from './styles.module.scss';

type CustomTablePaginationProps = {
    onPageChange: (selectedItem: { selected: number }) => void;
    pageCount: number;
    currentPage: number;
};

const CustomTablePagination: React.FC<CustomTablePaginationProps> = ({
    onPageChange,
    pageCount,
    currentPage,
}) => {
    return (
        <ReactPaginate
            breakLabel='...'
            nextLabel='>'
            nextClassName={styles.next}
            previousClassName={styles.previous}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={onPageChange}
            pageCount={pageCount}
            forcePage={currentPage - 1}
            previousLabel='<'
            renderOnZeroPageCount={() => null}
            containerClassName={styles.container}
            pageClassName={styles.page}
            breakClassName={styles.page}
            pageLinkClassName={styles.link}
            activeLinkClassName={styles.activePage}
        />
    );
};

export default CustomTablePagination;
