import { createSlice } from '@reduxjs/toolkit';
import { PER_PAGE } from '../../Api/constants';
import {
    deleteForm,
    getCSV,
    getFormDataById,
    getFormsByCompanyId,
    getFormsWithSearch, getHrForms,
    updateFormsStatus,
} from '../../Api/Forms';
import {
    BackendToFrontendFormDataConverter,
    convertFormStatus,
    sendNotification,
} from '../../Common/common';
import {
    FrontFormData,
    FORM_STATUS,
    NOTIFICATION_STATUS,
    BackFormData,
} from '../../types';

type Report = {
    id: number;
    appId: string;
    name: string;
    company: {
        city: string;
        companyName: string;
        contactName: string;
        fein: string;
        id: number;
        phone: string;
        state: string;
        street: string;
        url: string;
        zip: string;
    } | null;
    fein: string;
    ssn: string;
    postmarkDate: string;
    status: FORM_STATUS;
    determinationDate: string;
    groupType: string;
    isAgeBetween: boolean;
};

export interface ReportsState {
    adminReports: Array<Report>;
    totalReportsPages: number;
    selectedReport: FrontFormData | null;
    selectedReportForDelete: Report | null;
    reportMainData: {
        appId: string;
        determinationDate: string;
        id: number;
        name: string;
        postmarkDate: string;
        status: any;
        fein: string;
        ssn: string;
        signedBy: string;
    };
    isReportsLoading: boolean;
    isReportsStatusUpdating: boolean;
    csvDownloadLink: string;

    lastLocation: string;
    reportsPage: number;
    sortDirectionAsc: boolean;
}

const initialState: ReportsState = {
    adminReports: [],
    selectedReport: null,
    reportMainData: {
        id: -1,
        appId: 'XXXXX',
        name: 'Unknown',
        fein: 'XXXXX',
        ssn: 'XXXXX',
        postmarkDate: '09/08/2022',
        determinationDate: '09/08/2022',
        status: FORM_STATUS.PENDING,
        signedBy: '',
    },
    reportsPage: 1,
    totalReportsPages: 1,
    isReportsLoading: false,
    isReportsStatusUpdating: false,
    selectedReportForDelete: null,
    csvDownloadLink: '',
    lastLocation: '',

    sortDirectionAsc: false
};

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setDirection: (state, action) => {
            state.sortDirectionAsc = action.payload
        },
        setReportsPage: (state, action) => {
            state.reportsPage = action.payload;
        },

        setLastLocation: (state, action)=> {
            state.lastLocation = action.payload
        },

        changeGroupValue: (state, action) => {
            const { value, appId } = action.payload;
            state.adminReports = state.adminReports.map((item) => {
                return {
                    ...item,
                    groupType: item.appId === appId ? value : item.groupType,
                };
            });
        },
        setReportForDelete: (state, action) => {
            const deleteId = action.payload;
            const selectedReport =
                state.adminReports.find((item) => item.id === deleteId) || null;
            state.selectedReportForDelete = selectedReport;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getFormsWithSearch.pending, (state) => {
            state.isReportsLoading = true;
        });

        builder.addCase(getFormsWithSearch.fulfilled, (state, { payload }) => {
            state.adminReports = payload[0].map((item: any) => {
                return {
                    id: item.id,
                    appId: item.appId,
                    name: item.name || '',
                    fein: item.txtFEIN || '',
                    ssn: item.txtSSN || '',
                    postmarkDate: item.postmarkDate || '',
                    status: convertFormStatus(item.status),
                    determanationDate: item.determanationDate || '',
                    groupType: item.groupType || 0,
                    company: item.company || null,
                };
            });
            state.totalReportsPages = Math.ceil(payload[1] / PER_PAGE);
            state.isReportsLoading = false;
        });

        builder.addCase(getFormsWithSearch.rejected, (state, action) => {
            state.isReportsLoading = false;
            state.adminReports = [];
            sendNotification(
                (action.payload as string) || 'Error with reports loading',
                NOTIFICATION_STATUS.ERROR
            );
        });


        builder.addCase(getHrForms.pending, (state) => {
            state.isReportsLoading = true;
        })
        builder.addCase(getHrForms.fulfilled, (state, { payload }) => {
            state.adminReports = payload[0].map((item: any) => {
                return {
                    id: item.id,
                    appId: item.appId,
                    name: item.name || '',
                    fein: item.txtFEIN || '',
                    ssn: item.txtSSN || '',
                    postmarkDate: item.postmarkDate || '',
                    status: convertFormStatus(item.status),
                    determanationDate: item.determanationDate || '',
                    groupType: item.groupType || 0,
                    company: item.company || null,
                };
            });
            state.totalReportsPages = Math.ceil(payload[1] / PER_PAGE);
            state.isReportsLoading = false;
        });
        builder.addCase(getHrForms.rejected, (state, action) => {
            state.isReportsLoading = false;
            state.adminReports = [];
            sendNotification(
                (action.payload as string) || 'Error with reports loading',
                NOTIFICATION_STATUS.ERROR
            );
        });


        builder.addCase(getFormsByCompanyId.pending, (state) => {
            state.isReportsLoading = true;
        });

        builder.addCase(getFormsByCompanyId.fulfilled, (state, { payload }) => {
            state.adminReports = payload[0].map((item: any) => {
                return {
                    id: item.id,
                    appId: item.appId,
                    name: item.name || '',
                    fein: item.txtFEIN || '',
                    ssn: item.txtSSN || '',
                    postmarkDate: item.postmarkDate || '',
                    status: convertFormStatus(item.status),
                    determanationDate: item.determanationDate || '',
                    groupType: item.groupType || 0,
                    company: item.company || null,
                };
            });
            state.totalReportsPages = Math.ceil(payload[1] / PER_PAGE);
            state.isReportsLoading = false;
        });

        builder.addCase(getFormsByCompanyId.rejected, (state, action) => {
            state.isReportsLoading = false;
            state.adminReports = [];
            sendNotification(
                (action.payload as string) || 'Error with reports loading',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(getFormDataById.fulfilled, (state, { payload }) => {
            const { mainData, form } = payload;
            state.selectedReport = BackendToFrontendFormDataConverter(
                form as BackFormData
            );

            state.reportMainData = {
                id: mainData.id,
                appId: form.appId || 'unknown',
                name: mainData.name || '',
                fein: mainData.txtFEIN || '',
                ssn: mainData.txtSSN || '',
                postmarkDate: mainData.postmarkDate || '',
                status: convertFormStatus(mainData.status),
                determinationDate: mainData.determanationDate || '',
                signedBy: mainData.signedBy || ' ',
            };
        });

        builder.addCase(getFormDataById.rejected, (state, action) => {
            sendNotification(
                (action.payload as string) ||
                    'Cannot get report info! Please, refresh page',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(deleteForm.fulfilled, (state) => {
            sendNotification(
                'Form was deleted successfully!',
                NOTIFICATION_STATUS.SUCCESS
            );
        });

        builder.addCase(deleteForm.rejected, (state, action) => {
            sendNotification(
                (action.payload as string) || 'Error in form deletion!',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(updateFormsStatus.pending, (state) => {
            state.isReportsStatusUpdating = true;
        });

        builder.addCase(updateFormsStatus.fulfilled, (state) => {
            sendNotification(
                'Successfully updated reports statuses!',
                NOTIFICATION_STATUS.SUCCESS
            );
            state.isReportsStatusUpdating = false;
        });

        builder.addCase(updateFormsStatus.rejected, (state, action) => {
            (action.payload as string) ||
                sendNotification(
                    'Error in forms status update!',
                    NOTIFICATION_STATUS.ERROR
                );
            state.isReportsStatusUpdating = false;
        });

        builder.addCase(getCSV.fulfilled, () => {
            sendNotification(
                'Successfully generated CSV file!',
                NOTIFICATION_STATUS.SUCCESS
            );
        });

        builder.addCase(getCSV.rejected, (state, action) => {
            (action.payload as string) ||
                sendNotification(
                    'Error is CSV file generation!',
                    NOTIFICATION_STATUS.ERROR
                );
        });
    },
});

export const { changeGroupValue, setReportsPage, setReportForDelete, setLastLocation, setDirection } =
    reportsSlice.actions;

export default reportsSlice.reducer;
