import { Dayjs } from 'dayjs';
import { ChangeEvent } from 'react';
import { CustomRadioGroup, CustomInput, CustomDatePicker } from '../../';
import useResponsive from '../../../Hooks/useResponsive';
import styles from './styles.module.scss';
import {useTranslation} from "react-i18next";

type FormUnemploymentQuestionProps = {
    counter: string;
    values: {
        optLTUrecipient27weeks: string;
        txtLtuBenefitsState: string;
        txtSafStartDate: string;
    };
    errors: {
        optLTUrecipient27weeks?: string;
        txtLtuBenefitsState?: string;
        txtSafStartDate?: string;
    };
    onChange: (e: ChangeEvent<any>) => void;
    onDateChange: (value: Dayjs | string) => void;
    disabled?: boolean;
};

const FormUnemploymentQuestion: React.FC<FormUnemploymentQuestionProps> = ({
    counter,
    values,
    errors,
    onChange,
    onDateChange,
    disabled = false,
}) => {
    const { Mobile, Tablet, Desktop } = useResponsive();

    const showTextAreas = values.optLTUrecipient27weeks === 'yes';
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.line}>
                <div className={styles.infoContainer}>
                    <div className={styles.counter}>{counter}</div>
                    <div>
                        {t('Are you an individual who is or was in a period of unemployment that is at least 27 consecutive weeks and for all or part of that period you received unemployment compensation?')}
                    </div>
                </div>
                <div className={styles.radioGroup}>
                    <CustomRadioGroup
                        groupName={'optLTUrecipient27weeks'}
                        onChange={onChange}
                        value={values.optLTUrecipient27weeks}
                        error={errors.optLTUrecipient27weeks}
                        disabled={disabled}
                    />
                </div>
            </div>
            {showTextAreas && (
                <>
                    <div className={styles.textLine}>
                        <Desktop>
                            <CustomInput
                                id='txtLtuBenefitsState'
                                onChange={onChange}
                                value={values.txtLtuBenefitsState}
                                title={t('Enter state where unemployment compensation was received')}
                                leftLabel
                                error={t(errors.txtLtuBenefitsState as string)}
                                disabled={disabled}
                                maxLength={2}
                                containerClassName={styles.stateContainer}
                            />
                        </Desktop>
                        <Tablet>
                            <CustomInput
                                id='txtLtuBenefitsState'
                                onChange={onChange}
                                value={values.txtLtuBenefitsState}
                                title={t('Enter state where unemployment compensation was received')}
                                error={errors.txtLtuBenefitsState}
                                disabled={disabled}
                                maxLength={2}
                                containerClassName={styles.stateContainer}
                            />
                        </Tablet>
                        <Mobile>
                            <CustomInput
                                id='txtLtuBenefitsState'
                                onChange={onChange}
                                value={values.txtLtuBenefitsState}
                                title={t('Enter state where unemployment compensation was received')}
                                error={errors.txtLtuBenefitsState}
                                disabled={disabled}
                                maxLength={2}
                                inputClassName={styles.stateInput}
                                width='260px'
                            />
                        </Mobile>
                    </div>
                    <div className={styles.datePickerContainer}>
                        <div className={styles.datePicker}>
                            <div className={styles.datePickerLabel}>
                                {t('Unemployment start date')}
                            </div>
                            <CustomDatePicker
                                value={values.txtSafStartDate}
                                id='txtSafStartDate'
                                name='txtSafStartDate'
                                onChange={onDateChange}
                                error={errors.txtSafStartDate}
                                disabled={disabled}
                            />
                        </div>
                        {values.txtSafStartDate.length > 0 && (
                            <div className={styles.disclosureContainer}>
                                <div className={styles.disclosure}>
                                    {t('Disclosure:')}
                                </div>
                                {t('By entering the start date of unemployment, you are agreeing that you have a completed and signed Long-Term Unemployment Recipient Self-Attestation Form, DOL-ETA Form 9175, on file available upon request.')}
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
export default FormUnemploymentQuestion;
