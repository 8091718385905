import React, { ChangeEvent } from 'react';
import useResponsive from '../../../Hooks/useResponsive';
import CustomRadioCheckbox from '../../CustomRadioCheckbox';
import styles from './styles.module.scss';
import {useTranslation} from "react-i18next";

interface ListItem {
    point?: string;
    text: string;
}

interface FormCheckedLineWithListProps {
    id: string;
    counter: string;
    title?: string;
    list: ListItem[];
    isChecked: boolean;
    disabled?: boolean;
    onChange: (e: ChangeEvent<any>) => void;
}

const FormCheckedLineWithList: React.FC<FormCheckedLineWithListProps> = ({
    id,
    counter,
    title = '',
    list,
    isChecked,
    disabled = false,
    onChange,
}) => {
    const { Touch, Desktop } = useResponsive();

    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <div className={styles.infoContainer}>
                <div className={styles.counter}>{counter}</div>
                <Desktop>
                    <div className={styles.list}>
                        {title && <div className={styles.title}>{t(title)}</div>}
                        {list.map((item) => {
                            return (
                                <div
                                    className={styles.listItem}
                                    key={item.text}
                                >
                                    {item.point && <div>{item.point}</div>}
                                    <div>{t(item.text)}</div>
                                </div>
                            );
                        })}
                    </div>
                </Desktop>
            </div>
            <div className={styles.contentContainer}>
                <Touch>
                    {title && <div className={styles.title}>{t(title)}</div>}
                    <div className={styles.list}>
                        {list.map((item) => {
                            return (
                                <div
                                    className={styles.listItem}
                                    key={item.text}
                                >
                                    {item.point && <div>{item.point}</div>}
                                    <div>{t(item.text)}</div>
                                </div>
                            );
                        })}
                    </div>
                </Touch>
                <CustomRadioCheckbox
                    id={id}
                    checked={isChecked}
                    onChange={onChange}
                    label={t('Checked')}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
export default FormCheckedLineWithList;
