import { Dayjs } from 'dayjs';
import React, { ChangeEvent } from 'react';
import { CustomDatePicker, CustomRadioGroup } from '../../';
import styles from './styles.module.scss';

interface FormCheckedWithDatePickerProps {
	id1: string;
	id2: string;
	id3: string;
	counter: string;
	title: string;
	subtitle: string;
	datePickerText: string;
	value: string;
	value2: string;
	question1: string;
	question2: string;
	error?: string;
	datePickerValue: string;
	isAdminEditable?: boolean;
	onChange: (e: ChangeEvent<any>) => void;
	onDateChange: (value: Dayjs | string) => void;
	errors: {
		dateError?: string;
	};
	disabled?: boolean;
}

const FormCheckedWithDatePicker: React.FC<FormCheckedWithDatePickerProps> = ({
	id1,
	id2,
	id3,
	counter,
	title,
	subtitle,
	question1,
	question2,
	value,
	value2,
	onChange,
	datePickerText,
	datePickerValue,
	onDateChange,
	errors,
	disabled = false,
	isAdminEditable,
}) => {
	const disableRadio = (val: string, dis: boolean): boolean => {
		if (val === 'yes') {
			return true;
		} else if (dis) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.line}>
				<div className={styles.counter}>{counter}</div>
				<div className={styles.infoContainer}>
					<div>{title}</div>
					<div>{subtitle}</div>

					<div className={styles.question1}>
						<div>{question1}</div>
						<div className={styles.radioGroup}>
							<CustomRadioGroup
								groupName={id1}
								onChange={onChange}
								value={value}
								disabled={disableRadio(value2, disabled)}
								isAdminEditable={isAdminEditable}
							/>
						</div>
					</div>
					<div className={styles.question1}>
						<div>{question2}</div>
						<div className={styles.radioGroup}>
							<CustomRadioGroup
								groupName={id2}
								onChange={onChange}
								value={value2}
								disabled={disableRadio(value, disabled)}
								isAdminEditable={isAdminEditable}
							/>
						</div>
					</div>

					{value === 'yes' || value2 === 'yes' ? (
						<div className={styles.dateLine}>
							<div className={styles.datePickerText}>{datePickerText}</div>
							<CustomDatePicker
								value={datePickerValue}
								id={id3}
								name={id3}
								onChange={onDateChange}
								error={errors.dateError}
								disabled={disabled}
							/>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};
export default FormCheckedWithDatePicker;
