import { Dialog } from '@mui/material';
import { CustomButton } from '..';
import Icons from '../../Assets';
import styles from './styles.module.scss';

interface DeleteModalProps {
    open: boolean;
    handleClose: () => void;
    title?: string;
    deleteButtonTitle?: string;
    onAction: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
    open,
    handleClose,
    onAction,
    title = '',
    deleteButtonTitle = '',
}) => {
    const onDialogClose = () => {
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            maxWidth='lg'
            classes={{ paper: styles.dialog }}
        >
            <div className={styles.container}>
                <Icons.CloseModalIcon
                    onClick={onDialogClose}
                    className={styles.closeIcon}
                />
                <div className={styles.title}>{title}</div>
                <div className={styles.buttonContainer}>
                    <CustomButton
                        title={deleteButtonTitle}
                        onClickHandler={onAction}
                    />
                    <CustomButton
                        title={'Cancel'}
                        onClickHandler={onDialogClose}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default DeleteModal;
