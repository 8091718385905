import React from 'react';
import Icons from '../../Assets';
import styles from './styles.module.css';

type CopyrightFooterProps = {
    text: string;
};

const CopyrightFooter: React.FC<CopyrightFooterProps> = ({ text }) => {
    return (
        <div className={styles.container}>
            <Icons.CopyrightIcon />
            <div className={styles.text}>{text}</div>
        </div>
    );
};

export default React.memo(CopyrightFooter);
