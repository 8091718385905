import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { signIn } from '../../Api/Auth';
import validationSchema from './validation';
import {
    CustomButton,
    CustomInput,
    UnauthorizedHeader,
} from '../../Components';
import { AppDispatch, StoreRootState } from '../../Store';
import styles from './styles.module.scss';

const LoginPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const { role } = useSelector((state: StoreRootState) => state.auth);

    const onMainPageClick = () => {
        navigate('/');
    };

    const onForgotPasswordClick = () => {
        navigate('/forgot-password');
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            dispatch(
                signIn({
                    ...values,
                })
            );
        },
    });

    return (
        <>
            {role !== null && <Navigate to='/reports' />}
            <div className={styles.container}>
                <UnauthorizedHeader
                    buttonAction={onMainPageClick}
                    buttonTitle='Main Screen'
                />
                <div className={styles.contentContainer}>
                    <div className={styles.mainImage} />
                    <div className={styles.loginFormContainer}>
                        <div className={styles.loginFormTitle}>Log in</div>
                        <div className={styles.loginFormSubtitle}>
                            Please enter your details below!
                        </div>
                        <div className={styles.inputsContainer}>
                            <CustomInput
                                id='email'
                                title='Email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.errors.email}
                                inputClassName={styles.loginInput}
                            />
                            <CustomInput
                                id='password'
                                title='Password'
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                type='password'
                                error={formik.errors.password}
                                inputClassName={styles.passwordInput}
                            />
                        </div>
                        <div
                            className={styles.forgotPasswordLink}
                            onClick={onForgotPasswordClick}
                        >
                            Forgot your password?
                        </div>
                        <CustomButton
                            title='Continue'
                            onClickHandler={formik.submitForm}
                            disabled={
                                !formik.isValid || formik.values.email === ''
                            }
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginPage;
