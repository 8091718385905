import React from 'react';
import Icons from '../../Assets';
import styles from './styles.module.scss';

type ConsItemProps = {
    title: string;
    hasBorder?: boolean;
};

const ConsItem: React.FC<ConsItemProps> = ({ title, hasBorder = false }) => {
    return (
        <div className={hasBorder ? styles.borderContainer : styles.container}>
            <Icons.CheckIcon className={styles.checkIcon} />
            <div className={styles.consText}>{title}</div>
        </div>
    );
};

export default React.memo(ConsItem);
