import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PER_PAGE } from '../../Api/constants';
import {
	deleteForm,
	DeleteFormData,
	getFormsByCompanyId,
	getFormsWithSearch,
	submitExistingForm,
	UpdateFormData,
	updateFormsStatus,
} from '../../Api/Forms';
import {
	CustomSearchInput,
	AdminEmployeesTable,
	CustomTabs,
	LoadingIndicator,
	DeleteModal,
	AdminHeader,
	CustomIconButton,
	UpdateStatusButton,
} from '../../Components';
import { LOADER_VERSION } from '../../Components/LoadingIndicator';
import useDebounce from '../../Hooks/useDebounce';
import {
	setReportForDelete,
	setReportsPage,
	setLastLocation,
	setDirection,
} from '../../Slices/ReportsSlice';
import { AppDispatch, StoreRootState } from '../../Store';
import { TabData } from '../../types';
import styles from './styles.module.scss';
import AdminUsersTable from '../../Components/AdminUsersTable';
import UserInfoModal, { UserMode } from '../../Components/UserInfoModal';
import Icons from '../../Assets';
import { HrUser, selectUser, setUserPage } from '../../Slices/UserSlice';
import { createHr, deleteUser, getUsersWithSearch, updateUserInfo } from '../../Api/User';

const AdminCompanyPage: React.FC = () => {
	const { companyId } = useParams();

	const [employeesSearch, setEmployeesSearch] = useState('');
	const onEmployeesSearchChange = (e: React.ChangeEvent<any>) => {
		setEmployeesSearch(e.target.value);
	};

	const {
		sortDirectionAsc,
		adminReports,
		reportsPage,
		totalReportsPages,
		isReportsLoading,
		selectedReportForDelete,
		isReportsStatusUpdating,
	} = useSelector((state: StoreRootState) => state.reports);

	const [deleteFormModalOpen, setDeleteFormModalOpen] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const [selectedTab, setSelectedTab] = useState('employees');
	const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);

	const sort = sortDirectionAsc ? 'asc' : 'desc';

	const changeSortDirection = () => {
		dispatch(setDirection(!sortDirectionAsc));
	};

	const onTabChange = (id: string) => {
		if (id === 'companies') {
			navigate('/reports');
		}
		setSelectedTab(id);
	};

	useEffect(() => {
		let location = window.location.href;
		dispatch(setLastLocation(location));
	}, [dispatch]);

	const showForm = (id: string) => {
		navigate(`/review/admin/${id}`);
	};

	const onEmployeePageChange = (page: number) => {
		dispatch(setReportsPage(page + 1));
	};

	const onFormSend = (formId: number) => {
		dispatch(submitExistingForm(formId));
	};

	const onDeleteFormModalOpen = (formId: number) => {
		setDeleteFormModalOpen(true);
		dispatch(setReportForDelete(formId));
	};

	const onDeleteFormModalClose = () => {
		setDeleteFormModalOpen(false);
	};

	const onFormDelete = () => {
		dispatch(setReportsPage(1));
		setEmployeesSearch('');

		let currentCompany = -1;
		if (companyId !== undefined) {
			currentCompany = +companyId;
		}

		const deleteData: DeleteFormData = {
			formId: selectedReportForDelete?.id || -1,
			refreshFunction: () =>
				getFormsByCompanyId({
					direction: sort,
					page: reportsPage,
					perPage: PER_PAGE,
					search: '',
					companyId: currentCompany,
				}),
		};

		dispatch(deleteForm(deleteData));
		setDeleteFormModalOpen(false);
	};

	const { allCompanies } = useSelector((state: StoreRootState) => state.company);

	const debouncedEmployeesSearch = useDebounce(employeesSearch, 500);

	useEffect(() => {
		if (companyId) {
			dispatch(
				getFormsByCompanyId({
					direction: sort,
					page: reportsPage,
					perPage: PER_PAGE,
					search: debouncedEmployeesSearch,
					companyId: +companyId,
				}),
			);
		}
	}, [dispatch, companyId, reportsPage, debouncedEmployeesSearch, sort]);

	// useEffect(() => {
	//     dispatch(setReportsPage(1));
	// }, [dispatch, debouncedEmployeesSearch]);

	const [userModalOpen, setUserModalOpen] = useState(false);
	const [userModalMode, setUserModalMode] = useState(UserMode.CREATE);

	const onUserCreateModalOpen = () => {
		setUserModalOpen(true);
		setUserModalMode(UserMode.CREATE);
	};

	const { selectedUserId, currentUser, totalUsersPages, users, usersPage } = useSelector(
		(state: StoreRootState) => state.user,
	);

	const onUsersPageChange = (page: number) => {
		dispatch(setUserPage(page + 1));
	};

	const onDeleteUserModalOpen = (id: number) => {
		setDeleteUserModalOpen(true);
		dispatch(selectUser(id));
	};

	const onUserEditModalOpen = (id: number) => {
		setUserModalOpen(true);
		setUserModalMode(UserMode.EDIT);
		dispatch(selectUser(id));
	};

	const onUserDelete = () => {
		dispatch(deleteUser(selectedUserId));
		setDeleteUserModalOpen(false);
	};

	const onDeleteUserModalClose = () => {
		setDeleteUserModalOpen(false);
	};

	const onUserCreate = (values: HrUser) => {
		dispatch(createHr(values));
		setUserModalOpen(false);
	};
	const onUserEdit = (values: HrUser, id?: number) => {
		dispatch(
			updateUserInfo({
				...values,
				id: id || -1,
			}),
		);
		setUserModalOpen(false);
	};

	const userModalAction = userModalMode === UserMode.CREATE ? onUserCreate : onUserEdit;

	const onUserCreateModalClose = () => {
		setUserModalOpen(false);
	};

	const [usersSearch, setUsersSearch] = useState('');
	const onUsersSearchChange = (e: React.ChangeEvent<any>) => {
		setUsersSearch(e.target.value);
	};

	const onFormsStatusUpdate = () => {
		const updateData: UpdateFormData = {
			refreshFunction: () =>
				getFormsWithSearch({
					direction: sort,
					page: 1,
					perPage: PER_PAGE,
					search: '',
				}),
		};

		dispatch(updateFormsStatus(updateData));
		dispatch(setReportsPage(1));
		setEmployeesSearch('');
	};

	const usersTabComponent = (
		<>
			<div className={styles.actionComponents}>
				<div className={styles.searchContainer}>
					<CustomSearchInput
						id='usersSearch'
						placeholder='Search by name'
						value={usersSearch}
						onChange={onUsersSearchChange}
						className={styles.searchInput}
					/>
					{isReportsLoading && <LoadingIndicator size={40} version={LOADER_VERSION.CLIP} />}
				</div>
				<div className={styles.buttonsContainer}>
					<CustomIconButton
						title='Create user'
						onClick={onUserCreateModalOpen}
						icon={<Icons.CreateHrIcon />}
					/>
				</div>
			</div>
			<div className={styles.adminTableContainer}>
				<AdminUsersTable
					noResultsPlaceholder='No users found'
					currentPage={usersPage}
					values={users}
					onRowClick={() => {}}
					pageCount={totalUsersPages}
					onPageChange={onUsersPageChange}
					onDeleteClick={onDeleteUserModalOpen}
					onEditClick={onUserEditModalOpen}
				/>
			</div>

			<DeleteModal
				open={deleteUserModalOpen}
				onAction={onUserDelete}
				title={`Are you sure you want to delete user '${currentUser?.name}' ?`}
				deleteButtonTitle='Delete user'
				handleClose={onDeleteUserModalClose}
			/>

			<UserInfoModal
				open={userModalOpen}
				mode={userModalMode}
				onAction={userModalAction}
				handleClose={onUserCreateModalClose}
				user={currentUser}
				companies={allCompanies}
			/>
		</>
	);

	const employeesTabComponent = (
		<>
			<div className={styles.actionComponents}>
				<div className={styles.searchContainer}>
					<CustomSearchInput
						placeholder='Search by name'
						value={employeesSearch}
						onChange={onEmployeesSearchChange}
						className={styles.searchInput}
					/>
					{isReportsLoading && <LoadingIndicator size={40} version={LOADER_VERSION.CLIP} />}
				</div>
				<div className={styles.buttonsContainer}>
					<UpdateStatusButton
						title='Update status'
						onClick={onFormsStatusUpdate}
						disabled={isReportsStatusUpdating}
					/>
				</div>
			</div>
			<div className={styles.tableContainer}>
				<AdminEmployeesTable
					noResultsPlaceholder='No forms found'
					currentPage={reportsPage}
					values={adminReports}
					onRowClick={showForm}
					pageCount={totalReportsPages}
					onPageChange={onEmployeePageChange}
					onDownloadClick={() => ''}
					onSendClick={onFormSend}
					onDeleteClick={onDeleteFormModalOpen}
					isAsc={sortDirectionAsc}
					onSortClick={changeSortDirection}
				/>
			</div>
			<DeleteModal
				open={deleteFormModalOpen}
				onAction={onFormDelete}
				title={`Are you sure you want to delete form '${selectedReportForDelete?.name}'?`}
				deleteButtonTitle='Delete form'
				handleClose={onDeleteFormModalClose}
			/>
		</>
	);

	const tabs: Array<TabData> = [
		{
			name: 'Companies',
			id: 'companies',
			component: <div></div>,
		},
		{
			name: 'Users',
			id: 'users',
			component: usersTabComponent,
		},
		{
			name: 'Employees',
			id: 'employees',
			component: employeesTabComponent,
		},
	];
	const debouncedUsersSearch = useDebounce(usersSearch, 500);

	useEffect(() => {
		if (selectedTab === 'users')
			dispatch(
				getUsersWithSearch({
					page: usersPage,
					perPage: PER_PAGE,
					search: debouncedUsersSearch,
				}),
			);
	}, [dispatch, selectedTab, usersPage, debouncedUsersSearch]);

	return (
		<>
			<div className={styles.container}>
				<AdminHeader />
				<div className={styles.content}>
					<CustomTabs tabs={tabs} selectedId={selectedTab} onTabChange={onTabChange} />
				</div>
			</div>
		</>
	);
};

export default AdminCompanyPage;
