import {Box, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import Chip from '@mui/material/Chip';
import cc from 'classcat';
import Icons from '../../Assets';
import styles from './styles.module.scss';
import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";

type CustomSelectProps = {
    type?: 'select'
    id: string;
    selectedValue: string;
    values: Array<{
        text: string;
        value: string;
    }>;
    disabled?: boolean;
    error?: boolean;
    title?: string;
    width?: string;
    fullContainer?: boolean;
    onChange: (e: SelectChangeEvent) => void;
    MenuProps?: object;
    className?: string;
};

type CustomMultiSelectProps = {
    type?: 'multiselect'
    id: string;
    selectedValue: Array<string>;
    values: Array<{
        text: string;
        value: string;
    }>;
    disabled?: boolean;
    error?: boolean;
    title?: string;
    width?: string;
    fullContainer?: boolean;
    onChange: (e: SelectChangeEvent<Array<string>>) => void;
    MenuProps?: object;
    className?: string;
};

const CustomSelect: React.FC<CustomSelectProps | CustomMultiSelectProps> = (
    {
        id,
        disabled = false,
        error = false,
        selectedValue,
        values,
        title = '',
        width = '400px',
        onChange,
        MenuProps,
        fullContainer = true,
        className = '',
        type = 'select'
    }) => {
    const selectStyle = error ? styles.selectWithError : styles.select;
    const {t} = useTranslation();

    return (
        <div className={fullContainer ? styles.container : ''}>
            {title && <div className={styles.title}>{title}</div>}
            <Select
                id={id}
                className={cc([selectStyle, className])}
                IconComponent={Icons.SelectIcon}
                value={selectedValue}
                sx={{
                    '& .MuiSelect-icon': {
                        marginRight: '10px',
                        marginTop: '2px',
                    },
                    '& .MuiOutlinedInput-input': {
                        paddingRight: '45px !important',
                    },
                }}
                style={{width: width}}
                onChange={onChange as ((event: SelectChangeEvent<string | string[]>, child: ReactNode) => void) | undefined}
                disabled={disabled}
                MenuProps={MenuProps}
                multiple={type === 'multiselect'}

                renderValue={(selected: string | Array<string>) => {
                    if (!Array.isArray(selected)) {
                        const selectedText = values.find(el => el.value === selected)
                        return <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            <Chip key={selected} label={selectedText?.text || ''}/>
                        </Box>
                    }
                    return <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {selected.length ? selected.map((value) => (
                            <Chip key={value} label={value}/>
                        )) : ''}
                    </Box>
                }}
            >
                {values.map((item) => {
                    return (
                        <MenuItem
                            key={item.value}
                            value={item.value}
                            className={styles.menuItem}
                        >
                            {t(item.text)}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    );
};

export default CustomSelect;
