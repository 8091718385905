import { ChangeEvent } from 'react';
import { CustomRadioGroup, CustomInput } from '../../';
import useResponsive from '../../../Hooks/useResponsive';
import styles from './styles.module.scss';
import {useTranslation} from "react-i18next";

type FormSnapQuestionProps = {
    counter: string;
    values: {
        optSnap6Months: string;
        optSnap3Months: string;
        txtSnapPrimaryRecipient: string;
        txtSnapRecipientLocation: string;
    };
    errors: {
        optSnap6Months?: string;
        optSnap3Months?: string;
        txtSnapPrimaryRecipient?: string;
        txtSnapRecipientLocation?: string;
    };
    onChange: (e: ChangeEvent<any>) => void;
    disabled?: boolean;
};

const FormSnapQuestion: React.FC<FormSnapQuestionProps> = ({
    counter,
    values,
    errors,
    onChange,
    disabled = false,
}) => {
    const { Touch, Desktop } = useResponsive();
    const { t } = useTranslation();
    const showTextAreas =
        values.optSnap3Months === 'yes' || values.optSnap6Months === 'yes';

    return (
        <div className={styles.container}>
            <div className={styles.line}>
                <div className={styles.infoContainer}>
                    <div className={styles.counter}>{counter}</div>
                    <div>
                        {t('Are you a member of a family that received Supplemental Nutrition Assistance Program (SNAP) (formerly Food Stamps) benefits for the 6 months before you were hired?')}
                    </div>
                </div>
                <div className={styles.radioGroup}>
                    <CustomRadioGroup
                        groupName={'optSnap6Months'}
                        onChange={onChange}
                        value={values.optSnap6Months}
                        error={errors.optSnap6Months}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className={styles.line}>
                <div className={styles.infoContainer}>
                    <div className={styles.counter}>{counter}.1</div>
                    <div>
                        {t('Did you receive SNAP benefits for at least a 3-month period within the last 5 months but you are no longer receiving them?')}
                    </div>
                </div>
                <div className={styles.radioGroup}>
                    <CustomRadioGroup
                        groupName={'optSnap3Months'}
                        onChange={onChange}
                        value={values.optSnap3Months}
                        error={errors.optSnap3Months}
                        disabled={disabled}
                    />
                </div>
            </div>
            {showTextAreas && (
                <div className={styles.textLine}>
                    <Desktop>
                        <CustomInput
                            id='txtSnapPrimaryRecipient'
                            onChange={onChange}
                            title={t('Primary recipient name')}
                            value={values.txtSnapPrimaryRecipient}
                            leftLabel
                            error={errors.txtSnapPrimaryRecipient}
                            disabled={disabled}
                            maxLength={50}
                            labelClassName={styles.label}
                        />
                        <CustomInput
                            id='txtSnapRecipientLocation'
                            onChange={onChange}
                            title={t('City and state')}
                            value={values.txtSnapRecipientLocation}
                            leftLabel
                            error={errors.txtSnapRecipientLocation}
                            disabled={disabled}
                            maxLength={50}
                            labelClassName={styles.label}
                        />
                    </Desktop>
                    <Touch>
                        <CustomInput
                            id='txtSnapPrimaryRecipient'
                            onChange={onChange}
                            title={t('Primary recipient name')}
                            value={values.txtSnapPrimaryRecipient}
                            error={errors.txtSnapPrimaryRecipient}
                            disabled={disabled}
                            maxLength={50}
                        />
                        <CustomInput
                            id='txtSnapRecipientLocation'
                            onChange={onChange}
                            title={t('City and state')}
                            value={values.txtSnapRecipientLocation}
                            error={errors.txtSnapRecipientLocation}
                            disabled={disabled}
                            maxLength={50}
                        />
                    </Touch>
                </div>
            )}
        </div>
    );
};
export default FormSnapQuestion;
