import * as React from 'react';
import Icons from '../../Assets';
import LoadingIndicator, { LOADER_VERSION } from '../LoadingIndicator';

import styles from './styles.module.scss';

interface IUpdateStatusButtonProps {
    title: string;
    disabled?: boolean;
    onClick: () => void;
}

const UpdateStatusButton: React.FC<IUpdateStatusButtonProps> = ({
    title,
    disabled = false,
    onClick,
}) => {
    const onButtonClick = () => {
        if (disabled) return;
        else onClick();
    };

    return (
        <div
            className={disabled ? styles.disabledContainer : styles.container}
            onClick={onButtonClick}
        >
            {disabled ? (
                <LoadingIndicator
                    size={20}
                    color='white'
                    version={LOADER_VERSION.CLIP}
                />
            ) : (
                <Icons.RefreshStatusIcon />
            )}
            <div className={styles.title}>{title}</div>
        </div>
    );
};

export default UpdateStatusButton;
