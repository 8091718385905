import { createSlice } from '@reduxjs/toolkit';
import {
    signIn,
    signOut,
    restorePassword,
    setNewPassword,
    verifyCode,
} from '../../Api/Auth';
import { updatePasswordOnBackend } from '../../Api/User';
import { sendNotification } from '../../Common/common';
import { NOTIFICATION_STATUS, USER_TYPES } from '../../types';

export interface AuthState {
    token: string | null;
    refreshToken: string | null;
    id: number;
    role: 'manager' | 'admin' | null;
    isEmailFound: boolean;
    isVerificationCodeFound: boolean;
    isPasswordChanged: boolean;
    isWaitingForResponse: boolean;
}

const initialState: AuthState = {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    refreshToken: localStorage.getItem('refreshToken')
        ? localStorage.getItem('refreshToken')
        : null,
    id: -1,
    role: localStorage.getItem('role')
        ? (localStorage.getItem('role') as USER_TYPES)
        : null,
    isEmailFound: false,
    isVerificationCodeFound: false,
    isPasswordChanged: false,
    isWaitingForResponse: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateTokens(state, action) {
            const { refreshToken, token, role } = action.payload;
            state.refreshToken = refreshToken;
            state.role = role;
            state.token = token;
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('token', token);
            localStorage.setItem('role', role);
        },
        clearState(state) {
            state.isEmailFound = false;
            state.isPasswordChanged = false;
            state.isVerificationCodeFound = false;
            state.isWaitingForResponse = false;
        },
        clearToken(state) {
            state.token = null;
            state.refreshToken = null;
            state.role = null;
            state.id = -1;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(signIn.fulfilled, (state, { payload }) => {
            state.token = payload.token;
            localStorage.setItem('token', payload.token);
            state.refreshToken = payload.refreshToken;
            localStorage.setItem('refreshToken', payload.refreshToken);
            state.id = payload.userId;
            localStorage.setItem('role', payload.role);
            state.role = payload.role;
        });
        builder.addCase(signOut.fulfilled, (state) => {
            state.id = -1;
            state.refreshToken = null;
            localStorage.removeItem('refreshToken');
            state.token = null;
            localStorage.removeItem('token');
            state.role = null;
            localStorage.removeItem('role');
            sendNotification(
                'Logged out successfully!',
                NOTIFICATION_STATUS.SUCCESS
            );
        });
        builder.addCase(signIn.rejected, (state, action) => {
            sendNotification(
                (action.payload as string) ||
                    'Cannot sign in! Please, check your email and password!',
                NOTIFICATION_STATUS.ERROR
            );
        });
        builder.addCase(signOut.rejected, (state) => {
            state.id = -1;
            state.refreshToken = null;
            state.token = null;
            state.role = null;
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('token');
            localStorage.removeItem('role');
        });

        builder.addCase(verifyCode.pending, (state) => {
            state.isWaitingForResponse = true;
        });
        builder.addCase(verifyCode.fulfilled, (state, { payload }) => {
            state.isWaitingForResponse = false;
            state.isVerificationCodeFound = true;
            state.id = payload;
        });
        builder.addCase(verifyCode.rejected, (state, action) => {
            state.isWaitingForResponse = false;
            sendNotification(
                (action.payload as string) ||
                    'Verification code is not correct! Please, check verification code on your e-mail',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(restorePassword.pending, (state) => {
            state.isWaitingForResponse = false;
        });
        builder.addCase(restorePassword.fulfilled, (state) => {
            state.isWaitingForResponse = false;
            state.isEmailFound = true;
            sendNotification(
                'Verification code was sent to your e-mail address!',
                NOTIFICATION_STATUS.SUCCESS
            );
        });
        builder.addCase(restorePassword.rejected, (state, action) => {
            state.isWaitingForResponse = false;
            sendNotification(
                (action.payload as string) ||
                    'Email is not found! Please, check spelling of e-mail address',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(setNewPassword.pending, (state) => {
            state.isWaitingForResponse = false;
        });
        builder.addCase(setNewPassword.fulfilled, (state) => {
            state.isWaitingForResponse = false;
            state.isPasswordChanged = true;
            sendNotification(
                'Password changed successfully!',
                NOTIFICATION_STATUS.SUCCESS
            );
        });
        builder.addCase(setNewPassword.rejected, (state, action) => {
            state.isWaitingForResponse = false;
            sendNotification(
                (action.payload as string) ||
                    'Error with password update! Please, try again later',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(updatePasswordOnBackend.rejected, (state, action) => {
            sendNotification(
                (action.payload as string) ||
                    'Error with password update! Please, try again later',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(updatePasswordOnBackend.fulfilled, (state) => {
            sendNotification(
                `Password updated successfully! Please, don't forget to change your password on eddservices.edd.ca.gov!`,
                NOTIFICATION_STATUS.SUCCESS
            );
        });
    },
});

export const { clearState, clearToken, updateTokens } = authSlice.actions;

export default authSlice.reducer;
