import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import {
	HrMainPage,
	EmployeeFormPage,
	ForgotPasswordPage,
	ReviewPage,
	LoginPage,
	MainPage,
	AdminMainPage,
	FormSubmittedPage,
} from './Pages';
import { PrivateRoute } from './Components/PrivateRoute';
import { StoreRootState } from './Store';
import { useSelector } from 'react-redux';
import { USER_TYPES } from './types';
import AdminCompanyPage from './Pages/AdminCompanyPage';
import HrCompanyPage from './Pages/HrCompanyPage';

const App: React.FC = () => {
	const { role } = useSelector((state: StoreRootState) => state.auth);
	return (
		<div className='App'>
			<Routes>
				<Route path='/' element={<MainPage />} />
				<Route path='/login' element={<LoginPage />} />
				<Route path='/forgot-password' element={<ForgotPasswordPage />} />
				<Route
					path='/review/:id'
					element={
						<PrivateRoute>
							<ReviewPage isAdminEditable={false} />
						</PrivateRoute>
					}
				/>
				<Route
					path='/review/admin/:id'
					element={
						<PrivateRoute>
							<ReviewPage isAdminEditable={true} />
						</PrivateRoute>
					}
				/>
				<Route path='/employee-form/:companyId' element={<EmployeeFormPage />} />
				<Route
					path='/reports'
					element={
						<PrivateRoute>
							{role === USER_TYPES.MANAGER && <HrMainPage />}
							{role === USER_TYPES.ADMIN && <AdminMainPage />}
						</PrivateRoute>
					}
				/>
				<Route
					path='/reports/company/:companyId'
					element={
						<PrivateRoute>
							{role === USER_TYPES.MANAGER && <HrCompanyPage />}
							{role === USER_TYPES.ADMIN && <AdminCompanyPage />}
							{/*<AdminCompanyPage />*/}
						</PrivateRoute>
					}
				/>

				<Route path='/submit-success' element={<FormSubmittedPage />} />
			</Routes>
			<ToastContainer position='top-center' />
		</div>
	);
};

export default App;
