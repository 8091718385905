import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {getFormDataById} from '../../Api/Forms';
import Icons from '../../Assets';
import {
    CustomForm,
    FormStatus,
    CopyrightFooter,
    AuthorizedHeader,
    CustomIconButton,
    LoadingIndicator,
    ScrollButton,
} from '../../Components';
import useResponsive from '../../Hooks/useResponsive';
import {AppDispatch, StoreRootState} from '../../Store';
import {FORM_STATUS} from '../../types';
import styles from './styles.module.scss';
import CustomErrorTooltip from "../../Components/CustomErrorTooltip";
import {t} from "i18next";

interface ReviewPageProps {
    isAdminEditable: boolean;
}

const ReviewPage: React.FC<ReviewPageProps> = ({isAdminEditable}) => {
    const [formValid, setFormValid] = useState(true);
    const navigate = useNavigate();
    const formRef = useRef<any>(null);
    const {Touch} = useResponsive();
    const dispatch = useDispatch<AppDispatch>();
    const {id} = useParams();

    useEffect(() => {
        if (id) {
            dispatch(getFormDataById(+id));
        }
    }, [id, dispatch]);

    const {selectedReport, reportMainData, lastLocation} = useSelector(
        (state: StoreRootState) => state.reports
    );

    const isFromCompanyPage = lastLocation.split('/')[5] === 'company';

    const onBackClick = () => {
        if (selectedReport && isFromCompanyPage) {
            if(selectedReport.company) {
                let companyId = selectedReport.company.id
                navigate(`/reports/company/${companyId}`);
            } else {
                navigate('/reports')}
        } else {
            // @ts-ignore
            navigate('/reports', {state: {isFromCompanyPage: true}});
        }
    };

    const {isLoading} = useSelector(
        (state: StoreRootState) => state.formSubmit
    );

    const onSaveClick = () => {
        formRef.current.submitForm();
        formRef.current.saveValues();
    };

    return (
        <>
            <div className={styles.pageContainer}>
                <AuthorizedHeader/>
                <div className={styles.formHeader}>
                    <div className={styles.formTitleContainer}>
                        <Icons.BackIcon
                            className={styles.backIcon}
                            onClick={onBackClick}
                        />
                        <div className={styles.formTitle}>
                            {reportMainData.name}
                        </div>
                    </div>

                    <div className={styles.formHeaderActions}>
                        {isAdminEditable &&
                            reportMainData.status ===
                            FORM_STATUS.APPROVE_WAITING && (
                                <div className={styles.saveContainer}>
                                    {!formValid && <CustomErrorTooltip
                                        text={
                                            t('Form has errors, please fix them before submit')
                                        }
                                    />}

                                    <CustomIconButton
                                        title='Save'
                                        icon={<Icons.SaveIcon/>}
                                        onClick={onSaveClick}
                                    />

                                    {isLoading && <LoadingIndicator/>}
                                </div>
                            )}
                    </div>
                </div>
                <div className={styles.formStatusContainer}>
                    <FormStatus
                        appId={reportMainData.appId}
                        determinationDate={reportMainData.determinationDate}
                        fein={reportMainData.fein}
                        name={reportMainData.name}
                        postmarkDate={reportMainData.postmarkDate}
                        ssn={reportMainData.ssn}
                        status={reportMainData.status}
                        signedBy={reportMainData.signedBy}
                    />
                </div>
                <CustomForm
                    disabled={reportMainData.appId === 'unknown'}
                    id={id ? +id : -1}
                    isAdminEditable={
                        isAdminEditable && reportMainData.status === FORM_STATUS.APPROVE_WAITING
                    }
                    initialData={selectedReport}
                    ref={formRef}
                    // enableValidation={enableValidation}
                    onFormSubmit={() => {
                    }}
                    setValid={setFormValid}
                />
            </div>
            <CopyrightFooter text='2023 WOTC Wiz LLC. All rights reserved'/>

            <Touch>
                <ScrollButton/>
            </Touch>
        </>
    );
};

export default ReviewPage;
