import styles from './styles.module.scss';

type CustomButtonProps = {
    disabled?: boolean;
    title: string;
    onClickHandler: () => void;
};

const CustomButton: React.FC<CustomButtonProps> = ({
    title,
    onClickHandler,
    disabled,
}) => {
    const onButtonClick = () => {
        if (disabled) return;
        else onClickHandler();
    };

    return (
        <div
            className={disabled ? styles.disabledContainer : styles.container}
            onClick={onButtonClick}
        >
            {title}
        </div>
    );
};

export default CustomButton;
