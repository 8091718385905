import { Dialog } from '@mui/material';
import { CustomButton } from '..';
import Icons from '../../Assets';
import styles from './styles.module.scss';

interface LinkGeneratedModalProps {
    open: boolean;
    handleClose: () => void;
}

const LinkGeneratedModal: React.FC<LinkGeneratedModalProps> = ({
    open,
    handleClose,
}) => {
    const onDialogClose = () => {
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            maxWidth='lg'
            classes={{ paper: styles.dialog }}
        >
            <div className={styles.container}>
                <Icons.CloseModalIcon
                    onClick={onDialogClose}
                    className={styles.closeIcon}
                />
                <div className={styles.title}>Link generated</div>
                <div className={styles.text}>
                    The link has been generated successfully and is on the
                    clipboard!
                </div>
                <div className={styles.buttonContainer}>
                    <CustomButton
                        title='Well noted'
                        onClickHandler={onDialogClose}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export default LinkGeneratedModal;
