import { Dialog, SelectChangeEvent } from '@mui/material';
import { useFormik } from 'formik';
import validationSchema from './validation';
import { Company } from '../../Slices/CompanySlice';
import Icons from '../../Assets';
import { CustomButton, CustomSelect, CustomDatePicker } from '..';
import styles from './styles.module.scss';
import {useSelector} from "react-redux";
import {StoreRootState} from "../../Store";

interface CsvDownloadModalProps {
    open: boolean;
    handleClose: () => void;
    companies: Array<Company>;
    onAction: (values: {
        companyName: string;
        fromDate: string;
        thruDate: string;
    }) => void;
}

const CsvDownloadModal: React.FC<CsvDownloadModalProps> = ({
    open,
    companies,
    handleClose,
    onAction,
}) => {
    const today = new Date().toDateString();

    const formik = useFormik({
        initialValues: {
            companyName: 'all',
            fromDate: today,
            thruDate: today,
        },
        validationSchema: validationSchema,
        validateOnMount: true,
        onSubmit: (values: any) => {
            onAction(values);
        },
    });

    const {
        errors,
        values,
        setFieldValue,
        setValues,
        setFieldTouched,
        submitForm,
        isValid,
    } = formik;

    const onValueChange = (e: SelectChangeEvent<string>) => {
        setFieldValue('companyName', e.target.value);
    };

    const onDialogClose = () => {
        handleClose();
        setValues({
            companyName: 'all',
            fromDate: today,
            thruDate: today,
        });
    };

    const {
        allCompanies,
    } = useSelector((state: StoreRootState) => state.company);

    const companiesArray = [
        {
            text: 'all',
            value: 'all',
        },
        ...allCompanies.map((item) => {
            return {
                text: item.companyName,
                value: item.companyName,
            };
        }),
    ];

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            maxWidth='lg'
            classes={{ paper: styles.dialog }}
        >
            <div className={styles.container}>
                <Icons.CloseModalIcon
                    onClick={onDialogClose}
                    className={styles.closeIcon}
                />
                <div className={styles.title}>CSV Download</div>
                <div className={styles.subtitle}>
                    Download employees' statuses as a CSV report
                </div>
                <div className={styles.fieldsContainer}>
                    <CustomSelect
                        id='companyName'
                        title='Company'
                        selectedValue={values.companyName}
                        values={companiesArray}
                        onChange={onValueChange}
                        className={styles.select}
                    />
                    <div className={styles.fieldLine}>
                        <CustomDatePicker
                            title='From'
                            value={values.fromDate}
                            id='fromDate'
                            name='from'
                            error={errors.fromDate}
                            onChange={(value) => {
                                setFieldValue('fromDate', value);
                                setTimeout(() =>
                                    setFieldTouched('fromDate', true)
                                );
                            }}
                        />
                        <CustomDatePicker
                            title='To'
                            value={values.thruDate}
                            id='thruDate'
                            name='to'
                            error={errors.thruDate}
                            onChange={(value) => {
                                setFieldValue('thruDate', value);
                                setTimeout(() =>
                                    setFieldTouched('thruDate', true)
                                );
                            }}
                        />
                    </div>

                    <div className={styles.buttonContainer}>
                        <CustomButton
                            title='Download'
                            onClickHandler={submitForm}
                            disabled={!isValid}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default CsvDownloadModal;
