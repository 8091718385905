import { configureStore } from '@reduxjs/toolkit';

import {
    AuthReducer,
    CompanyReducer,
    FormSubmitReducer,
    ReportsReducer,
    UserReducer,
} from '../Slices';

export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        company: CompanyReducer,
        reports: ReportsReducer,
        formSubmit: FormSubmitReducer,
        user: UserReducer,
    },
});

export type StoreType = typeof store;
export type StoreRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
