import { ChangeEvent } from 'react';

import { CustomButton, CustomInput } from '../../../../Components';
import styles from './styles.module.scss';

interface ChangePasswordScreenProps {
    password: string;
    passwordError?: string;
    repeatedPassword: string;
    repeatedPasswordError?: string;
    setPassword: (e: ChangeEvent<HTMLInputElement>) => void;
    setRepeatedPassword: (e: ChangeEvent<HTMLInputElement>) => void;
    onChangeClick: () => void;
}

const ChangePasswordScreen: React.FC<ChangePasswordScreenProps> = ({
    password,
    passwordError,
    repeatedPassword,
    repeatedPasswordError,
    setPassword,
    setRepeatedPassword,
    onChangeClick,
}) => {
    return (
        <div className={styles.formContainer}>
            <div className={styles.formTitle}>New Password</div>
            <div className={styles.formSubtitle}>
                To change the current password, enter the new password in both
                fields
            </div>
            <div className={styles.inputsContainer}>
                <CustomInput
                    title='New Password'
                    value={password}
                    id='password'
                    type='password'
                    onChange={setPassword}
                    error={passwordError}
                    inputClassName={styles.passwordInput}
                />
                <CustomInput
                    title='Confirm New Password'
                    value={repeatedPassword}
                    id='repeatedPassword'
                    type='password'
                    onChange={setRepeatedPassword}
                    error={repeatedPasswordError}
                    inputClassName={styles.passwordInput}
                />
            </div>
            <CustomButton
                title='Change Password'
                onClickHandler={onChangeClick}
                disabled={
                    (repeatedPasswordError?.length ||
                        passwordError?.length ||
                        0) > 0
                }
            />
        </div>
    );
};

export default ChangePasswordScreen;
