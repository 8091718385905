import {createAsyncThunk} from '@reduxjs/toolkit';
import {axios, axiosErrorHandler} from './setupAxios';
import {HrUser, setUserPage} from '../Slices/UserSlice';
import {API_PATH, PER_PAGE} from './constants';
// import {Company, setCompanyPage} from "../Slices/CompanySlice";
// import {getCompaniesWithSearch} from "./Company";

type FullUserData = HrUser & {
    id: number;
};

type PaginationData = {
    page: number;
    perPage: number;
};

type SearchData = PaginationData & {
    search: string;
};

const createHr = createAsyncThunk(
    'user/createHr',
    async (data: HrUser, {dispatch, rejectWithValue}) => {
        try {
            const result = await axios.post(`${API_PATH}/auth/create_manager`, {
                ...data,
            });
            dispatch(
                getUsersWithSearch({
                    page: 1,
                    perPage: PER_PAGE,
                    search: '',
                })
            );
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

const getAllHr = createAsyncThunk(
    'user/getAllHr',
    async () => {
        const result = await axios.get(`${API_PATH}/users/get_all_managers`);
        return result.data;
    }
)

const getUsersWithSearch = createAsyncThunk(
    'users/getUsersWithSearch',
    async (data: SearchData) => {
        const take = data.perPage;
        const skip = (data.page - 1) * data.perPage;
        const result = await axios.post(
            `${API_PATH}/users/get_all_managers?take=${take}&skip=${skip}`,
            {
                name: data.search,
            }
        );
        return result.data;
    }
);

const updateUserInfo = createAsyncThunk(
    'users/updateUserInfo',
    async (data: FullUserData, { dispatch, rejectWithValue }) => {
        try {
            const result = await axios.post(
                `${API_PATH}/users/update?id=${data.id}`,
                {
                    ...data,
                }
            );
            dispatch(setUserPage(1));
            dispatch(
                getUsersWithSearch({
                    page: 1,
                    perPage: PER_PAGE,
                    search: '',
                })
            );
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

const deleteUser = createAsyncThunk(
    'users/delete',
    async (id: number, { dispatch, rejectWithValue }) => {
        try {
            const result = await axios.delete(
                `${API_PATH}/users/delete_by_id/${id}`
            );
            dispatch(setUserPage(1));
            dispatch(
                getUsersWithSearch({
                    page: 1,
                    perPage: PER_PAGE,
                    search: '',
                })
            );
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

const getCompanyIdByToken = createAsyncThunk(
    'user/getCompanyId',
    async (token: string, {rejectWithValue}) => {
        try {
            const result = await axios.post(`${API_PATH}/token/get_company`, {
                token,
            });
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

//для госсайта
const updatePasswordOnBackend = createAsyncThunk(
    'user/updatePasswordOnBackend',
    async (newPassword: string, {rejectWithValue}) => {
        try {
            const result = await axios.post(
                `${API_PATH}/users/update_wotc_creds`,
                {
                    newPassword,
                }
            );
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

export {
    updateUserInfo,
    deleteUser,
    getAllHr,
    createHr,
    getCompanyIdByToken,
    updatePasswordOnBackend,
    getUsersWithSearch
};
