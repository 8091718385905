import Icons from '../../Assets';
import cc from 'classcat';
import styles from './styles.module.scss';

type CustomSearchInputProps = {
    id?: string;
    value?: string;
    width?: string;
    height?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    onChange: (e: React.ChangeEvent<any>) => void;
};

const CustomSearchInput: React.FC<CustomSearchInputProps> = ({
    id = '',
    value = '',
    placeholder = '',
    className = '',
    disabled = false,
    onChange,
}) => {
    return (
        <div className={styles.inputContainer}>
            <div className={styles.inputBorder}>
                <Icons.SearchIcon className={styles.icon} />
                <input
                    id={id}
                    className={cc([styles.input, className])}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
};

export default CustomSearchInput;
