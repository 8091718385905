import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {signOut} from '../../Api/Auth';
import {getHrCompaniesWithSearch, updateCompanyInfo} from '../../Api/Company';
import {FORM_URL, PER_PAGE} from '../../Api/constants';
import {
    getHrForms,
    UpdateFormData,
    updateFormsStatus
} from '../../Api/Forms';
import {getCompanyIdByToken} from '../../Api/User';
import Icons from '../../Assets';
import {copyToClipboard} from '../../Common/common';
import {
    AuthorizedHeader,
    CustomSearchInput,
    CustomTabs,
    HrFormTable,
    HrCompaniesTable,
    LinkGeneratedModal,
    LoadingIndicator,
    UpdateStatusButton,
} from '../../Components';
import CompanyInfoModal, {CompanyMode} from '../../Components/CompanyInfoModal';
import {LOADER_VERSION} from '../../Components/LoadingIndicator';
import useDebounce from '../../Hooks/useDebounce';
import {Company, selectCompany, setCompanyPage} from '../../Slices/CompanySlice';
import {resetLink} from '../../Slices/FormSubmitSlice';
import {setDirection, setReportsPage} from '../../Slices/ReportsSlice';
import {AppDispatch, StoreRootState} from '../../Store';
import styles from './styles.module.scss';
import {TabData} from "../../types";
import LanguageModal from "../../Components/LanguageModal";

const HrMainPage: React.FC = () => {
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const [isLinkOpen, setIsLinkOpen] = useState(false);
    const [employeesSearch, setEmployeesSearch] = useState('');
    const [companiesSearch, setCompaniesSearch] = useState('');
    const [tabsId, setTabsId] = useState('companies');

    const onCompaniesSearchChange = (e: React.ChangeEvent<any>) => {
        setCompaniesSearch(e.target.value);
    };

    const [companyModalOpen, setCompanyModalOpen] = useState(false);
    const onCompanyModalClose = () => {
        setCompanyModalOpen(false);
    };

    const navigate = useNavigate();

    const closeCompanyModal = () => {
        setIsInfoOpen(false);
    };

    const onInfoUpdate = (data: Company) => {
        dispatch(
            updateCompanyInfo({
                ...data,
                id: selectedCompanyId,
            })
        );
        closeCompanyModal();
    };

    const closeLinkModal = () => {
        setIsLinkOpen(false);
        dispatch(resetLink());
    };

    const onEmployeesSearchChange = (e: React.ChangeEvent<any>) => {
        setEmployeesSearch(e.target.value);
    };

    const showForm = (id: string) => {
        navigate(`/review/${id}`);
    };

    const onEmployeePageChange = (page: number) => {
        dispatch(setReportsPage(page + 1));
    };

    const dispatch = useDispatch<AppDispatch>();
    const {refreshToken, token} = useSelector(
        (state: StoreRootState) => state.auth
    );

    const {
        sortDirectionAsc,
        adminReports,
        totalReportsPages,
        reportsPage,
        isReportsStatusUpdating,
        isReportsLoading
    } =
        useSelector((state: StoreRootState) => state.reports);

    const [asc, setAsc] = useState<boolean>(sortDirectionAsc);

    const changeSortDirection = () => {
        setAsc(!asc)
        dispatch(setDirection(!asc))
    }

    const {
        companies,
        selectedCompanyId,
        currentCompany,
        companyPage,
        totalCompanyPages,
        isCompaniesLoading,
    } = useSelector(
        (state: StoreRootState) => state.company
    );

    const {company} = useSelector((state: StoreRootState) => state);

    const {isLinkGenerated} = useSelector(
        (state: StoreRootState) => state.formSubmit
    );
    const menuActions = [
        {
            text: 'Log out',
            icon: <Icons.MenuLogoutIcon/>,
            onClick: () => {
                dispatch(signOut(refreshToken || ''));
            },
        },
    ];

    useEffect(() => {
        dispatch(getCompanyIdByToken(token || ''));
    }, [token, dispatch]);

    useEffect(() => {
        if (isLinkGenerated) {
            setIsLinkOpen(true);
        }
    }, [isLinkGenerated]);

    const onTabsChange = (id: string) => {
        setTabsId(id);
    };

    const debouncedEmployeesSearch = useDebounce(employeesSearch, 500);
    const debouncedCompaniesSearch = useDebounce(companiesSearch, 500);
    const debouncedEmployeeSearch = useDebounce(employeesSearch, 500);

    useEffect(() => {
        if (tabsId === 'employees')
            dispatch(
                getHrForms({
                    direction: asc ? "asc" : "desc",
                    page: reportsPage,
                    perPage: PER_PAGE,
                    search: debouncedEmployeeSearch
                })
            );
    }, [dispatch, tabsId, reportsPage, debouncedEmployeeSearch, asc]);

    useEffect(() => {
        if (tabsId === 'companies')
            dispatch(
                getHrCompaniesWithSearch({
                    page: companyPage,
                    perPage: PER_PAGE,
                    search: debouncedCompaniesSearch,
                })
            );
    }, [dispatch, tabsId, companyPage, debouncedCompaniesSearch]);

    useEffect(() => {
        dispatch(setReportsPage(1));
    }, [dispatch, debouncedEmployeesSearch]);

    const onFormDownload = (formId: string) => {
        console.log(`form ${formId} download clicked`);
    };

    const onCompanyLinkGenerate = (url: string) => {
        copyToClipboard(`${FORM_URL}/${url}`);
    };

    const onCompanyRowClick = (id: number) => {
        navigate(`/reports/company/${id}`);
    };

    const onFormsStatusUpdate = () => {
        const updateData: UpdateFormData = {
            refreshFunction: () =>
                getHrForms({
                    direction: asc ? "asc" : "desc",
                    page: 1,
                    perPage: PER_PAGE,
                    search: '',
                }),
        };

        dispatch(updateFormsStatus(updateData));
        dispatch(setReportsPage(1));
        setEmployeesSearch('');
    };

    const employeesTabComponentHr = (
        <>
            <div className={styles.actionComponents}>
                <div className={styles.searchContainer}>
                    <CustomSearchInput
                        placeholder='Search by name'
                        value={employeesSearch}
                        onChange={onEmployeesSearchChange}
                        className={styles.searchInput}
                    />
                    {isReportsLoading && (
                        <LoadingIndicator
                            size={40}
                            version={LOADER_VERSION.CLIP}
                        />
                    )}
                </div>
                <div className={styles.buttonsContainer}>
                    <UpdateStatusButton
                        title='Update status'
                        onClick={onFormsStatusUpdate}
                        disabled={isReportsStatusUpdating}
                    />
                </div>
            </div>
            <div className={styles.tableContainer}>
                <HrFormTable
                    noResultsPlaceholder='No forms found'
                    currentPage={reportsPage}
                    values={adminReports}
                    pageCount={totalReportsPages}
                    onRowClick={showForm}
                    onPageChange={onEmployeePageChange}
                    onDownloadClick={onFormDownload}
                    onSortClick={changeSortDirection}
                    isAsc={asc}
                />
            </div>
            <CompanyInfoModal
                company={company.currentCompany}
                handleClose={closeCompanyModal}
                open={isInfoOpen}
                onAction={onInfoUpdate}
            />
            <LinkGeneratedModal
                open={isLinkOpen}
                handleClose={closeLinkModal}
            />

        </>
    )

    const [companyModalMode, setCompanyModalMode] = useState(
        CompanyMode.VIEW
    );
    const onCompanyEditModalOpen = (id: number) => {
        setCompanyModalOpen(true);
        setCompanyModalMode(CompanyMode.VIEW);
        dispatch(selectCompany(id));
    };

    const onCompanyPageChange = (page: number) => {
        dispatch(setCompanyPage(page + 1));
    };

    const [languageModalOpen, setLanguageModalOpen] = useState(false);
    const [modalData, setModalData] = useState('');

    const companiesTabComponentHr = (
        <>
            <div className={styles.actionComponents}>
                <div className={styles.searchContainer}>
                    <CustomSearchInput
                        id='companiesSearch'
                        placeholder='Search by company name'
                        value={companiesSearch}
                        onChange={onCompaniesSearchChange}
                        className={styles.searchInput}
                    />
                    {isCompaniesLoading && (
                        <LoadingIndicator
                            size={40}
                            version={LOADER_VERSION.CLIP}
                        />
                    )}
                </div>
            </div>
            <div className={styles.tableContainer}>
                <HrCompaniesTable
                    noResultsPlaceholder='No companies found'
                    currentPage={companyPage}
                    values={companies}
                    pageCount={totalCompanyPages}
                    onRowClick={onCompanyRowClick}
                    onInfoClick={onCompanyEditModalOpen}
                    onPageChange={onCompanyPageChange}
                    onGenerateLinkClick={(url: string) => {
                        setModalData(url)
                        setLanguageModalOpen(true)
                    }}
                />
            </div>

            <LanguageModal open={languageModalOpen}
                           onSubmit={() => {
                               setLanguageModalOpen(false)
                               onCompanyLinkGenerate(modalData)
                           }}
                           close={() => setLanguageModalOpen(false)}
            />

            <CompanyInfoModal
                mode={companyModalMode}
                open={companyModalOpen}
                onAction={() => {
                }}
                handleClose={onCompanyModalClose}
                company={currentCompany}
            />
        </>
    )

    const tabs: Array<TabData> = [
        {
            name: 'Companies',
            id: 'companies',
            component: companiesTabComponentHr,
        },
        {
            name: 'Employees',
            id: 'employees',
            component: employeesTabComponentHr,
        },
    ];

    return (
        <>
            <div className={styles.container}>
                <AuthorizedHeader actions={menuActions}/>
                <div className={styles.contentContainer}>
                    <CustomTabs
                        tabs={tabs}
                        selectedId={tabsId}
                        onTabChange={onTabsChange}
                    />
                </div>
            </div>
        </>
    );
};

export default HrMainPage;
