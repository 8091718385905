import { createSlice } from '@reduxjs/toolkit';
import {
    createCompany,
    deleteCompany,
    getAllCompanies,
    getCompaniesWithSearch,
    getCompanyInfo, getHrCompaniesWithSearch,
    updateCompanyInfo,
} from '../../Api/Company';
import { PER_PAGE } from '../../Api/constants';
import { getCompanyIdByToken } from '../../Api/User';
import { sendNotification } from '../../Common/common';
import { NOTIFICATION_STATUS } from '../../types';

export interface Company {
    id: number;
    companyName: string;
    fein: string;
    state: string;
    city: string;
    zip: string;
    street: string;
    contactName: string;
    phone: string;
    url: string;
}

export interface CompanyState {
    companyPage: number;
    totalCompanyPages: number;
    currentCompany: Company;
    companies: Array<Company>;
    allCompanies: Array<Company>;
    selectedCompanyId: number;
    isCompaniesLoading: boolean;
    clearSearch: boolean;
}

const initialState: CompanyState = {
    currentCompany: {
        id: 1,
        companyName: '',
        fein: '',
        state: '',
        city: '',
        zip: '',
        street: '',
        contactName: '',
        phone: '',
        url: '',
    },
    companies: [],
    allCompanies: [],
    selectedCompanyId: -1,
    companyPage: 1,
    totalCompanyPages: 1,
    isCompaniesLoading: false,
    clearSearch: false,
};

export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        selectCompany: (state, action) => {
            const id = action.payload;
            state.selectedCompanyId = id;

            const company = state.companies.find((item) => item.id === id);

            state.currentCompany.city = company?.city || '';
            state.currentCompany.companyName = company?.companyName || '';
            state.currentCompany.contactName = company?.contactName || '';
            state.currentCompany.fein = company?.fein || '';
            state.currentCompany.id = company?.id || state.currentCompany.id;
            state.currentCompany.phone = company?.phone || '';
            state.currentCompany.state = company?.state || '';
            state.currentCompany.street = company?.street || '';
            state.currentCompany.zip = company?.zip || '';
            state.currentCompany.url = company?.url || '';
        },

        restoreClearSearch: (state) => {
            state.clearSearch = false;
        },

        clearCompany: (state) => {
            state.currentCompany.city = '';
            state.currentCompany.companyName = '';
            state.currentCompany.contactName = '';
            state.currentCompany.fein = '';
            state.currentCompany.id = -1;
            state.currentCompany.phone = '';
            state.currentCompany.state = '';
            state.currentCompany.street = '';
            state.currentCompany.zip = '';
        },

        setCompanyPage: (state, action) => {
            state.companyPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllCompanies.fulfilled, (state, { payload }) => {
            state.allCompanies = payload;
        });

        builder.addCase(getCompanyIdByToken.fulfilled, (state, { payload }) => {
            state.selectedCompanyId = payload;
        });

        builder.addCase(getCompaniesWithSearch.pending, (state) => {
            state.isCompaniesLoading = true;
        });

        builder.addCase(
            getCompaniesWithSearch.fulfilled,
            (state, { payload }) => {
                state.companies = payload[0];
                state.totalCompanyPages = Math.ceil(payload[1] / PER_PAGE);
                state.isCompaniesLoading = false;
            }
        );
        builder.addCase(getCompaniesWithSearch.rejected, (state) => {
            state.isCompaniesLoading = false;
            sendNotification(
                'Error with companies loading',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(getHrCompaniesWithSearch.pending, (state) => {
            state.isCompaniesLoading = true;
        });

        builder.addCase(
            getHrCompaniesWithSearch.fulfilled,
            (state, { payload }) => {
                state.companies = payload[0];
                state.totalCompanyPages = Math.ceil(payload[1] / PER_PAGE);
                state.isCompaniesLoading = false;
            }
        );
        builder.addCase(getHrCompaniesWithSearch.rejected, (state) => {
            state.isCompaniesLoading = false;
            sendNotification(
                'Error with companies loading',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(getCompanyInfo.fulfilled, (state, { payload }) => {
            state.currentCompany.city = payload.city;
            state.currentCompany.companyName = payload.companyName;
            state.currentCompany.contactName = payload.contactName;
            state.currentCompany.fein = payload.fein;
            state.currentCompany.id = payload.id;
            state.currentCompany.phone = payload.phone;
            state.currentCompany.state = payload.state;
            state.currentCompany.street = payload.street;
            state.currentCompany.zip = payload.zip;
            state.currentCompany.url = payload.url || '';
        });

        builder.addCase(createCompany.fulfilled, (state) => {
            sendNotification('Added new company', NOTIFICATION_STATUS.SUCCESS);
            state.clearSearch = true;
        });

        builder.addCase(createCompany.rejected, (state, action) => {
            sendNotification(
                (action.payload as string) || 'Error with company creation',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(updateCompanyInfo.fulfilled, (state) => {
            sendNotification(
                'Updated company info',
                NOTIFICATION_STATUS.SUCCESS
            );
            state.clearSearch = true;
        });

        builder.addCase(updateCompanyInfo.rejected, (state, action) => {
            sendNotification(
                (action.payload as string) || 'Error with company info update',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(deleteCompany.fulfilled, (state) => {
            sendNotification(
                'Company deleted successfully',
                NOTIFICATION_STATUS.SUCCESS
            );
            state.clearSearch = true;
        });

        builder.addCase(deleteCompany.rejected, (state, action) => {
            sendNotification(
                (action.payload as string) || 'Error with company deletion',
                NOTIFICATION_STATUS.ERROR
            );
        });
    },
});

export const {
    selectCompany,
    clearCompany,
    setCompanyPage,
    restoreClearSearch,
} = companySlice.actions;

export default companySlice.reducer;
