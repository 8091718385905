const questions = {
	chkItem1: `Check here if you received a conditional certification from the state workforce agency (SWA) or a participating local agency for the work opportunity credit.`,
	chkItem2: {
		title: 'Check here if any of the following statements apply to you.',
		list: [
			{
				point: '•',
				text: 'I am a member of a family that has received assistance from Temporary Assistance for Needy Families (TANF) for any 9 months during the past 18 months.',
			},
			{
				point: '•',
				text: 'I am a veteran and a member of a family that received Supplemental Nutrition Assistance Program (SNAP) benefits (food stamps) for at least a 3-month period during the past 15 months.',
			},
			{
				point: '•',
				text: 'I was referred here by a rehabilitation agency approved by the state, an employment network under the Ticket to Work program, or the Department of Veterans Affairs.',
			},
			{
				point: '•',
				text: 'I am at least age 18 but not age 40 or older and I am a member of a family that:',
			},
			{
				point: 'a.',
				text: 'Received SNAP benefits (food stamps) for the past 6 months; or',
			},
			{
				point: 'b.',
				text: 'Received SNAP benefits (food stamps) for at least 3 of the past 5 months, but is no longer eligible to receive them.',
			},
			{
				point: '•',
				text: 'During the past year, I was convicted of a felony or released from prison for a felony.',
			},
			{
				point: '•',
				text: 'I received supplemental security income (SSI) benefits for any month ending during the past 60 days.',
			},
			{
				point: '•',
				text: 'I am a veteran and I was unemployed for a period or periods totaling at least 4 weeks but less than 6 months during the past year',
			},
		],
	},
	chkItem3:
		'Check here if you are a veteran and you were unemployed for a period or periods totaling at least 6 months during the past year.',
	chkItem4:
		'Check here if you are a veteran entitled to compensation for a service-connected disability and you were discharged or released from active duty in the U.S. Armed Forces during the past year.',
	chkItem5:
		'Check here if you are a veteran entitled to compensation for a service-connected disability and you were unemployed for a period or periods totaling at least 6 months during the past year.',
	chkItem6: {
		list: [
			{
				point: '',
				text: 'Check here if you are a member of a family that:',
			},
			{
				point: '•',
				text: 'Received TANF payments for at least the past 18 months; or',
			},
			{
				point: '•',
				text: 'Received TANF payments for any 18 months beginning after August 5, 1997, and the earliest 18-month period beginning after August 5, 1997, ended during the past 2 years; or',
			},
			{
				point: '•',
				text: 'Stopped being eligible for TANF payments during the past 2 years because federal or state law limited the maximum time those payments could be made.',
			},
		],
	},
	ChkItem7:
		'Check here if you are in a period of unemployment that is at least 27 consecutive weeks and for all or part of that period you received unemployment compensation.',
	optPreviousEmployer: 'Have you worked for this employer before?', //8 question
	txtLastEmploymentDate: 'Enter last date of employment', //8
	txtStartWage: 'Starting Wage/Hour (USD)', // 10
	ddlOccupation: {
		// 11
		title: 'What is your occupation?',
		values: [
			{
				text: '',
				value: '',
			},
			{
				text: 'Architecture & Engineering',
				value: '17',
			},
			{
				text: 'Arts, Entertainment, Sports & Media',
				value: '27',
			},
			{
				text: 'Building & Grounds Cleaning & Maintenance',
				value: '37',
			},
			{
				text: 'Business & Financial Operations',
				value: '13',
			},
			{
				text: 'Community & Social Services',
				value: '21',
			},
			{
				text: 'Computer & Mathematics',
				value: '15',
			},
			{
				text: 'Construction & Extraction',
				value: '47',
			},
			{
				text: 'Education ,Training & Library',
				value: '25',
			},
			{
				text: 'Farming, Fishing & Forestry',
				value: '45',
			},
			{
				text: 'Food Prep & Serving',
				value: '35',
			},
			{
				text: 'Healthcare Practitioner & Technical',
				value: '29',
			},
			{
				text: 'Healthcare Support',
				value: '31',
			},
			{
				text: 'Installation, Maintenance & Repair',
				value: '49',
			},
			{
				text: 'Legal',
				value: '23',
			},
			{
				text: 'Life, Physical & Social Science',
				value: '19',
			},
			{
				text: 'Management',
				value: '11',
			},
			{
				text: 'Office & Administrative Support',
				value: '43',
			},
			{
				text: 'Personal Care & Service',
				value: '39',
			},
			{
				text: 'Production',
				value: '51',
			},
			{
				text: 'Protective Service',
				value: '33',
			},
			{
				text: 'Sales & Related Occupations',
				value: '41',
			},
			{
				text: 'Transportation & Material Moving',
				value: '53',
			},
		],
	},

	optAgeUnder40: 'Date of birth', //12
	txtBirthDate: 'Enter your date of birth', //12

	title: 'Designated Community Resident (DCR)', //18
	subtitle:
		'Check if the job applicant is at list 18 but not age 40 on the hiring date, and resides in a',
	isRuralRenewalCounty: ' Rural Renewal Country', //18
	isEmprowermentZone: 'Empowerment Zone (EZ).', //18
	jobAppBirthdayRRC: "Enter the job applicant's birthday", //18

	title19: 'Qualified Summer Youth Employee',
	isQualifSummerYouthEmpl: 'Check here if the job applicant is a Qualified Summer Youth Employee',
	jobAppBirthdayQSYE: "Enter the job applicant's birthday",

	optReceivedSSI:
		'Did you receive Supplemental Security Income (SSI) benefits for any month ending within 60 days before you were hired?', //20
};

export default questions;
