import {Tooltip} from '@mui/material';
import React from 'react';
import {CustomTablePagination} from '..';
import Icons from '../../Assets';
import styles from './styles.module.scss';
import {HrUser} from "../../Slices/UserSlice";

type AdminUsersTableProps = {
    values: Array<HrUser>
    pageCount?: number;
    currentPage: number;
    noResultsPlaceholder: string;
    onRowClick: (id: string) => void;
    onPageChange: (page: number) => void;
    onDeleteClick: (id: number) => void;
    onEditClick: (id: number) => void;
};

const AdminUsersTable: React.FC<AdminUsersTableProps> = (
    {
        values = [],
        currentPage,
        noResultsPlaceholder,
        pageCount = 10,
        onRowClick,
        onPageChange,
        onDeleteClick,
        onEditClick
    }) => {
    const pageChangeHandler = (selectedItem: { selected: number }) => {
        onPageChange(selectedItem.selected);
    };

    const isEmptyContainer = values.length === 0;

    return (
        <div className={styles.container}>
            <div className={styles.tableContainer}>
                {!isEmptyContainer && (
                    <table className={styles.table}>
                        <thead>
                        <tr className={styles.headerRow}>
                            <th className={styles.headerCell}>User name</th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Email
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Company name
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {values.map((item) => {

                            // @ts-ignore
                            // @ts-ignore
                            return (
                                <tr
                                    key={item.id + item.name}
                                    className={styles.tableRow}
                                    onClick={() =>
                                        onRowClick(item.id.toString())
                                    }
                                >
                                    <td className={styles.cell}>
                                        {item.name}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item.email}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {
                                            item?.companies?.map((company) => {
                                                return company.companyName
                                            }).join(', ')
                                        }
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        <div
                                            className={
                                                styles.actionsContainer
                                            }
                                        >
                                            <Tooltip title='Edit user'>
                                                <Icons.CompanyTableEditIcon
                                                    className={
                                                        styles.actionIcon
                                                    }
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onEditClick(
                                                            item.id
                                                        );
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title='Delete user'>
                                                <Icons.DeleteFormIcon
                                                    className={
                                                        styles.actionIcon
                                                    }
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onDeleteClick(
                                                            item.id
                                                        );
                                                    }}
                                                />
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                )}
                {isEmptyContainer && (
                    <div className={styles.noResultsFound}>
                        {noResultsPlaceholder}
                    </div>
                )}
            </div>
            <CustomTablePagination
                onPageChange={pageChangeHandler}
                pageCount={pageCount}
                currentPage={currentPage}
            />
        </div>
    );
};

export default AdminUsersTable;
