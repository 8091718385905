import {Tooltip} from '@mui/material';
import {CustomTablePagination} from '..';
import Icons from '../../Assets';
import styles from './styles.module.scss';
import React from "react";
import {setReportsPage} from "../../Slices/ReportsSlice";
import {useDispatch} from "react-redux";

type AdminCompaniesTableProps = {
    values: Array<{
        id: number;
        companyName: string;
        fein: string;
        contactName: string;
        phone: string;
        url: string;
    }>;
    pageCount: number;
    currentPage: number;
    noResultsPlaceholder: string;
    onRowClick: (id: number) => void;
    onGenerateLinkClick: (url: string) => void;
    onEditClick: (id: number) => void;
    onDeleteClick: (id: number) => void;
    onPageChange: (page: number) => void;
};

const AdminCompaniesTable: React.FC<AdminCompaniesTableProps> = ({
                                                                     values,
                                                                     pageCount,
                                                                     currentPage,
                                                                     noResultsPlaceholder,
                                                                     onRowClick,
                                                                     onDeleteClick,
                                                                     onEditClick,
                                                                     onGenerateLinkClick,
                                                                     onPageChange
                                                                 }) => {
    const pageChangeHandler = (selectedItem: { selected: number }) => {
        onPageChange(selectedItem.selected);
    };

    const dispatch = useDispatch();
    const isEmptyContainer = values.length === 0;

    return (
        <div className={styles.container}>
            <div className={styles.tableContainer}>
                {!isEmptyContainer && (
                    <table className={styles.table}>
                        <thead className={styles.tableHead}>
                        <tr className={styles.headerRow}>
                            <th className={styles.headerCell} align='left'>
                                Company name
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                FEIN
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Contact name
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Phone
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody className={styles.tableBody}>
                        {values.map((item) => {

                            const editable = item.contactName !== 'WOTC Wiz - Casey Fournier';

                            return (
                                <tr
                                    key={item.fein + item.companyName}
                                    className={styles.tableRow}
                                    onClick={() => {
                                        onRowClick(item.id)
                                        dispatch(setReportsPage(1))
                                    }}
                                >
                                    <td
                                        className={styles.cell}
                                        align='left'
                                    >
                                        {item.companyName}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item.fein}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item.contactName}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item.phone}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        <div
                                            className={
                                                styles.actionsContainer
                                            }
                                        >
                                            <Tooltip title='Generate Link'>
                                                <Icons.CompanyTableLinkIcon
                                                    className={
                                                        styles.actionIcon
                                                    }
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onGenerateLinkClick(
                                                            item.url
                                                        );
                                                    }}
                                                />
                                            </Tooltip>

                                            <Tooltip title='Edit'>
                                                <Icons.CompanyTableEditIcon
                                                    className={styles.actionIcon}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        onEditClick(item.id)
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title='Delete'>
                                                <Icons.CompanyTableDeleteIcon
                                                    className={editable ?
                                                        styles.actionIcon : `${styles.actionIcon} ${styles.disabledIcon}`
                                                    }
                                                    onClick={editable
                                                        ? (e) => {
                                                            e.stopPropagation()
                                                            onDeleteClick(
                                                                item.id
                                                            )
                                                        } : (e) => {
                                                            e.stopPropagation()
                                                        }}
                                                />
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                )}
                {isEmptyContainer && (
                    <div className={styles.noResultsFound}>
                        {noResultsPlaceholder}
                    </div>
                )}
            </div>
            <CustomTablePagination
                onPageChange={pageChangeHandler}
                pageCount={pageCount}
                currentPage={currentPage}
            />
        </div>
    );
};

export default AdminCompaniesTable;
