import * as yup from 'yup';

const validationSchema = yup.object().shape({
    email: yup.string().email().required('Required'),
    verificationCode: yup
        .string()
        .required('Required')
        .min(6, 'Must have exactly 6 digits')
        .max(6, 'Must have exactly 6 digits')
        .matches(/^[0-9]+$/, 'Must have only digits'),
    password: yup
        .string()
        .min(4, 'Must be more than 3 symbols')
        .max(16, 'Must be less than 17 symbols')
        .required('Required'),
    repeatedPassword: yup
        .string()
        .min(4, 'Must be more than 3 symbols')
        .max(16, 'Must be less than 17 symbols')
        .required('Required')
        .test(
            'must be the same as new password',
            'Must be the same as new password',
            (value, context) => {
                return value === context.parent.password;
            }
        ),
});

export default validationSchema;
