import { useFormik } from 'formik';
import React, { Ref, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import dayjs from 'dayjs';
import EmployeersHeader from './EmployeersHeader';
import FormCheckedLine from './FormCheckedLine';
import FormCheckedLineWithList from './FormCheckedLineWithList';
import FormFelonyQuestion from './FormFelonyQuestion';
import FormInputLine from './FormInputLine';
import FormOrganizationAssignment from './FormOrganizationAssignment';
import FormSelectLine from './FormSelectLine';
import FormSnapQuestion from './FormSnapQuestion';
import FormTanfQuestion from './FormTanfQuestion';
import FormUnemploymentQuestion from './FormUnemploymentQuestion';
import FormVeteranQuestion from './FormVeteranQuestion';
import FormYesNoLine from './FormYesNoLine';
import JobApplicantHeader from './JobApplicantHeader';
import questions from './questions';
import validationSchema from './validationSchema';
import { FrontFormData } from '../../types';
import CustomIconButton from '../CustomIconButton';
import SignFormModal from '../SignFormModal';
import Icons from '../../Assets';
import { AppDispatch, StoreRootState } from '../../Store';
import CustomErrorTooltip from '../CustomErrorTooltip';
import { Company } from '../../Slices/CompanySlice';
import LoadingIndicator from '../LoadingIndicator';
import { updateFormValues } from '../../Api/Forms';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import ConfirmLineWithAddQwestion from './ConfirmLineWithAddQwestion';
import FormWithDatePicker from './FormWithDatePicker';
import FormCheckedWithDatePicker from './FormCheckedWithDatePicker';
import FormOneCheckedWithDatePicker from './FormOneCheckedWithDatePicker';

type CustomFormProps = {
	id?: number;
	disabled?: boolean;
	isAdminEditable?: boolean;
	initialData?: FrontFormData | null;
	companyData?: Company;
	onFormSubmit?: (data: FrontFormData) => void;
	ref?: Ref<any>;
	enableValidation?: boolean;
	setEnableValidation?: (arg: any) => void;
	setValid?: (arg: boolean) => void;
};

const CustomForm: React.FC<CustomFormProps> = React.forwardRef(
	(
		{
			id = -1,
			disabled = false,
			onFormSubmit,
			companyData = null,
			initialData = null,
			isAdminEditable = false,
			// enableValidation = false,
			// setEnableValidation,
			setValid,
		},
		ref: Ref<any>,
	) => {
		const [isDialogOpen, setIsDialogOpen] = useState(false);
		const [enableValidation, setEnableValidation] = useState(false);

		const { role } = useSelector((state: StoreRootState) => state.auth);
		// eslint-disable-next-line
		const hideEmployeersHeader = role != 'admin' && role != 'manager';

		const yesterday = dayjs().add(-1, 'day').format('MM/DD/YYYY');

		const formik = useFormik({
			initialValues: {
				//applicant
				txtFirstName: initialData?.txtFirstName || '',
				txtLastName: initialData?.txtLastName || '',
				txtSSN: initialData?.txtSSN || '',
				txtStreet: initialData?.txtStreet || '',
				txtCity: initialData?.txtCity || '',
				txtState: initialData?.txtState || '',
				txtZip: initialData?.txtZip || '',

				//employeer
				txtFEIN: initialData?.txtFEIN || companyData?.fein || '',
				txtErName: initialData?.txtErName || companyData?.companyName || '',
				txtErPhone: initialData?.txtErPhone || companyData?.phone || '',
				txtErStreet: initialData?.txtErStreet || companyData?.street || '',
				txtErCity: initialData?.txtErCity || companyData?.city || '',
				txtErState: initialData?.txtErState || companyData?.state || '',
				txtErZip: initialData?.txtErZip || companyData?.zip || '',

				txtContactName: initialData?.txtContactName || companyData?.contactName || '',

				txtContactPhone: '(916) 221-1341',
				txtContactCity: 'Folsom',
				txtContactState: 'CA',
				txtContactStreet: '704 Natoma St',
				txtContactZip: '95630',

				// txtContactPhone:
				//     initialData?.txtContactPhone || companyData?.phone || '',
				// txtContactStreet:
				//     initialData?.txtContactStreet || companyData?.street || '',
				// txtContactCity:
				//     initialData?.txtContactCity || companyData?.city || '',
				// txtContactState:
				//     initialData?.txtContactState || companyData?.state || '',
				// txtContactZip:
				//     initialData?.txtContactZip || companyData?.zip || '',

				ddlGroupNumber: initialData?.ddlGroupNumber || '0',
				txtInfoDate: initialData?.txtInfoDate || yesterday,
				txtOfferDate: initialData?.txtOfferDate || yesterday,
				txtHireDate: initialData?.txtHireDate || yesterday,
				txtStartDate: initialData?.txtStartDate || yesterday,

				chkItem1: initialData?.chkItem1 || false, //1
				chkItem2: initialData?.chkItem2 || false,
				chkItem3: initialData?.chkItem3 || false,
				chkItem4: initialData?.chkItem4 || false,
				chkItem5: initialData?.chkItem5 || false,
				chkItem6: initialData?.chkItem6 || false,
				ChkItem7: initialData?.ChkItem7 || false,

				optPreviousEmployer: initialData?.optPreviousEmployer || '', //8
				txtLastEmploymentDate: initialData?.txtLastEmploymentDate || '', //8
				optReceivingWages: initialData?.optReceivingWages || '', //8 88 88 88 88 88 8

				txtStartWage: initialData?.txtStartWage || '15', //9
				ddlOccupation: initialData?.ddlOccupation || '', //10

				txtBirthDate: initialData?.txtBirthDate || '',

				optVeteran: initialData?.optVeteran || '', //11
				txtDischargeDate: initialData?.txtDischargeDate || '', //12
				optVeteranReceivedSnap: initialData?.optVeteranReceivedSnap || '', //13
				txtVeteranSnapPrimaryRecipient: initialData?.txtVeteranSnapPrimaryRecipient || '', // 14
				txtVeteranSnapRecipientLocation: initialData?.txtVeteranSnapRecipientLocation || '', // 14
				optVeteranDisability: initialData?.optVeteranDisability || '', //14
				optVeteranDischarged: initialData?.optVeteranDischarged || '', //14
				optVeteranUnemployed: initialData?.optVeteranUnemployed || '', //14

				optSnap6Months: initialData?.optSnap6Months || '', //15
				optSnap3Months: initialData?.optSnap3Months || '', //15
				txtSnapPrimaryRecipient: initialData?.txtSnapPrimaryRecipient || '', //14
				txtSnapRecipientLocation: initialData?.txtSnapRecipientLocation || '', //14

				optAssignmentOrganization: initialData?.optAssignmentOrganization || 'none', //15

				optTanf18Months: initialData?.optTanf18Months || '', //16
				optTanfAny18Months: initialData?.optTanfAny18Months || '', //16
				optTanfNotEligible: initialData?.optTanfNotEligible || '', //16
				optTanfAny9Months: initialData?.optTanfAny9Months || '', //16
				txtTanfPrimaryRecipient: initialData?.txtTanfPrimaryRecipient || '', //16
				txtTanfRecipientLocation: initialData?.txtTanfRecipientLocation || '', //16

				optExFelon: initialData?.optExFelon || '', //17
				txtConvictionDate: initialData?.txtConvictionDate || '', //17
				txtReleaseDate: initialData?.txtReleaseDate || '', //17
				optConvictionType: initialData?.optConvictionType || '', //17

				isRuralRenewalCounty: initialData?.isRuralRenewalCounty || '', //18
				isEmprowermentZone: initialData?.isEmprowermentZone || '', //18
				jobAppBirthdayRRC: initialData?.jobAppBirthdayRRC || '',
				jobAppBirthdayQSYE: initialData?.jobAppBirthdayQSYE || '', //19
				isQualifSummerYouthEmpl: initialData?.isQualifSummerYouthEmpl || false, //19
				optReceivedSSI: initialData?.optReceivedSSI || '', //20

				optLTUrecipient27weeks: initialData?.optLTUrecipient27weeks || '', //23
				txtLtuBenefitsState: initialData?.txtLtuBenefitsState || '', //23
				txtSafStartDate: initialData?.txtSafStartDate || '', //23

				txtEligibilitySources: initialData?.txtEligibilitySources || '', //last field
				signedBy: '', //signed by
			},

			validationSchema: validationSchema,
			onSubmit: (values: FrontFormData) => {
				if (onFormSubmit) {
					// setEnableValidation(true)
					onFormSubmit(values);
				}
			},
			validateOnChange: enableValidation,
			validateOnBlur: enableValidation,
			enableReinitialize: true,
		});

		const { isLoading, isSubmitted } = useSelector((state: StoreRootState) => state.formSubmit);

		const dispatch = useDispatch<AppDispatch>();

		const { errors, values, handleChange, setFieldValue, setFieldTouched } = formik;

		const addAnswers = (value: string) => {
			return {
				optVocationalRehab: value === 'optVocationalRehab',
				optEmploymentNetwork: value === 'optEmploymentNetwork',
				optVeteransAffair: value === 'optVeteransAffair',
			};
		};

		const convertedOrganization = addAnswers(values.optAssignmentOrganization);

		useImperativeHandle(
			ref,
			() => {
				const optToConverter = (value: string): boolean => {
					return value === 'yes';
				};

				return {
					async saveValues() {
						const validationErrors = await formik.validateForm(values);
						if (!Object.keys(validationErrors).length) {
							{
								setValid && setValid(true);
							}
							dispatch(
								updateFormValues({
									...convertedOrganization,
									id: id,
									chkItem1: values.chkItem1,
									chkItem2: values.chkItem2,
									chkItem3: values.chkItem3,
									chkItem4: values.chkItem4,
									chkItem5: values.chkItem5,
									chkItem6: values.chkItem6,
									ChkItem7: values.ChkItem7,
									isRuralRenewalCounty: optToConverter(values.isRuralRenewalCounty), //18
									isEmprowermentZone: optToConverter(values.isEmprowermentZone), //18
									jobAppBirthdayRRC: values.jobAppBirthdayRRC,
									isQualifSummerYouthEmpl: values.isQualifSummerYouthEmpl, //19
									jobAppBirthdayQSYE: values.jobAppBirthdayQSYE, //19
									optReceivingWages: optToConverter(values.optReceivingWages), //8
									txtDischargeDate: values.txtDischargeDate,
									txtBirthDate: values.txtBirthDate,
									txtCity: values.txtCity,
									txtContactName: values.txtContactName,
									txtContactPhone: '(916) 221-1341',
									txtContactCity: 'Folsom',
									txtContactState: 'CA',
									txtContactStreet: '704 Natoma St',
									txtContactZip: '95630',
									txtConvictionDate: values.txtConvictionDate,
									txtEligibilitySources: values.txtEligibilitySources,
									txtErCity: values.txtErCity,
									txtErName: values.txtErName,
									txtErPhone: values.txtErPhone,
									txtErState: values.txtErState,
									txtErStreet: values.txtErStreet,
									txtErZip: values.txtErZip,
									txtFEIN: values.txtFEIN,
									txtFirstName: values.txtFirstName,
									txtHireDate: values.txtHireDate,
									txtInfoDate: values.txtInfoDate,
									txtLastEmploymentDate: values.txtLastEmploymentDate,
									txtLastName: values.txtLastName,
									txtLtuBenefitsState: values.txtLtuBenefitsState,
									txtOfferDate: values.txtOfferDate,
									txtReleaseDate: values.txtReleaseDate,
									txtSSN: values.txtSSN,
									txtSafStartDate: values.txtSafStartDate,
									txtSnapPrimaryRecipient: values.txtSnapPrimaryRecipient,
									txtSnapRecipientLocation: values.txtSnapRecipientLocation,
									txtStartDate: values.txtStartDate,
									txtStartWage: values.txtStartWage,
									txtState: values.txtState,
									txtStreet: values.txtStreet,
									txtTanfPrimaryRecipient: values.txtTanfPrimaryRecipient,
									txtTanfRecipientLocation: values.txtTanfRecipientLocation,
									txtVeteranSnapPrimaryRecipient: values.txtVeteranSnapPrimaryRecipient,
									txtVeteranSnapRecipientLocation: values.txtVeteranSnapRecipientLocation,
									txtZip: values.txtZip,
									optPreviousEmployer: optToConverter(values.optPreviousEmployer),
									ddlOccupation: values.ddlOccupation,
									optVeteran: optToConverter(values.optVeteran),
									optVeteranReceivedSnap: optToConverter(values.optVeteranReceivedSnap),
									optVeteranDisability: optToConverter(values.optVeteranDisability),
									optVeteranDischarged: optToConverter(values.optVeteranDischarged),
									optVeteranUnemployed: optToConverter(values.optVeteranUnemployed),
									optSnap6Months: optToConverter(values.optSnap6Months),
									optSnap3Months: optToConverter(values.optSnap3Months),
									optTanf18Months: optToConverter(values.optTanf18Months),
									optTanfAny18Months: optToConverter(values.optTanfAny18Months),
									optTanfNotEligible: optToConverter(values.optTanfNotEligible),
									optTanfAny9Months: optToConverter(values.optTanfAny9Months),
									optExFelon: optToConverter(values.optExFelon),
									optConvictionType: optToConverter(values.optConvictionType),
									optReceivedSSI: optToConverter(values.optReceivedSSI),
									optLTUrecipient27weeks: optToConverter(values.optLTUrecipient27weeks),
								}),
							);
						} else {
							setValid && setValid(false);
						}
					},
					submitForm() {
						formik.handleSubmit();
					},
				};
			},
			[dispatch, values, id],
		);

		const onDialogOpen = async () => {
			if (!isLoading) {
				const validationErrors = await formik.validateForm(values);
				if (!Object.keys(validationErrors).length) setIsDialogOpen(true);
			}
			setEnableValidation(true);
			// setEnableValidation && setEnableValidation(true);
		};

		const onDialogClose = () => {
			setIsDialogOpen(false);
		};

		const onFormSign = (signedBy: string) => {
			setFieldValue('signedBy', signedBy);
			formik.submitForm();
			onDialogClose();
		};

		const { t } = useTranslation();

		useEffect(() => {
			if (!values.isQualifSummerYouthEmpl) {
				setFieldValue('jobAppBirthdayQSYE', '', true);
			}
		}, [values.isQualifSummerYouthEmpl, setFieldValue]);

		useEffect(() => {
			if (
				(values.isRuralRenewalCounty === 'yes' || values.isEmprowermentZone === 'yes') &&
				values.txtBirthDate
			) {
				setFieldValue('jobAppBirthdayRRC', values.txtBirthDate, false);
			}
		}, [
			values.isRuralRenewalCounty,
			values.isEmprowermentZone,
			values.txtBirthDate,
			setFieldValue,
		]);

		useEffect(() => {
			if (values.isQualifSummerYouthEmpl && values.txtBirthDate) {
				setFieldValue('jobAppBirthdayQSYE', values.txtBirthDate, false);
			}
		}, [values.isQualifSummerYouthEmpl, values.txtBirthDate, setFieldValue]);

		return (
			<form onSubmit={formik.submitForm} id='mainForm'>
				{disabled && (
					<SignFormModal open={isDialogOpen} handleClose={onDialogClose} onSendClick={onFormSign} />
				)}
				{isSubmitted && <Navigate to='/submit-success' />}
				<div className={styles.container}>
					<div className={styles.headerContainer}>
						<div className={styles.headerTitle}>
							{t('New Hire Work Opportunity Tax Credit Questionnaire')}
						</div>
					</div>
					{!hideEmployeersHeader && (
						<EmployeersHeader
							disabled={disabled}
							onChange={handleChange}
							values={{
								txtContactCity: 'Folsom',
								txtContactState: 'CA',
								txtContactStreet: '704 Natoma St',
								txtContactZip: '95630',
								txtContactName: values.txtContactName,
								txtContactPhone: '(916) 221-1341',
								txtErCity: values.txtErCity,
								txtErName: values.txtErName,
								txtErPhone: values.txtErPhone,
								txtErState: values.txtErState,
								txtErStreet: values.txtErStreet,
								txtErZip: values.txtErZip,
								txtFEIN: values.txtFEIN,
								txtHireDate: values.txtHireDate,
								txtInfoDate: values.txtInfoDate,
								txtOfferDate: values.txtOfferDate,
								txtStartDate: values.txtStartDate,
							}}
							errors={{ ...errors }}
						/>
					)}
					<JobApplicantHeader
						disabled={!disabled || !isAdminEditable}
						onChange={handleChange}
						values={{
							txtFirstName: values.txtFirstName,
							txtLastName: values.txtLastName,
							txtSSN: values.txtSSN,
							txtStreet: values.txtStreet,
							txtCity: values.txtCity,
							txtState: values.txtState,
							txtZip: values.txtZip,
						}}
						companyName={values.txtErName}
						errors={{
							...errors,
						}}
					/>
					<div className={isAdminEditable ? styles.editableContainer : styles.defaultContainer}>
						<FormCheckedLine
							id='chkItem1'
							counter='1'
							text={t(questions.chkItem1)}
							isChecked={values.chkItem1}
							onChange={handleChange}
							disabled={!(disabled && isAdminEditable)}
						/>
						<FormCheckedLineWithList
							id='chkItem2'
							counter='2'
							title={t(questions.chkItem2.title)}
							list={questions.chkItem2.list}
							isChecked={values.chkItem2}
							onChange={handleChange}
							disabled={!(disabled && isAdminEditable)}
						/>
						<FormCheckedLine
							id='chkItem3'
							counter='3'
							text={t(questions.chkItem3)}
							isChecked={values.chkItem3}
							onChange={handleChange}
							disabled={!(disabled && isAdminEditable)}
						/>
						<FormCheckedLine
							id='chkItem4'
							counter='4'
							text={t(questions.chkItem4)}
							isChecked={values.chkItem4}
							onChange={handleChange}
							disabled={!(disabled && isAdminEditable)}
						/>
						<FormCheckedLine
							id='chkItem5'
							counter='5'
							text={t(questions.chkItem5)}
							isChecked={values.chkItem5}
							onChange={handleChange}
							disabled={!(disabled && isAdminEditable)}
						/>
						<FormCheckedLineWithList
							id='chkItem6'
							counter='6'
							list={questions.chkItem6.list}
							isChecked={values.chkItem6}
							onChange={handleChange}
							disabled={!disabled || !isAdminEditable}
						/>
						<FormCheckedLine
							id='ChkItem7'
							counter='7'
							text={t(questions.ChkItem7)}
							isChecked={values.ChkItem7}
							onChange={handleChange}
							disabled={!isAdminEditable || !disabled}
						/>
					</div>
					<ConfirmLineWithAddQwestion
						counter='8'
						id='optPreviousEmployer'
						value={{
							optPreviousEmployer: values.optPreviousEmployer,
							optReceivingWages: values.optReceivingWages,
						}}
						topText={t(questions.optPreviousEmployer)}
						onChange={handleChange}
						datePickerValue={values.txtLastEmploymentDate}
						datePickerText={t(questions.txtLastEmploymentDate)}
						onDateChange={(value) => {
							setFieldValue('txtLastEmploymentDate', value, false);
							setTimeout(() => setFieldTouched('txtLastEmploymentDate', true));
						}}
						errors={{
							...errors,
							valueError: errors.optPreviousEmployer,
							optReceivingWages: errors.optReceivingWages,
							dateError: errors.txtLastEmploymentDate,
						}}
						disabled={!disabled || !isAdminEditable}
					/>

					<FormInputLine
						id='txtStartWage'
						counter='9'
						type='number'
						text={t(questions.txtStartWage)}
						value={values.txtStartWage}
						onChange={handleChange}
						error={errors.txtStartWage}
						disabled={!disabled || !isAdminEditable}
					/>
					<FormSelectLine
						id='ddlOccupation'
						counter='10'
						text={t(questions.ddlOccupation.title)}
						value={values.ddlOccupation}
						values={questions.ddlOccupation.values}
						onChange={(e) => {
							setFieldValue('ddlOccupation', e.target.value);
							setTimeout(() => setFieldTouched('ddlOccupation', true));
						}}
						error={t(errors.ddlOccupation as string)}
						disabled={!disabled || !isAdminEditable}
					/>
					<FormWithDatePicker
						counter='11'
						id='optAgeUnder40'
						topText={t(questions.optAgeUnder40)}
						onChange={handleChange}
						datePickerValue={values.txtBirthDate}
						datePickerText={t(questions.txtBirthDate)}
						onDateChange={(value) => {
							setFieldValue('txtBirthDate', value, false);
							setTimeout(() => setFieldTouched('txtBirthDate', true));
						}}
						errors={{
							dateError: errors.txtBirthDate,
						}}
						disabled={!disabled || !isAdminEditable}
					/>
					<FormVeteranQuestion
						id='txtDischargeDate'
						counter='12'
						onChange={handleChange}
						values={{
							txtDischargeDate: values.txtDischargeDate,
							optVeteran: values.optVeteran,
							optVeteranReceivedSnap: values.optVeteranReceivedSnap,
							txtVeteranSnapPrimaryRecipient: values.txtVeteranSnapPrimaryRecipient,
							txtVeteranSnapRecipientLocation: values.txtVeteranSnapRecipientLocation,
							optVeteranDisability: values.optVeteranDisability,
							optVeteranDischarged: values.optVeteranDischarged,
							optVeteranUnemployed: values.optVeteranUnemployed,
						}}
						datePickerValue={values.txtDischargeDate}
						datePickerText='Date of Discharge'
						onDateChange={(value) => {
							setFieldValue('txtDischargeDate', value, false);
							setTimeout(() => setFieldTouched('txtDischargeDate', true));
						}}
						errors={{ ...errors }}
						disabled={!disabled || !isAdminEditable}
					/>
					<FormSnapQuestion
						counter='13'
						onChange={handleChange}
						values={{
							optSnap3Months: values.optSnap3Months,
							optSnap6Months: values.optSnap6Months,
							txtSnapPrimaryRecipient: values.txtSnapPrimaryRecipient,
							txtSnapRecipientLocation: values.txtSnapRecipientLocation,
						}}
						errors={{ ...errors }}
						disabled={!disabled || !isAdminEditable}
					/>
					<FormOrganizationAssignment
						counter='14'
						onChange={handleChange}
						value={values.optAssignmentOrganization}
						error={errors.optAssignmentOrganization}
						disabled={!disabled || !isAdminEditable}
					/>
					<FormTanfQuestion
						counter='15'
						onChange={handleChange}
						values={{
							optTanf18Months: values.optTanf18Months,
							optTanfAny18Months: values.optTanfAny18Months,
							optTanfNotEligible: values.optTanfNotEligible,
							optTanfAny9Months: values.optTanfAny9Months,
							txtTanfPrimaryRecipient: values.txtTanfPrimaryRecipient,
							txtTanfRecipientLocation: values.txtTanfRecipientLocation,
						}}
						errors={{ ...errors }}
						disabled={!disabled || !isAdminEditable}
					/>
					<FormFelonyQuestion
						counter='16'
						onChange={handleChange}
						onConvictionDateChange={(value) => {
							setFieldValue('txtConvictionDate', value, false);
							setTimeout(() => setFieldTouched('txtConvictionDate', true));
						}}
						onReleaseDateChange={(value) => {
							setFieldValue('txtReleaseDate', value, false);
							setTimeout(() => setFieldTouched('txtReleaseDate', true));
						}}
						values={{
							optExFelon: values.optExFelon,
							txtConvictionDate: values.txtConvictionDate,
							txtReleaseDate: values.txtReleaseDate,
							optConvictionType: values.optConvictionType,
						}}
						errors={{
							...errors,
						}}
						disabled={!disabled || !isAdminEditable}
					/>
					<FormCheckedWithDatePicker
						id1='isRuralRenewalCounty'
						id2='isEmprowermentZone'
						id3='jobAppBirthdayRRC'
						counter='17'
						title={t(questions.title)}
						subtitle={t(questions.subtitle)}
						question1={t(questions.isRuralRenewalCounty)}
						question2={t(questions.isEmprowermentZone)}
						datePickerText={t(questions.jobAppBirthdayRRC)}
						datePickerValue={values.jobAppBirthdayRRC}
						value={values.isRuralRenewalCounty}
						value2={values.isEmprowermentZone}
						onChange={handleChange}
						onDateChange={(value) => {
							setFieldValue('jobAppBirthdayRRC', value, false);
							setTimeout(() => setFieldTouched('jobAppBirthdayRRC', true));
						}}
						errors={{
							dateError: errors.jobAppBirthdayRRC,
						}}
						disabled={!disabled || !isAdminEditable}
					/>

					<FormOneCheckedWithDatePicker
						id2='isQualifSummerYouthEmpl'
						id1='jobAppBirthdayQSYE'
						counter='18'
						title={t(questions.title19)}
						question={t(questions.isQualifSummerYouthEmpl)}
						datePickerText={t(questions.jobAppBirthdayQSYE)}
						datePickerValue={values.jobAppBirthdayQSYE}
						isChecked={values.isQualifSummerYouthEmpl}
						onChange={handleChange}
						onDateChange={(value) => {
							setFieldValue('jobAppBirthdayQSYE', value, false);
							setTimeout(() => setFieldTouched('jobAppBirthdayQSYE', true));
						}}
						errors={{
							dateError: errors.jobAppBirthdayQSYE,
						}}
						disabled={!disabled || !isAdminEditable}
					/>

					<FormYesNoLine
						id='optReceivedSSI'
						counter='19'
						text={t(questions.optReceivedSSI)}
						value={values.optReceivedSSI}
						onChange={handleChange}
						error={errors.optReceivedSSI}
						disabled={!disabled || !isAdminEditable}
						// isAdminEditable={isAdminEditable}
					/>
					<FormUnemploymentQuestion
						counter='20'
						onChange={handleChange}
						onDateChange={(value) => {
							setFieldValue('txtSafStartDate', value, false);
							setTimeout(() => setFieldTouched('txtSafStartDate', true));
						}}
						values={{
							optLTUrecipient27weeks: values.optLTUrecipient27weeks,
							txtLtuBenefitsState: values.txtLtuBenefitsState,
							txtSafStartDate: values.txtSafStartDate,
						}}
						errors={{ ...errors }}
						disabled={!disabled || !isAdminEditable}
					/>
					<div className={styles.submitButton}>
						{isLoading && <LoadingIndicator />}
						{disabled && isAdminEditable && hideEmployeersHeader && (
							<CustomIconButton
								title={t('Submit')}
								icon={<Icons.SignAndSendIcon />}
								onClick={onDialogOpen}
								disabled={!formik.isValid || isLoading}
							/>
						)}
						{/*//add rules*/}
						{!formik.isValid && hideEmployeersHeader && (
							<CustomErrorTooltip text={t('Form has errors, please fix them before submit')} />
						)}
					</div>
				</div>
			</form>
		);
	},
);

export default CustomForm;
