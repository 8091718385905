import Icons from '../../Assets';
import { FORM_STATUS } from '../../types';
import styles from './styles.module.css';
import React from "react";

type StatusLabelProps = {
    status: FORM_STATUS;
};

const StatusLabel: React.FC<StatusLabelProps> = ({ status }) => {
    return (
        <div className={styles.container}>
            {status === FORM_STATUS.CERTIFIED && (
                <>
                    <Icons.CertifiedIcon />
                    <div className={styles.certified}>Completed</div>
                </>
            )}
            {status === FORM_STATUS.PENDING && (
                <>
                    <Icons.PendingIcon />
                    <div className={styles.pending}>Unprocessed</div>
                </>
            )}
            {status === FORM_STATUS.DENIED && (
                <>
                    <Icons.DeniedIcon />
                    <div className={styles.denied}>Denied</div>
                </>
            )}
            {status === FORM_STATUS.APPROVE_WAITING && (
                <>
                    <Icons.WaitingIcon />
                    <div className={styles.waiting}>Waiting</div>
                </>
            )}
            {status === FORM_STATUS.UNKNOWN && (
                <>
                    <div className={styles.denied}>No POA</div>
                </>
            )}
        </div>
    );
};

export default StatusLabel;
