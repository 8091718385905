import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Dayjs } from 'dayjs';
import { ChangeEvent } from 'react';
import { CustomRadioGroup, CustomDatePicker, CustomErrorTooltip } from '../../';
import Icons from '../../../Assets';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type FormFelonyQuestionProps = {
	counter: string;
	values: {
		optExFelon: string;
		txtConvictionDate: string;
		txtReleaseDate: string;
		optConvictionType: string;
	};
	errors: {
		optExFelon?: string;
		txtConvictionDate?: string;
		txtReleaseDate?: string;
		optConvictionType?: string;
	};
	onChange: (e: ChangeEvent<any>) => void;
	onConvictionDateChange: (value: Dayjs | string) => void;
	onReleaseDateChange: (value: Dayjs | string) => void;
	disabled?: boolean;
};

const FormFelonyQuestion: React.FC<FormFelonyQuestionProps> = ({
	counter,
	values,
	errors,
	onChange,
	onConvictionDateChange,
	onReleaseDateChange,
	disabled = false,
}) => {
	const showAdditionalInputs = values.optExFelon === 'yes';
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<div className={styles.line}>
				<div className={styles.infoContainer}>
					<div className={styles.counter}>{counter}</div>
					<div>
						{t(
							'Were you convicted of a felony or released from prison after a felony conviction during the year before you were hired?',
						)}
					</div>
				</div>
				<div className={styles.radioQuestion}>
					<CustomRadioGroup
						groupName={'optExFelon'}
						onChange={onChange}
						value={values.optExFelon}
						error={errors.optExFelon}
						disabled={disabled}
					/>
				</div>
			</div>

			{showAdditionalInputs && (
				<>
					<div className={styles.textLine}>
						<div className={styles.datePicker}>
							<div className={styles.datePickerLabel}>{t('Date of conviction')}</div>
							<CustomDatePicker
								value={values.txtConvictionDate}
								error={errors.txtConvictionDate}
								id={'txtConvictionDate'}
								name={'txtConvictionDate'}
								onChange={onConvictionDateChange}
								disabled={disabled}
							/>
						</div>
						<div className={styles.datePicker}>
							<div className={styles.datePickerLabel}>{t('Date of release')}</div>
							<CustomDatePicker
								value={values.txtReleaseDate}
								error={errors.txtReleaseDate}
								id={'txtReleaseDate'}
								name={'txtReleaseDate'}
								onChange={onReleaseDateChange}
								disabled={disabled}
							/>
						</div>
					</div>
					<div className={styles.radioLine}>
						<div className={styles.radioLineHeader}>{t('Choose the option:')}</div>
						<div className={styles.radioGroup}>
							<RadioGroup
								name={'optConvictionType'}
								value={values.optConvictionType}
								row
								onChange={onChange}
							>
								<FormControlLabel
									control={
										<Radio
											disableRipple
											checkedIcon={<Icons.RadioCheckedIconYes />}
											icon={<Icons.RadioIcon />}
											sx={{
												'&.MuiRadio-colorPrimary': {
													color: '#0B5394',
												},
												'&.Mui-checked': {
													color: '#0B5394',
												},
												'&.Mui-disabled': {
													color: '#656565',
												},
											}}
											value='yes'
											disabled={disabled}
										/>
									}
									label={t('Federal conviction')}
								/>
								<FormControlLabel
									control={
										<Radio
											disableRipple
											checkedIcon={<Icons.RadioCheckedIconYes />}
											icon={<Icons.RadioIcon />}
											sx={{
												'&.MuiRadio-colorPrimary': {
													color: '#0B5394',
												},
												'&.Mui-checked': {
													color: '#0B5394',
												},
												'&.Mui-disabled': {
													color: '#656565',
												},
											}}
											value='no'
											disabled={disabled}
										/>
									}
									label={t('State conviction')}
								/>
							</RadioGroup>
							<CustomErrorTooltip text={errors.optConvictionType || ''} />
						</div>
					</div>
				</>
			)}
		</div>
	);
};
export default FormFelonyQuestion;
