import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import App from './App';
import { store } from './Store';
import { setupAxiosInstance } from './Api/setupAxios';
import './index.css';
import { ErrorBoundary } from "react-error-boundary";

import './18n';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HashRouter>
                <ErrorBoundary fallback={<p>Some error</p>}>
                    <App/>
                </ErrorBoundary>
            </HashRouter>
        </Provider>
    </React.StrictMode>
);

setupAxiosInstance(store);
