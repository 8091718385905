import { FormControlLabel, RadioGroup, Radio } from '@mui/material';
import React, { ChangeEvent } from 'react';

import Icons from '../../Assets';
import CustomErrorTooltip from '../CustomErrorTooltip';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type CustomRadioGroupProps = {
	disabled?: boolean;
	value?: string;
	groupName: string;
	error?: string;
	isAdminEditable?: boolean;
	onChange: (e: ChangeEvent<any>) => void;
};

interface RadioGroupValues {
	icon: React.FunctionComponent;
	value: string;
	label: string;
}

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
	disabled,
	groupName,
	onChange,
	error = '',
	value = '',
	isAdminEditable = false,
}) => {
	const { t } = useTranslation();

	const groupValues: RadioGroupValues[] = [
		{
			icon: Icons.RadioCheckedIconYes,
			value: 'yes',
			label: t('Yes'),
		},
		{
			icon: Icons.RadioCheckedIconNo,
			value: 'no',
			label: t('No'),
		},
	];

	const isErrorShown = !disabled && !isAdminEditable;

	return (
		<div className={styles.container}>
			<RadioGroup
				name={groupName}
				value={value}
				row
				onChange={onChange}
				className={styles.group}
			>
				{groupValues.map((radio) => {
					return (
						<FormControlLabel
							control={
								<Radio
									disableRipple
									checkedIcon={<radio.icon />}
									icon={<Icons.RadioIcon />}
									sx={{
										'&.MuiRadio-colorPrimary': {
											color: '#0B5394',
										},
										'&.Mui-checked': {
											color: '#0B5394',
										},
										'&.Mui-disabled': {
											color: '#656565',
										},
									}}
									value={radio.value}
								/>
							}
							label={radio.label}
							disabled={disabled}
							key={radio.label}
						/>
					);
				})}
			</RadioGroup>
			{isErrorShown && <CustomErrorTooltip text={t(error)} />}
		</div>
	);
};

export default CustomRadioGroup;
