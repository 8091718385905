import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation, useSearchParams} from 'react-router-dom';
import { getCompanyInfoByLink, submitForm } from '../../Api/Forms';
import { FrontendToBackendFormDataConverter } from '../../Common/common';
import {
    CustomForm,
    CopyrightFooter,
    Logo,
    ScrollButton,
} from '../../Components';
import useResponsive from '../../Hooks/useResponsive';
import { AppDispatch, StoreRootState } from '../../Store';
import { FrontFormData } from '../../types';
import styles from './styles.module.scss';
import {useTranslation} from "react-i18next";

const EmployeeFormPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const submitFormHandler = (values: FrontFormData) => {
        const convertedData = FrontendToBackendFormDataConverter(values);
        const withLink = {
            ...convertedData,
            url: link,
        };
        dispatch(submitForm(withLink));
    };

    const location = useLocation();
    const splittedArray = location.pathname.split('/');
    const link = splittedArray[splittedArray.length - 1];

    useEffect(() => {
        dispatch(getCompanyInfoByLink(link));
    }, [link, dispatch]);

    const { companyInfo } = useSelector(
        (state: StoreRootState) => state.formSubmit
    );

    const { Touch } = useResponsive();

    const { t, i18n } = useTranslation()

    let [searchParams] = useSearchParams();
    const language = searchParams.get('lng') || 'en'

    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language])

    return (
        <>
            <div className={styles.pageContainer}>
                <div className={styles.header}>
                    <Logo />
                </div>
                <div className={styles.separator} />
                <CustomForm
                    disabled={true}
                    isAdminEditable={true}
                    onFormSubmit={submitFormHandler}
                    companyData={companyInfo}
                />
            </div>
            <CopyrightFooter text='2023 WOTC Wiz LLC. All rights reserved' />
            <Touch>
                <ScrollButton />
            </Touch>
        </>
    );
};

export default EmployeeFormPage;
