import Icons from '../../Assets';
import { Logo } from '../../Components';
import styles from './styles.module.scss';

const FormSubmittedPage: React.FC = () => {
    return (
        <div className={styles.pageContainer}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <Logo />
                </div>
                <div className={styles.separator} />
            </div>
            <div className={styles.contentContainer}>
                <Icons.CertifiedIcon className={styles.contentIcon} />
                <div className={styles.contentText}>
                    The Form has been successfully submitted.
                </div>
                <div className={styles.contentText}>
                    (You can now close this browser.)
                </div>
            </div>
        </div>
    );
};

export default FormSubmittedPage;
