import { Dialog } from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import { CustomButton, CustomInput } from '..';
import Icons from '../../Assets';
import validationSchema from './validation';

import styles from './styles.module.scss';

interface ChangePasswordModalProps {
    open: boolean;
    handleClose: () => void;
    onChangeClick: (values: any) => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
    open,
    handleClose,
    onChangeClick,
}) => {
    const onDialogClose = () => {
        handleClose();
        formik.resetForm();
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values: any) => {
            onChangeClick(values.newPassword);
            onDialogClose();
        },
    });

    const { isValid, handleChange, values, errors, handleSubmit, dirty } =
        formik;

    const isSubmitActive = isValid && dirty;

    return (
        <Dialog
            open={open}
            onClose={onDialogClose}
            maxWidth='lg'
            classes={{ paper: styles.dialog }}
        >
            <div className={styles.container}>
                <Icons.CloseModalIcon
                    onClick={onDialogClose}
                    className={styles.icon}
                />
                <div className={styles.title}>Change password</div>
                <div className={styles.inputsContainer}>
                    <CustomInput
                        id='newPassword'
                        onChange={handleChange}
                        title='New password'
                        value={values.newPassword}
                        error={errors?.newPassword}
                        type='password'
                        maxLength={50}
                        inputClassName={styles.passwordInput}
                    />
                    <CustomInput
                        id='confirmPassword'
                        onChange={handleChange}
                        title='Confirm password'
                        value={values.confirmPassword}
                        error={errors?.confirmPassword}
                        type='password'
                        maxLength={50}
                        inputClassName={styles.passwordInput}
                    />
                </div>
                <CustomButton
                    title='Change'
                    onClickHandler={handleSubmit}
                    disabled={!isSubmitActive}
                />
            </div>
        </Dialog>
    );
};

export default ChangePasswordModal;
