import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios, axiosErrorHandler } from './setupAxios';

import { API_PATH } from './constants';

interface SignInData {
    email: string;
    password: string;
}

interface SignUpData {
    email: string;
    password: string;
    role: string;
}

interface VerifyCodeData {
    code: number;
    email: string;
}

interface SetNewPasswordData {
    userId: number;
    password1: string;
    password2: string;
}

const signIn = createAsyncThunk(
    'auth/signIn',
    async ({ email, password }: SignInData, { rejectWithValue }) => {
        try {
            const result = await axios.post(`${API_PATH}/auth/signin`, {
                email,
                password,
            });
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

const signUp = createAsyncThunk(
    'auth/signUp',
    async ({ email, password, role }: SignUpData, { rejectWithValue }) => {
        try {
            const result = await axios.post(`${API_PATH}/auth/signup`, {
                email,
                password,
                role,
            });
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

const signOut = createAsyncThunk(
    'auth/signOut',
    async (refreshToken: string, { rejectWithValue }) => {
        try {
            const result = await axios.post(`${API_PATH}/auth/signout`, {
                refreshToken,
            });
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

const restorePassword = createAsyncThunk(
    'auth/restorePassword',
    async (email: string, { rejectWithValue }) => {
        try {
            const result = await axios.post(
                `${API_PATH}/auth/restore_password`,
                {
                    email,
                }
            );
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

const verifyCode = createAsyncThunk(
    'auth/verifyCode',
    async ({ email, code }: VerifyCodeData, { rejectWithValue }) => {
        try {
            const result = await axios.post(`${API_PATH}/auth/verify_code`, {
                email,
                code,
            });
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

const setNewPassword = createAsyncThunk(
    'auth/setNewPassword',
    async (
        { userId, password1, password2 }: SetNewPasswordData,
        { rejectWithValue }
    ) => {
        try {
            const result = await axios.post(
                `${API_PATH}/auth/set_new_password`,
                {
                    userId,
                    password1,
                    password2,
                }
            );
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

export { signIn, signUp, signOut, restorePassword, setNewPassword, verifyCode };
