import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	createCompany,
	deleteCompany,
	getAllCompanies,
	getCompaniesWithSearch,
	updateCompanyInfo,
} from '../../Api/Company';
import { FORM_URL, PER_PAGE } from '../../Api/constants';
import {
	CsvData,
	deleteForm,
	DeleteFormData,
	getCSV,
	getFormsWithSearch,
	submitExistingForm,
	UpdateFormData,
	updateFormsStatus,
} from '../../Api/Forms';
import { createHr, deleteUser, getUsersWithSearch, updateUserInfo } from '../../Api/User';
import Icons from '../../Assets';
import { copyToClipboard } from '../../Common/common';
import {
	AdminCompaniesTable,
	AdminEmployeesTable,
	AdminHeader,
	CompanyInfoModal,
	CsvDownloadModal,
	CustomIconButton,
	CustomSearchInput,
	CustomTabs,
	DeleteModal,
	LoadingIndicator,
	UpdateStatusButton,
} from '../../Components';
import { CompanyMode } from '../../Components/CompanyInfoModal';
import { LOADER_VERSION } from '../../Components/LoadingIndicator';
import useDebounce from '../../Hooks/useDebounce';
import {
	clearCompany,
	Company,
	restoreClearSearch,
	selectCompany,
	setCompanyPage,
} from '../../Slices/CompanySlice';
import {
	setDirection,
	setLastLocation,
	setReportForDelete,
	setReportsPage,
} from '../../Slices/ReportsSlice';
import { HrUser, selectUser, setUserPage } from '../../Slices/UserSlice';
import { AppDispatch, StoreRootState } from '../../Store';
import { TabData } from '../../types';
import styles from './styles.module.scss';
import AdminUsersTable from '../../Components/AdminUsersTable';
import UserInfoModal, { UserMode } from '../../Components/UserInfoModal';
import LanguageModal from '../../Components/LanguageModal';
import { useTranslation } from 'react-i18next';

const AdminMainPage: React.FC<any> = ({ route }) => {
	const [companiesSearch, setCompaniesSearch] = useState('');
	const location = useLocation();
	const onCompaniesSearchChange = (e: React.ChangeEvent<any>) => {
		setCompaniesSearch(e.target.value);
	};
	const [employeesSearch, setEmployeesSearch] = useState('');
	const onEmployeesSearchChange = (e: React.ChangeEvent<any>) => {
		setEmployeesSearch(e.target.value);
	};
	const [usersSearch, setUsersSearch] = useState('');
	const onUsersSearchChange = (e: React.ChangeEvent<any>) => {
		setUsersSearch(e.target.value);
	};

	const [tabId, setTabId] = useState('companies');

	useEffect(() => {
		if (location.state) {
			setTabId('employees');
			window.history.replaceState({}, document.title);
		}
		return () => {
			location.state = null;
		};
	}, []);

	const [companyModalOpen, setCompanyModalOpen] = useState(false);
	const [userModalOpen, setUserModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [companyModalMode, setCompanyModalMode] = useState(CompanyMode.CREATE);
	const [userModalMode, setUserModalMode] = useState(UserMode.CREATE);
	const [csvModalOpen, setCsvModalOpen] = useState(false);
	const [deleteFormModalOpen, setDeleteFormModalOpen] = useState(false);
	const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { i18n } = useTranslation();

	const onUserCreateModalOpen = () => {
		setUserModalOpen(true);
		setUserModalMode(UserMode.CREATE);
	};

	const onUserCreateModalClose = () => {
		setUserModalOpen(false);
	};

	const onUserCreate = (values: HrUser) => {
		dispatch(createHr(values));
		setUserModalOpen(false);
	};

	const onCompanyCreateModalOpen = () => {
		dispatch(clearCompany());
		setCompanyModalOpen(true);
		setCompanyModalMode(CompanyMode.CREATE);
	};

	const onCompanyEditModalOpen = (id: number) => {
		setCompanyModalOpen(true);
		setCompanyModalMode(CompanyMode.EDIT);
		dispatch(selectCompany(id));
	};

	const onUserEditModalOpen = (id: number) => {
		setUserModalOpen(true);
		setUserModalMode(UserMode.EDIT);
		dispatch(selectUser(id));
	};

	const onCompanyDeleteModalOpen = (id: number) => {
		dispatch(selectCompany(id));
		setDeleteModalOpen(true);
	};

	const onUserDelete = () => {
		dispatch(deleteUser(selectedUserId));
		setDeleteUserModalOpen(false);
	};

	const onCompanyRowClick = (id: number) => {
		navigate(`/reports/company/${id}`);
	};

	const onCsvDownloadModalOpen = () => {
		setCsvModalOpen(true);
	};

	const onCompanyLinkGenerate = (url: string) => {
		copyToClipboard(`${FORM_URL}/${url}`);
	};

	const onCompanyModalClose = () => {
		setCompanyModalOpen(false);
	};

	const onDeleteModalClose = () => {
		setDeleteModalOpen(false);
	};

	const onCsvDownloadModalClose = () => {
		setCsvModalOpen(false);
	};

	const onTabChange = (id: string) => {
		setTabId(id);
	};

	useEffect(() => {
		let location = window.location.href;
		dispatch(setLastLocation(location));
	}, []);
	const showForm = (id: string) => {
		navigate(`/review/admin/${id}`);
	};

	const onCompanyCreate = (values: Company) => {
		dispatch(createCompany(values));
		setCompanyModalOpen(false);
	};

	const onCompanyEdit = (values: Company, id?: number) => {
		dispatch(
			updateCompanyInfo({
				...values,
				id: id || -1,
			}),
		);
		setCompanyModalOpen(false);
	};

	const onUserEdit = (values: HrUser, id?: number) => {
		dispatch(
			updateUserInfo({
				...values,
				id: id || -1,
			}),
		);
		setUserModalOpen(false);
	};

	const onCompanyDelete = () => {
		dispatch(deleteCompany(selectedCompanyId));
		setDeleteModalOpen(false);
	};

	const onCompanyPageChange = (page: number) => {
		dispatch(setCompanyPage(page + 1));
	};

	const onUsersPageChange = (page: number) => {
		dispatch(setUserPage(page + 1));
	};

	const onEmployeePageChange = (page: number) => {
		dispatch(setReportsPage(page + 1));
	};

	const {
		sortDirectionAsc,
		adminReports,
		reportsPage,
		totalReportsPages,
		isReportsLoading,
		selectedReportForDelete,
		isReportsStatusUpdating,
	} = useSelector((state: StoreRootState) => state.reports);

	const { currentUser, selectedUserId, totalUsersPages, users, usersPage, isUsersLoading } =
		useSelector((state: StoreRootState) => state.user);

	const [asc, setAsc] = useState<boolean>(sortDirectionAsc);

	const changeSortDirection = () => {
		setAsc(!asc);
		dispatch(setDirection(!asc));
	};

	const onFormsStatusUpdate = () => {
		const updateData: UpdateFormData = {
			refreshFunction: () =>
				getFormsWithSearch({
					direction: asc ? 'asc' : 'desc',
					page: 1,
					perPage: PER_PAGE,
					search: '',
				}),
		};

		dispatch(updateFormsStatus(updateData));
		dispatch(setReportsPage(1));
		setEmployeesSearch('');
	};

	const onFormDownload = (formId: number) => {
		console.log(`form ${formId} download clicked`);
	};

	const onFormSend = (formId: number) => {
		dispatch(submitExistingForm(formId));
	};

	const onCSVDownload = async (values: CsvData) => {
		dispatch(getCSV(values));
		setCsvModalOpen(false);
	};

	const onDeleteFormModalOpen = (formId: number) => {
		setDeleteFormModalOpen(true);
		dispatch(setReportForDelete(formId));
	};

	const onDeleteFormModalClose = () => {
		setDeleteFormModalOpen(false);
	};

	const onDeleteUserModalOpen = (id: number) => {
		setDeleteUserModalOpen(true);
		dispatch(selectUser(id));
	};

	const onDeleteUserModalClose = () => {
		setDeleteUserModalOpen(false);
	};

	const onFormDelete = () => {
		let formId = selectedReportForDelete?.id || -1;
		dispatch(setReportsPage(1));
		setEmployeesSearch('');

		const deleteData: DeleteFormData = {
			formId: formId,
			refreshFunction: () =>
				getFormsWithSearch({
					// direction: sort,
					direction: asc ? 'asc' : 'desc',
					page: 1,
					perPage: PER_PAGE,
					search: '',
				}),
		};
		dispatch(deleteForm(deleteData));
		setDeleteFormModalOpen(false);
	};

	const companyModalAction =
		companyModalMode === CompanyMode.CREATE ? onCompanyCreate : onCompanyEdit;

	const userModalAction = userModalMode === UserMode.CREATE ? onUserCreate : onUserEdit;

	const {
		allCompanies,
		companies,
		selectedCompanyId,
		currentCompany,
		companyPage,
		totalCompanyPages,
		isCompaniesLoading,
		clearSearch,
	} = useSelector((state: StoreRootState) => state.company);

	const debouncedCompaniesSearch = useDebounce(companiesSearch, 500);
	const debouncedEmployeesSearch = useDebounce(employeesSearch, 500);
	const debouncedUsersSearch = useDebounce(usersSearch, 500);

	useEffect(() => {
		if (tabId === 'employees')
			dispatch(
				getFormsWithSearch({
					// direction: sort,
					direction: asc ? 'asc' : 'desc',
					page: reportsPage,
					perPage: PER_PAGE,
					search: debouncedEmployeesSearch,
				}),
			);
	}, [dispatch, tabId, reportsPage, debouncedEmployeesSearch, asc]);

	useEffect(() => {
		if (tabId === 'users')
			dispatch(
				getUsersWithSearch({
					page: usersPage,
					perPage: PER_PAGE,
					search: debouncedUsersSearch,
				}),
			);
	}, [dispatch, tabId, usersPage, debouncedUsersSearch]);

	useEffect(() => {
		if (tabId === 'companies')
			dispatch(
				getCompaniesWithSearch({
					page: companyPage,
					perPage: PER_PAGE,
					search: debouncedCompaniesSearch,
				}),
			);
	}, [dispatch, tabId, companyPage, debouncedCompaniesSearch]);

	useEffect(() => {
		dispatch(setCompanyPage(1));
	}, [dispatch, debouncedCompaniesSearch]);

	useEffect(() => {
		dispatch(setUserPage(1));
	}, [dispatch, debouncedUsersSearch]);

	useEffect(() => {
		dispatch(getAllCompanies());
	}, [dispatch, userModalOpen]);

	useEffect(() => {
		if (clearSearch) {
			dispatch(restoreClearSearch());
			setCompaniesSearch('');
		}
	}, [clearSearch, dispatch]);

	const employeesTabComponent = (
		<>
			<div className={styles.actionComponents}>
				<div className={styles.searchContainer}>
					<CustomSearchInput
						id='companiesSearch'
						placeholder='Search by name'
						value={employeesSearch}
						onChange={onEmployeesSearchChange}
						className={styles.searchInput}
					/>
					{isReportsLoading && <LoadingIndicator size={40} version={LOADER_VERSION.CLIP} />}
				</div>
				<div className={styles.buttonsContainer}>
					<UpdateStatusButton
						title='Update status'
						onClick={onFormsStatusUpdate}
						disabled={isReportsStatusUpdating}
					/>
					<CustomIconButton
						title='CSV Download'
						onClick={onCsvDownloadModalOpen}
						icon={<Icons.TableDownloadIcon className={styles.csvIcon} />}
					/>
				</div>
			</div>
			<div className={styles.adminTableContainer}>
				<AdminEmployeesTable
					noResultsPlaceholder='No forms found'
					currentPage={reportsPage}
					values={adminReports}
					onRowClick={showForm}
					pageCount={totalReportsPages}
					onPageChange={onEmployeePageChange}
					onDownloadClick={onFormDownload}
					onSendClick={onFormSend}
					onDeleteClick={onDeleteFormModalOpen}
					onSortClick={changeSortDirection}
					isAsc={asc}
				/>
			</div>
			<CsvDownloadModal
				open={csvModalOpen}
				companies={companies}
				onAction={onCSVDownload}
				handleClose={onCsvDownloadModalClose}
			/>
			<DeleteModal
				open={deleteFormModalOpen}
				onAction={onFormDelete}
				title={`Are you sure you want to delete form '${selectedReportForDelete?.name}'?`}
				deleteButtonTitle='Delete form'
				handleClose={onDeleteFormModalClose}
			/>
		</>
	);

	const [languageModalOpen, setLanguageModalOpen] = useState(false);
	const [modalData, setModalData] = useState('');

	const companiesTabComponent = (
		<>
			<div className={styles.actionComponents}>
				<div className={styles.searchContainer}>
					<CustomSearchInput
						id='companiesSearch'
						placeholder='Search by company name'
						value={companiesSearch}
						onChange={onCompaniesSearchChange}
						className={styles.searchInput}
					/>
					{isCompaniesLoading && <LoadingIndicator size={40} version={LOADER_VERSION.CLIP} />}
				</div>

				<div className={styles.buttonsContainer}>
					<CustomIconButton
						title='Create company'
						onClick={onCompanyCreateModalOpen}
						icon={<Icons.CreateCompanyIcon />}
					/>
				</div>
			</div>
			<div className={styles.adminTableContainer}>
				<AdminCompaniesTable
					noResultsPlaceholder='No companies found'
					currentPage={companyPage}
					values={companies}
					pageCount={totalCompanyPages}
					onRowClick={onCompanyRowClick}
					onEditClick={onCompanyEditModalOpen}
					onDeleteClick={onCompanyDeleteModalOpen}
					onPageChange={onCompanyPageChange}
					onGenerateLinkClick={(url: string) => {
						setModalData(url);
						setLanguageModalOpen(true);
					}}
				/>
			</div>

			<LanguageModal
				open={languageModalOpen}
				onSubmit={() => {
					setLanguageModalOpen(false);
					onCompanyLinkGenerate(`${modalData}?lng=${i18n.language}`);
				}}
				close={() => setLanguageModalOpen(false)}
			/>

			<CompanyInfoModal
				open={companyModalOpen}
				mode={companyModalMode}
				onAction={companyModalAction}
				handleClose={onCompanyModalClose}
				company={currentCompany}
			/>
			<DeleteModal
				open={deleteModalOpen}
				handleClose={onDeleteModalClose}
				onAction={onCompanyDelete}
				title={`Are you sure you want to delete '${currentCompany?.companyName}' company?`}
				deleteButtonTitle='Delete company'
			/>
		</>
	);

	const usersTabComponent = (
		<>
			<div className={styles.actionComponents}>
				<div className={styles.searchContainer}>
					<CustomSearchInput
						id='usersSearch'
						placeholder='Search by name'
						value={usersSearch}
						onChange={onUsersSearchChange}
						className={styles.searchInput}
					/>
					{isUsersLoading && <LoadingIndicator size={40} version={LOADER_VERSION.CLIP} />}
				</div>
				<div className={styles.buttonsContainer}>
					<CustomIconButton
						title='Create user'
						onClick={onUserCreateModalOpen}
						icon={<Icons.CreateHrIcon />}
					/>
				</div>
			</div>
			<div className={styles.adminTableContainer}>
				<AdminUsersTable
					noResultsPlaceholder='No users found'
					currentPage={usersPage}
					values={users}
					onRowClick={() => {}}
					pageCount={totalUsersPages}
					onPageChange={onUsersPageChange}
					onDeleteClick={onDeleteUserModalOpen}
					onEditClick={onUserEditModalOpen}
				/>
			</div>
			<DeleteModal
				open={deleteUserModalOpen}
				onAction={onUserDelete}
				title={`Are you sure you want to delete user '${currentUser?.name}' ?`}
				deleteButtonTitle='Delete user'
				handleClose={onDeleteUserModalClose}
			/>
			<UserInfoModal
				open={userModalOpen}
				mode={userModalMode}
				onAction={userModalAction}
				handleClose={onUserCreateModalClose}
				user={currentUser}
				companies={allCompanies}
			/>
		</>
	);

	const tabs: Array<TabData> = [
		{
			name: 'Companies',
			id: 'companies',
			component: companiesTabComponent,
		},
		{
			name: 'Users',
			id: 'users',
			component: usersTabComponent,
		},
		{
			name: 'Employees',
			id: 'employees',
			component: employeesTabComponent,
		},
	];

	return (
		<>
			<div className={styles.container}>
				<AdminHeader />
				<div className={styles.content}>
					<CustomTabs tabs={tabs} selectedId={tabId} onTabChange={onTabChange} />
				</div>
			</div>
		</>
	);
};

export default AdminMainPage;
