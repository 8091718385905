import { createSlice } from '@reduxjs/toolkit';
import {createHr, deleteUser, getAllHr, getUsersWithSearch, updateUserInfo} from '../../Api/User';
import { sendNotification } from '../../Common/common';
import { NOTIFICATION_STATUS } from '../../types';
import {PER_PAGE} from "../../Api/constants";

export interface HrUser {
    id: number;
    name: string;
    email: string;
    password: string;
    companies: Array <{
        id: number
        companyName: string
    }>
}

export interface UserState {
    usersPage: number;
    totalUsersPages: number;
    currentUser: HrUser;
    users: Array<HrUser>;
    allUsers: Array<HrUser>;
    selectedUserId: number;
    isUsersLoading: boolean;
    clearSearch: boolean;
}

const initialState: UserState = {
    currentUser: {
        id: 1,
        name: '',
        email: '',
        password: '',
        companies: []
    },
    users: [],
    allUsers: [],
    selectedUserId: -1,
    usersPage: 1,
    totalUsersPages: 1,
    isUsersLoading: false,
    clearSearch: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        selectUser: (state, action) => {
            const id = action.payload;
            state.selectedUserId = id;

            const user = state.users.find((item) => item.id === id);

            state.currentUser.id = user?.id || state.currentUser.id;
            state.currentUser.name = user?.name || '';
            state.currentUser.email = user?.email || '';
            state.currentUser.companies = user?.companies || state.currentUser.companies;
        },
        setUserPage: (state, action) => {
            state.usersPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createHr.fulfilled, () => {
            sendNotification('Added new HR user!', NOTIFICATION_STATUS.SUCCESS);
        });

        builder.addCase(createHr.rejected, (state, action) => {
            sendNotification(
                (action.payload as string) || 'Error with HR user creation!',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(getAllHr.fulfilled, (state, { payload }) => {
            state.allUsers = payload;
        });

        builder.addCase(getUsersWithSearch.pending, (state) => {
            state.isUsersLoading = true;
        });

        builder.addCase(
            getUsersWithSearch.fulfilled,
            (state, { payload }) => {
                state.users = payload[0];
                state.totalUsersPages = Math.ceil(payload[1] / PER_PAGE);
                state.isUsersLoading = false;
            }
        );

        builder.addCase(getUsersWithSearch.rejected, (state) => {
            state.isUsersLoading = false;
            sendNotification(
                'Error with users loading',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(deleteUser.fulfilled, (state) => {
            sendNotification(
                'User deleted successfully',
                NOTIFICATION_STATUS.SUCCESS
            );
            state.clearSearch = true;
        });

        builder.addCase(deleteUser.rejected, (state, action) => {
            sendNotification(
                (action.payload as string) || 'Error with user deletion',
                NOTIFICATION_STATUS.ERROR
            );
        });

        builder.addCase(updateUserInfo.fulfilled, (state) => {
            sendNotification(
                'User updated',
                NOTIFICATION_STATUS.SUCCESS
            );
            state.clearSearch = true;
        });

        builder.addCase(updateUserInfo.rejected, (state, action) => {
            sendNotification(
                (action.payload as string) || 'Error with user update',
                NOTIFICATION_STATUS.ERROR
            );
        });

    },
});

export const {
    selectUser,
    setUserPage,
} = userSlice.actions;

export default userSlice.reducer;
