import { createAsyncThunk } from '@reduxjs/toolkit';
import { Company, setCompanyPage } from '../Slices/CompanySlice';

import { API_PATH, PER_PAGE } from './constants';
import { axios, axiosErrorHandler } from './setupAxios';

type FullCompanyData = Company & {
    id: number;
};

type PaginationData = {
    page: number;
    perPage: number;
};

type SearchData = PaginationData & {
    search: string;
};

const getAllCompanies = createAsyncThunk(
    'company/getAllCompanies',
    async () => {
        const result = await axios.get(`${API_PATH}/companies/get_all`);
        return result.data;
    }
);

const getCompaniesWithPagination = createAsyncThunk(
    'company/getCompaniesWithPagination',
    async (data: PaginationData) => {
        const take = data.perPage;
        const skip = (data.page - 1) * data.perPage;
        const result = await axios.get(
            `${API_PATH}/companies?take=${take}&skip=${skip}`
        );
        return result.data;
    }
);

const getCompaniesWithSearch = createAsyncThunk(
    'company/getCompaniesWithSearch',
    async (data: SearchData, { rejectWithValue }) => {
        try {
            const take = data.perPage;
            const skip = (data.page - 1) * data.perPage;
            const result = await axios.post(
                `${API_PATH}/companies/filter_by_name?take=${take}&skip=${skip}`,
                {
                    name: data.search,
                }
            );
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

const getHrCompaniesWithSearch = createAsyncThunk(
    'users/getHrCompaniesWithSearch',
    async (data: SearchData, { rejectWithValue }) => {
        try {
            const take = data.perPage;
            const skip = (data.page - 1) * data.perPage;
            const result = await axios.post(
                `${API_PATH}/users/user_companies?take=${take}&skip=${skip}`,
                {
                    name: data.search,
                }
            );
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
)

const getCompanyInfo = createAsyncThunk(
    'company/getCompanyInfo',
    async (id: number, { rejectWithValue }) => {
        try {
            const result = await axios.get(
                `${API_PATH}/companies/get_by_id?id=${id}`
            );
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

const updateCompanyInfo = createAsyncThunk(
    'company/updateCompanyInfo',
    async (data: FullCompanyData, { dispatch, rejectWithValue }) => {
        try {
            const result = await axios.post(
                `${API_PATH}/companies/update?id=${data.id}`,
                {
                    ...data,
                }
            );
            dispatch(setCompanyPage(1));
            dispatch(
                getCompaniesWithSearch({
                    page: 1,
                    perPage: PER_PAGE,
                    search: '',
                })
            );
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

const createCompany = createAsyncThunk(
    'company/create',
    async (data: Company, { dispatch, rejectWithValue }) => {
        try {
            const result = await axios.post(`${API_PATH}/companies/create`, {
                ...data,
            });
            dispatch(setCompanyPage(1));
            dispatch(
                getCompaniesWithSearch({
                    page: 1,
                    perPage: PER_PAGE,
                    search: '',
                })
            );
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

const deleteCompany = createAsyncThunk(
    'company/delete',
    async (id: number, { dispatch, rejectWithValue }) => {
        try {
            const result = await axios.delete(
                `${API_PATH}/companies/delete_by_id?id=${id}`
            );
            dispatch(setCompanyPage(1));
            dispatch(
                getCompaniesWithSearch({
                    page: 1,
                    perPage: PER_PAGE,
                    search: '',
                })
            );
            return result.data;
        } catch (e) {
            return rejectWithValue(axiosErrorHandler(e));
        }
    }
);

export {
    getHrCompaniesWithSearch,
    getAllCompanies,
    getCompanyInfo,
    updateCompanyInfo,
    createCompany,
    deleteCompany,
    getCompaniesWithPagination,
    getCompaniesWithSearch,
};
