import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { ChangeEvent } from 'react';
import Icons from '../../../Assets';
import CustomErrorTooltip from '../../CustomErrorTooltip';
import styles from './styles.module.scss';
import {useTranslation} from "react-i18next";

interface FormOrganizationAssignmentProps {
    counter: string;
    value: string;
    error?: string;
    onChange: (e: ChangeEvent<any>) => void;
    disabled?: boolean;
}

const FormOrganizationAssignment: React.FC<FormOrganizationAssignmentProps> = ({
    counter,
    value,
    error = '',
    onChange,
    disabled = false,
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.infoContainer}>
                <div className={styles.counter}>{counter}</div>
                <div>{t('By which organization where you assigned to employer?')}</div>
            </div>
            <div className={styles.optionsContainer}>
                <RadioGroup
                    id='optAssignmentOrganization'
                    name='optAssignmentOrganization'
                    value={value}
                    onChange={onChange}
                    className={styles.radioGroup}
                >
                    <FormControlLabel
                        control={
                            <Radio
                                disableRipple
                                checkedIcon={<Icons.RadioCheckedIconYes />}
                                icon={<Icons.RadioIcon />}
                                sx={{
                                    '&.MuiRadio-colorPrimary': {
                                        color: '#0B5394',
                                    },
                                    '&.Mui-checked': {
                                        color: '#0B5394',
                                    },
                                    '&.Mui-disabled': {
                                        color: '#656565',
                                    },
                                }}
                                value='optVocationalRehab'
                                disabled={disabled}
                            />
                        }
                        label={t('Vocational Rehabilitation Agency approved by a State')}
                        classes={{
                            label: styles.radioLabel,
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                disableRipple
                                checkedIcon={<Icons.RadioCheckedIconYes />}
                                icon={<Icons.RadioIcon />}
                                sx={{
                                    '&.MuiRadio-colorPrimary': {
                                        color: '#0B5394',
                                    },
                                    '&.Mui-checked': {
                                        color: '#0B5394',
                                    },
                                    '&.Mui-disabled': {
                                        color: '#656565',
                                    },
                                }}
                                value='optEmploymentNetwork'
                                disabled={disabled}
                            />
                        }
                        label={t('Employment Network under the Ticket to Work Program')}
                        classes={{
                            label: styles.radioLabel,
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                disableRipple
                                checkedIcon={<Icons.RadioCheckedIconYes />}
                                icon={<Icons.RadioIcon />}
                                sx={{
                                    '&.MuiRadio-colorPrimary': {
                                        color: '#0B5394',
                                    },
                                    '&.Mui-checked': {
                                        color: '#0B5394',
                                    },
                                    '&.Mui-disabled': {
                                        color: '#656565',
                                    },
                                }}
                                value='optVeteransAffair'
                                disabled={disabled}
                            />
                        }
                        label={t('The Department of Veterans Affairs')}
                        classes={{
                            label: styles.radioLabel,
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                disableRipple
                                checkedIcon={<Icons.RadioCheckedIconYes />}
                                icon={<Icons.RadioIcon />}
                                sx={{
                                    '&.MuiRadio-colorPrimary': {
                                        color: '#0B5394',
                                    },
                                    '&.Mui-checked': {
                                        color: '#0B5394',
                                    },
                                    '&.Mui-disabled': {
                                        color: '#656565',
                                    },
                                }}
                                value='none'
                                disabled={disabled}
                            />
                        }
                        label={t('None')}
                        classes={{
                            label: styles.radioLabel,
                        }}
                    />
                </RadioGroup>
                {!disabled && <CustomErrorTooltip text={t(error)} />}
            </div>
        </div>
    );
};
export default FormOrganizationAssignment;
