import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { signOut } from '../../Api/Auth';
import Icons from '../../Assets';
import { AppDispatch, StoreRootState } from '../../Store';
import Logo from '../Logo';
import styles from './styles.module.css';

interface AuthorizedHeaderProps {
    actions?: Array<{
        text: string;
        icon: React.ReactNode;
        onClick: () => void;
    }>;
}

const AuthorizedHeader: React.FC<AuthorizedHeaderProps> = ({ actions }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { refreshToken } = useSelector((state: StoreRootState) => state.auth);
    const defaultActions = [
        {
            text: 'Log out',
            icon: <Icons.MenuLogoutIcon className={styles.logoutIcon} />,
            onClick: () => {
                dispatch(signOut(refreshToken || ''));
            },
        },
    ];

    const menuActions = actions ? actions : defaultActions;

    const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Logo />
                <>
                    <Icons.CompanyIcon
                        onClick={handleClick}
                        className={styles.companyIcon}
                    />
                    <Menu
                        id='basic-menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClick={handleClose}
                        onClose={handleClose}
                        sx={{ width: '250px' }}
                    >
                        {menuActions.map((item) => {
                            return (
                                <MenuItem
                                    onClick={item.onClick}
                                    key={item.text}
                                >
                                    <div className={styles.itemIcon}>
                                        {item.icon}
                                    </div>
                                    <div className={styles.menuText}>
                                        {item.text}
                                    </div>
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </>
            </div>
            <div className={styles.separator} />
        </div>
    );
};

export default React.memo(AuthorizedHeader);
