import { ReactComponent as LogoIcon } from './LogoIcon.svg';
import { ReactComponent as CheckIcon } from './CheckIcon.svg';
import { ReactComponent as CompanyIcon } from './CompanyIcon.svg';
import { ReactComponent as CertifiedIcon } from './CertifiedIcon.svg';
import { ReactComponent as DeniedIcon } from './DeniedIcon.svg';
import { ReactComponent as PendingIcon } from './PendingIcon.svg';
import { ReactComponent as CheckboxIcon } from './CheckboxIcon.svg';
import { ReactComponent as CheckboxCheckedIcon } from './CheckboxCheckedIcon.svg';
import { ReactComponent as RadioIcon } from './RadioIcon.svg';
import { ReactComponent as RadioCheckedIconYes } from './RadioCheckedIconYes.svg';
import { ReactComponent as RadioCheckedIconNo } from './RadioCheckedIconNo.svg';
import { ReactComponent as SelectIcon } from './SelectIcon.svg';
import { ReactComponent as SignAndSendIcon } from './SignAndSendIcon.svg';
import { ReactComponent as CloseModalIcon } from './CloseModalIcon.svg';
import { ReactComponent as CalendarIcon } from './CalendarIcon.svg';
import { ReactComponent as CopyrightIcon } from './CopyrightIcon.svg';
import { ReactComponent as PrintIcon } from './PrintIcon.svg';
import { ReactComponent as SendIcon } from './SendIcon.svg';
import { ReactComponent as ValidationErrorIcon } from './ValidationErrorIcon.svg';
import { ReactComponent as ToastSuccessIcon } from './ToastSuccessIcon.svg';
import { ReactComponent as ToastErrorIcon } from './ToastErrorIcon.svg';
import { ReactComponent as MenuLogoutIcon } from './MenuLogoutIcon.svg';
import { ReactComponent as MenuInfoIcon } from './MenuInfoIcon.svg';
import { ReactComponent as LinkIcon } from './LinkIcon.svg';
import { ReactComponent as SearchIcon } from './SearchIcon.svg';
import { ReactComponent as BackIcon } from './BackIcon.svg';
import { ReactComponent as EmptyCompanyIcon } from './EmptyCompanyIcon.svg';
import { ReactComponent as CompanyTableDeleteIcon } from './CompanyTableDeleteIcon.svg';
import { ReactComponent as CompanyTableEditIcon } from './CompanyTableEditIcon.svg';
import { ReactComponent as CompanyTableLinkIcon } from './CompanyTableLinkIcon.svg';
import { ReactComponent as CreateCompanyIcon } from './CreateCompanyIcon.svg';
import { ReactComponent as TableDownloadIcon } from './TableDownloadIcon.svg';
import { ReactComponent as CreateHrIcon } from './CreateHrIcon.svg';
import { ReactComponent as SendFormIcon } from './SendFormIcon.svg';
import { ReactComponent as WaitingIcon } from './WaitingIcon.svg';
import { ReactComponent as SaveIcon } from './SaveIcon.svg';
import { ReactComponent as DeleteFormIcon } from './DeleteFormIcon.svg';
import { ReactComponent as ChangePasswordIcon } from './ChangePasswordIcon.svg';
import { ReactComponent as PasswordFreeIcon } from './PasswordFreeIcon.svg';
import { ReactComponent as PasswordHiddenIcon } from './PasswordHiddenIcon.svg';
import { ReactComponent as ScrollToTopIcon } from './ScrollToTopIcon.svg';
import { ReactComponent as RefreshStatusIcon } from './RefreshStatusIcon.svg';
import { ReactComponent as DetailInfoIcon } from "./DetailInfoIcon.svg";
import { ReactComponent as ArrowUpIcon } from "./ArrowUp.svg";
import { ReactComponent as ArrowDownIcon } from "./ArrowDown.svg";
import { ReactComponent as Print } from "./Print.svg";

const Icons = {
    ArrowDownIcon,
    ArrowUpIcon,
    LogoIcon,
    CheckIcon,
    CompanyIcon,
    CertifiedIcon,
    DeniedIcon,
    PendingIcon,
    CheckboxIcon,
    CheckboxCheckedIcon,
    RadioIcon,
    RadioCheckedIconNo,
    RadioCheckedIconYes,
    SelectIcon,
    SignAndSendIcon,
    CloseModalIcon,
    CalendarIcon,
    CopyrightIcon,
    PrintIcon,
    SendIcon,
    ValidationErrorIcon,
    ToastSuccessIcon,
    ToastErrorIcon,
    MenuLogoutIcon,
    MenuInfoIcon,
    LinkIcon,
    SearchIcon,
    BackIcon,
    EmptyCompanyIcon,
    CompanyTableDeleteIcon,
    CompanyTableEditIcon,
    CompanyTableLinkIcon,
    CreateCompanyIcon,
    TableDownloadIcon,
    CreateHrIcon,
    SendFormIcon,
    WaitingIcon,
    SaveIcon,
    DeleteFormIcon,
    ChangePasswordIcon,
    PasswordFreeIcon,
    PasswordHiddenIcon,
    ScrollToTopIcon,
    RefreshStatusIcon,
    DetailInfoIcon,
    Print
};

export default Icons;
