import * as yup from 'yup';

const validationSchema = yup.object().shape({
    newPassword: yup.string().required(`New password is required`),
    confirmPassword: yup
        .string()
        .required(`Confirm password is required`)
        .test(
            'are the same',
            'Passwords should be equal',
            function (confirmPassword) {
                const { newPassword } = this.parent;
                return newPassword === confirmPassword;
            }
        ),
});

export default validationSchema;
