import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StoreRootState } from '../../Store';
import { USER_TYPES } from '../../types';
import React from 'react';

type PrivateRouteProps = {
	children?: React.ReactNode;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
	const { role } = useSelector((state: StoreRootState) => state.auth);

	if (role !== USER_TYPES.ADMIN && role !== USER_TYPES.MANAGER) {
		return <Navigate to='/login' />;
	}

	return <>{children}</>;
};

export { PrivateRoute };
