import { ChangeEvent } from 'react';
import { CustomButton, CustomInput } from '../../../../Components';
import styles from './styles.module.scss';

interface VerificationCodeScreenProps {
    verificationCode: string;
    verificationCodeError?: string;
    setVerificationCode: (e: ChangeEvent<HTMLInputElement>) => void;
    onVerifyClick: () => void;
    onResendCodeClick: () => void;
}

const VerificationCodeScreen: React.FC<VerificationCodeScreenProps> = ({
    verificationCode,
    verificationCodeError,
    setVerificationCode,
    onVerifyClick,
    onResendCodeClick,
}) => {
    return (
        <div className={styles.formContainer}>
            <div className={styles.formTitle}>Verification code</div>
            <div className={styles.formSubtitle}>
                Enter the verification code we just sent you on your email
            </div>
            <div className={styles.inputsContainer}>
                <CustomInput
                    id='verificationCode'
                    title='Verification code'
                    value={verificationCode}
                    onChange={setVerificationCode}
                    error={verificationCodeError}
                    inputClassName={styles.verificationCodeInput}
                />
            </div>
            <CustomButton
                title='Verify'
                onClickHandler={onVerifyClick}
                disabled={(verificationCodeError?.length || 0) > 0}
            />
            <div className={styles.resendLink} onClick={onResendCodeClick}>
                Resend code
            </div>
        </div>
    );
};

export default VerificationCodeScreen;
