import { ChangeEvent } from 'react';
import { CustomRadioGroup, CustomInput } from '../../';
import useResponsive from '../../../Hooks/useResponsive';
import styles from './styles.module.scss';
import {useTranslation} from "react-i18next";

type FormTanfQuestionProps = {
    counter: string;
    values: {
        optTanf18Months: string;
        optTanfAny18Months: string;
        optTanfNotEligible: string;
        optTanfAny9Months: string;
        txtTanfPrimaryRecipient: string;
        txtTanfRecipientLocation: string;
    };
    errors: {
        optTanf18Months?: string;
        optTanfAny18Months?: string;
        optTanfNotEligible?: string;
        optTanfAny9Months?: string;
        txtTanfPrimaryRecipient?: string;
        txtTanfRecipientLocation?: string;
    };
    onChange: (e: ChangeEvent<any>) => void;
    disabled?: boolean;
};

const FormTanfQuestion: React.FC<FormTanfQuestionProps> = ({
    counter,
    values,
    errors,
    onChange,
    disabled = false,
}) => {
    const { Touch, Desktop } = useResponsive();
    const { t } = useTranslation();

    const showTextAreas =
        values.optTanf18Months === 'yes' ||
        values.optTanfAny18Months === 'yes' ||
        values.optTanfNotEligible === 'yes' ||
        values.optTanfAny9Months === 'yes';

    return (
        <div className={styles.container}>
            <div className={styles.line}>
                <div className={styles.infoContainer}>
                    <div className={styles.counter}>{counter}</div>
                    <div>
                        {t('Are you a member of a family that received TANF assistance for at least the last 18 months before you were hired?')}
                    </div>
                </div>
                <div className={styles.radioGroup}>
                    <CustomRadioGroup
                        groupName={'optTanf18Months'}
                        onChange={onChange}
                        value={values.optTanf18Months}
                        error={errors.optTanf18Months}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className={styles.line}>
                <div className={styles.infoContainer}>
                    <div className={styles.counter}>{counter}.1</div>
                    <div>
                        {t('Are you a member of a family that received TANF benefits for any 18 months beginning after August 5, 1997, and the earliest 18-month period beginning after August 5, 1997, ended within 2 years before you were hired?')}
                    </div>
                </div>
                <div className={styles.radioGroup}>
                    <CustomRadioGroup
                        groupName={'optTanfAny18Months'}
                        onChange={onChange}
                        value={values.optTanfAny18Months}
                        error={errors.optTanfAny18Months}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className={styles.line}>
                <div className={styles.infoContainer}>
                    <div className={styles.counter}>{counter}.2</div>
                    <div>
                        {t('Did your family stop being eligible for TANF assistance within 2 years before you were hired because a Federal or state law limited the maximum time those payments could be made?')}
                    </div>
                </div>
                <div className={styles.radioGroup}>
                    <CustomRadioGroup
                        groupName={'optTanfNotEligible'}
                        onChange={onChange}
                        value={values.optTanfNotEligible}
                        error={errors.optTanfNotEligible}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className={styles.line}>
                <div className={styles.infoContainer}>
                    <div className={styles.counter}>{counter}.3</div>
                    <div>
                        {t('Are you a member of a family that received TANF assistance for any 9 months during the 18-month period before you were hired?')}
                    </div>
                </div>
                <div className={styles.radioGroup}>
                    <CustomRadioGroup
                        groupName={'optTanfAny9Months'}
                        onChange={onChange}
                        value={values.optTanfAny9Months}
                        error={errors.optTanfAny9Months}
                        disabled={disabled}
                    />
                </div>
            </div>
            {showTextAreas && (
                <div className={styles.textLine}>
                    <Desktop>
                        <>
                            <CustomInput
                                id='txtTanfPrimaryRecipient'
                                onChange={onChange}
                                title={t('Primary recipient name')}
                                value={values.txtTanfPrimaryRecipient}
                                leftLabel
                                error={errors.txtTanfPrimaryRecipient}
                                disabled={disabled}
                                maxLength={50}
                                labelClassName={styles.label}
                            />
                            <CustomInput
                                id='txtTanfRecipientLocation'
                                onChange={onChange}
                                title={t('City and state')}
                                value={values.txtTanfRecipientLocation}
                                leftLabel
                                error={errors.txtTanfRecipientLocation}
                                disabled={disabled}
                                maxLength={50}
                                labelClassName={styles.label}
                            />
                        </>
                    </Desktop>
                    <Touch>
                        <>
                            <CustomInput
                                id='txtTanfPrimaryRecipient'
                                onChange={onChange}
                                title={t('Primary recipient name')}
                                value={values.txtTanfPrimaryRecipient}
                                error={errors.txtTanfPrimaryRecipient}
                                disabled={disabled}
                                maxLength={50}
                            />
                            <CustomInput
                                id='txtTanfRecipientLocation'
                                onChange={onChange}
                                title={t('City and state')}
                                value={values.txtTanfRecipientLocation}
                                error={errors.txtTanfRecipientLocation}
                                disabled={disabled}
                                maxLength={50}
                            />
                        </>
                    </Touch>
                </div>
            )}
        </div>
    );
};
export default FormTanfQuestion;
