import { FormControlLabel, Checkbox } from '@mui/material';

import styles from './styles.module.css';
import Icons from '../../Assets';
import { ChangeEvent } from 'react';

type CustomRadioCheckboxProps = {
    id: string;
    label?: string;
    disabled?: boolean;
    checked: boolean;
    onChange: (e: ChangeEvent<any>) => void;
};

const CustomRadioCheckbox: React.FC<CustomRadioCheckboxProps> = ({
    id,
    label = '',
    checked,
    disabled = false,
    onChange,
}) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    id={id}
                    icon={<Icons.RadioIcon />}
                    checkedIcon={<Icons.RadioCheckedIconYes />}
                    checked={checked}
                    onChange={onChange}
                    sx={{
                        '&.MuiCheckbox-colorPrimary': {
                            color: '#0B5394',
                        },
                        '&.Mui-checked': {
                            color: '#0B5394',
                        },
                        '&.Mui-disabled': {
                            color: '#656565',
                        },
                    }}
                    style={{ padding: 10 }}
                    disabled={disabled}
                />
            }
            label={label}
            classes={{
                label: styles.label,
            }}
            sx={{ maxHeight: 30 }}
        />
    );
};

export default CustomRadioCheckbox;
