import React, { PropsWithChildren } from 'react';
import { useMediaQuery } from 'react-responsive';

// $MAX_MOBILE_WIDTH: 375px;
// $MAX_TABLET_WIDTH: 1024px;
// $MIN_TABLET_WIDTH: 376px;
// $MIN_DESKTOP_WIDTH: 1025px;

// const MAX_MOBILE_WIDTH = 375;
const MAX_MOBILE_WIDTH = 420;
// const MIN_TABLET_WIDTH = 376;
const MIN_TABLET_WIDTH = 421;
const MAX_TABLET_WIDTH = 1024;
const MIN_DESKTOP_WIDTH = 1025;

const Mobile: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH });
    return <>{isMobile ? children : null}</>;
};

const Desktop: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH });
    return <>{isDesktop ? children : null}</>;
};

const Tablet: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const isTablet = useMediaQuery({
        minWidth: MIN_TABLET_WIDTH,
        maxWidth: MAX_TABLET_WIDTH,
    });
    return <>{isTablet ? children : null}</>;
};

const Default: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: MAX_MOBILE_WIDTH + 1 });
    return <>{isNotMobile ? children : null}</>;
};

const Touch: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const isNotDesktop = useMediaQuery({ maxWidth: MAX_TABLET_WIDTH });
    return <>{isNotDesktop ? children : null}</>;
};

export default function useResponsive() {
    return { Mobile, Tablet, Desktop, Touch, Default };
}
