import {FORM_STATUS} from '../../types';
import {CustomTablePagination} from '..';
import StatusLabel from '../StatusLabel';
import styles from './styles.module.scss';
import Icons from "../../Assets";
import React from "react";

type HrFormTableProps = {
    values: Array<{
        id: number;
        appId: string;
        name: string;
        company: {
            city: string;
            companyName: string;
            contactName: string;
            fein: string;
            id: number;
            phone: string;
            state: string;
            street: string;
            url: string;
            zip: string;
        } | null;
        fein: string;
        ssn: string;
        postmarkDate: string;
        status: FORM_STATUS;
        determinationDate: string;
        isAgeBetween: boolean;
        groupType: string;
    }>;
    pageCount: number;
    currentPage: number;
    noResultsPlaceholder: string;
    onRowClick: (id: string) => void;
    onPageChange: (page: number) => void;
    onDownloadClick: (id: string) => void;
    onSortClick: () => void;
    isAsc: boolean;
};

const HrFormTable: React.FC<HrFormTableProps> = ({
                                                     values,
                                                     pageCount,
                                                     currentPage,
                                                     noResultsPlaceholder,
                                                     onRowClick,
                                                     onPageChange,
                                                     onSortClick,
                                                     isAsc
                                                 }) => {
    const pageChangeHandler = (selectedItem: { selected: number }) => {
        onPageChange(selectedItem.selected);
    };

    const isEmptyContainer = values.length === 0;

    return (
        <div className={styles.container}>
            <div className={styles.tableContainer}>
                {!isEmptyContainer && (
                    <table className={styles.table}>
                        <thead>
                        <tr className={styles.headerRow}>
                            <th className={styles.headerCell}>App ID</th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Name
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Company
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                FEIN
                            </th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                SSN
                            </th>
                            <th onClick={onSortClick}
                                className={styles.headerCell}
                                align='center'
                            >
                                <div className={styles.clickable}>
                                    <span>Date received</span>
                                    {isAsc ? <Icons.ArrowUpIcon style={{flexShrink: 0}}/> :
                                        <Icons.ArrowDownIcon style={{flexShrink: 0}}/>}
                                </div>

                            </th>
                            <th className={styles.headerCell}>Status</th>
                            <th
                                className={styles.headerCell}
                                align='center'
                            >
                                Determination Date
                            </th>

                        </tr>
                        </thead>

                        <tbody>
                        {values.map((item) => {
                            // const canBeDownloaded =
                            //     item.status === FORM_STATUS.CERTIFIED ||
                            //     item.status === FORM_STATUS.DENIED;
                            return (
                                <tr
                                    key={item.appId + item.name}
                                    className={styles.tableRow}
                                    onClick={() =>
                                        onRowClick(item.id.toString())
                                    }
                                >
                                    <td className={styles.cell}>
                                        {item.appId}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item.name}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item?.company?.companyName}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item.fein}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item.ssn}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {new Date(
                                            item.postmarkDate
                                        ).toLocaleDateString('en-US')}
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        <StatusLabel status={item.status}/>
                                    </td>
                                    <td
                                        className={styles.cell}
                                        align='center'
                                    >
                                        {item.determinationDate
                                            ? new Date(
                                                item.determinationDate
                                            ).toLocaleDateString('en-US')
                                            : 'In progress'}
                                    </td>
                                    {/*<td*/}
                                    {/*    className={styles.cell}*/}
                                    {/*    align='center'*/}
                                    {/*>*/}
                                    {/*    {canBeDownloaded && (*/}
                                    {/*        <Icons.TableDownloadIcon*/}
                                    {/*            className={*/}
                                    {/*                styles.downloadIcon*/}
                                    {/*            }*/}
                                    {/*            onClick={(e) =>*/}
                                    {/*                downloadClickHandler(*/}
                                    {/*                    e,*/}
                                    {/*                    item.appId*/}
                                    {/*                )*/}
                                    {/*            }*/}
                                    {/*        />*/}
                                    {/*    )}*/}
                                    {/*</td>*/}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                )}
                {isEmptyContainer && (
                    <div className={styles.noResultsFound}>
                        {noResultsPlaceholder}
                    </div>
                )}
            </div>

            <CustomTablePagination
                currentPage={currentPage}
                onPageChange={pageChangeHandler}
                pageCount={pageCount}
            />
        </div>
    );
};

export default HrFormTable;
