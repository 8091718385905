import {Dayjs} from 'dayjs';
import React, {ChangeEvent} from 'react';
import {CustomDatePicker, CustomRadioGroup} from '../../';
import styles from './styles.module.scss';


interface ConfirmLineWithAddQuestionProps {
    id: string;
    counter: string;
    topText: string;
    datePickerText: string;
    value: {
        optPreviousEmployer: string;
        optReceivingWages: string;
    }
    datePickerValue: string;
    onChange: (e: ChangeEvent<any>) => void;
    onDateChange: (value: Dayjs | string) => void;
    errors: {
        valueError?: string;
        dateError?: string;
        optReceivingWages?: string;
    };
    disabled?: boolean;
}

const ConfirmLineWithAddQuestion: React.FC<ConfirmLineWithAddQuestionProps> = ({
                                                                                 id,
                                                                                 counter,
                                                                                 topText,
                                                                                 datePickerText,
                                                                                 value,
                                                                                 datePickerValue,
                                                                                 onChange,
                                                                                 onDateChange,
                                                                                 errors,
                                                                                 disabled = false,
                                                                             }) => {
    return (
        <div className={styles.container}>
            <div className={styles.line}>
                <div className={styles.counter}>{counter}</div>
                <div className={styles.infoContainer}>
                    <div className={styles.formLine}>
                        <div>{topText}</div>
                        <CustomRadioGroup
                            groupName={id}
                            onChange={onChange}
                            value={value.optPreviousEmployer}
                            error={errors.valueError}
                            disabled={disabled}
                        />
                    </div>

                    {value.optPreviousEmployer === 'yes' && (
                        <div className={styles.dateLine}>
                            <div className={styles.datePickerText}>
                                {datePickerText}
                            </div>
                            <CustomDatePicker
                                value={datePickerValue}
                                id={id}
                                name={id}
                                onChange={onDateChange}
                                error={errors.dateError}
                                disabled={disabled}
                            />
                        </div>
                    )}
                </div>
            </div>

            {value.optPreviousEmployer === 'no' && (
                <div className={styles.line}>

                    <div className={styles.counter}>{counter}.1</div>
                    <div className={styles.infoContainer}>
                        <div className={styles.formLine}>
                            <div>
                                Were you receiving any wages during the last six months <span className={styles.underline}>right before your hire</span>?
                            </div>

                            <div className={styles.radioGroup}>
                                <CustomRadioGroup
                                    groupName={'optReceivingWages'}
                                    onChange={onChange}
                                    value={value.optReceivingWages}
                                    error={errors.optReceivingWages}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </div>
    );
};
export default ConfirmLineWithAddQuestion;
