import { ChangeEvent } from 'react';
import { CustomRadioGroup } from '../../';
import styles from './styles.module.scss';

interface FormYesNoLineProps {
    id: string;
    counter: string;
    text: string;
    value: string;
    error?: string;
    disabled?: boolean;
    isAdminEditable?: boolean;
    onChange: (e: ChangeEvent<any>) => void;
}

const FormYesNoLine: React.FC<FormYesNoLineProps> = ({
    id,
    counter,
    text,
    value,
    error = '',
    disabled = false,
    isAdminEditable = false,
    onChange,
}) => {
    return (
        <div
            className={
                isAdminEditable
                    ? styles.adminEditableContainer
                    : styles.container
            }
        >
            <div className={styles.infoContainer}>
                <div className={styles.counter}>{counter}</div>
                <div className={styles.formLine}>
                    <div className={styles.text}>{text}</div>
                    <CustomRadioGroup
                        groupName={id}
                        onChange={onChange}
                        value={value}
                        error={error}
                        disabled={disabled}
                        isAdminEditable={isAdminEditable}
                    />
                </div>
            </div>
        </div>
    );
};
export default FormYesNoLine;
