import * as yup from 'yup';

const validationSchema = yup.object().shape({
    name: yup.string().required('Required'),
    email: yup.string().email().required('Required'),
    password: yup
        .string()
        .min(4, 'Must be more than 3 symbols')
        .max(16, 'Must be less than 17 symbols')
        .required('Required'),
    companyName: yup
        .array()
});

export default validationSchema;
