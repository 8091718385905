import React from 'react';
import styles from './styles.module.scss';

interface CustomTabsProps {
    tabs: Array<{
        id: string;
        name: string;
        component: React.ReactNode;
    }>;
    selectedId: string;
    onTabChange: (id: string) => void;
}

const CustomTabs: React.FC<CustomTabsProps> = ({
    tabs,
    selectedId,
    onTabChange,
}) => {
    const selectedComponentIndex = tabs.findIndex(
        (item) => item.id === selectedId
    );

    return (
        <div className={styles.mainContainer}>
            <div className={styles.container}>
                {tabs.map((item) => {
                    const isSelected = item.id === selectedId;
                    return (
                        <div
                            className={
                                isSelected ? styles.selectedItem : styles.item
                            }
                            onClick={() => onTabChange(item.id)}
                            key={item.id}
                        >
                            {item.name}
                        </div>
                    );
                })}
            </div>

            <div className={styles.selectedComponentContainer}>
                {tabs[selectedComponentIndex].component}
            </div>
        </div>
    );
};

export default CustomTabs;
