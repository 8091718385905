import * as yup from 'yup';
import { US_STATE_CODES } from '../../Common/common';

const validationSchema = yup.object().shape({
	companyName: yup
		.string()
		.matches(
			/^[a-z0-9 ,.?'";:!@#$%&*()_=+-]+$/i,
			`Company name may only contain letters, numbers, and the following: , . ? / ' " ; : ! @ # $ % & * ( ) - _ = +`,
		)
		.required(
			`Company name is required and may only contain letters, numbers, and the following: , . ? / ' " ; : ! @ # $ % & * ( ) - _ = +`,
		),
	fein: yup
		.string()
		.matches(/^(\d{2}-\d{7})$/, 'Must be in format (xx-xxxxxxx)')
		.max(10, 'Must be max 10 symbols')
		.required('Required'),
	state: yup
		.string()
		.required('Required')
		.min(2, 'Must be exactly 2 symbols')
		.max(2, 'Must be exactly 2 symbols')
		.required(
			'Postal state code is required. US State code must be 2 letter abbreviation of US State or territory.',
		)
		.test(
			'is valid US state',
			'US State code must be 2 letter abbreviation of US State or territory.',
			(value) => {
				return US_STATE_CODES.includes(value?.toUpperCase() || '');
			},
		),
	city: yup
		.string()
		.matches(
			/^[a-z .'-]+$/i,
			`City may only contain letters, space, period, apostrophe, and/or dash.`,
		)
		.required(`City may only contain letters, space, period, apostrophe, and/or dash.`),
	zip: yup
		.string()
		.matches(/^[0-9]+$/, 'Must be only digits')
		.min(5, 'Must be exactly 5 digits')
		.max(5, 'Must be exactly 5 digits')
		.required('Required'),
	street: yup
		.string()
		.matches(
			/^[a-z0-9 ,.'\-#/()]+$/i,
			`Street adress may only contain letters, numbers and the following: , . ' - # / ( )`,
		)
		.required(
			`Street adress is required and may only contain letters, numbers and the following: , . ' - # / ( )`,
		),
	contactName: yup
		.string()
		.max(50)
		.required(
			`Contact name is required and may only contain letters, space, period, apostrophe and/or dash`,
		),
	phone: yup
		.string()
		.matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Must be in format ((xxx) xxx-xxxx)')
		.max(14, 'Must be max 14 symbols')
		.required('Required'),
});

export default validationSchema;
