import { Dialog } from '@mui/material';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { CustomCheckbox, CustomButton, CustomInput } from '..';
import Icons from '../../Assets';
import validationSchema from './validation';
import useResponsive from '../../Hooks/useResponsive';
import styles from './styles.module.scss';

interface SignFormModalProps {
	open: boolean;
	handleClose: () => void;
	onSendClick: (value: string) => void;
}

const SignFormModal: React.FC<SignFormModalProps> = ({ open, handleClose, onSendClick }) => {
	const [isChecked, setIsChecked] = useState(false);

	const onCheckboxClick = () => {
		setIsChecked(!isChecked);
	};

	const onDialogClose = () => {
		handleClose();
		setIsChecked(false);
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			signedBy: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values: any) => {
			onSendClick(values.signedBy);
		},
	});

	const { isValid, handleChange, values, errors, handleSubmit } = formik;

	const isSubmitActive = isChecked && isValid;

	const { Touch, Desktop } = useResponsive();

	return (
		<Dialog open={open} onClose={onDialogClose} maxWidth='lg' classes={{ paper: styles.dialog }}>
			<div className={styles.container}>
				<Icons.CloseModalIcon onClick={onDialogClose} className={styles.icon} />
				<div className={styles.title}>Please sign your name</div>
				<div className={styles.description}>
					Your typed name will be used as a digital signature.
				</div>
				<div className={styles.signedByContainer}>
					<Touch>
						<CustomInput
							id='signedBy'
							onChange={handleChange}
							title='Signed by'
							value={values.signedBy}
							error={errors?.signedBy}
							maxLength={50}
							inputClassName={styles.signedByInput}
							containerClassName={styles.signedByInputContainer}
						/>
					</Touch>
					<Desktop>
						<CustomInput
							id='signedBy'
							onChange={handleChange}
							title='Signed by'
							value={values.signedBy}
							error={errors?.signedBy}
							leftLabel
							maxLength={50}
							containerClassName={styles.signedByContainer}
							labelClassName={styles.label}
						/>
					</Desktop>
				</div>
				<div className={styles.checkbox}>
					<CustomCheckbox
						label='By clicking “Submit” you certify that everything entered is correct and true to the best of your knowledge'
						checked={isChecked}
						onChange={onCheckboxClick}
					/>
				</div>
				<CustomButton title='Submit' onClickHandler={handleSubmit} disabled={!isSubmitActive} />
			</div>
		</Dialog>
	);
};

export default SignFormModal;
