import * as yup from 'yup';

const validationSchema = yup.object().shape({
    companyName: yup.string().required('Required'),
    fromDate: yup
        .date()
        .max(new Date(), `From date shouldn't be the date in future`)
        .typeError('Should be a valid date in MM/DD/YYYY format')
        .required('Required'),
    thruDate: yup
        .date()
        .max(new Date(), `To date shouldn't be the date in future`)
        .typeError('Should be a valid date in MM/DD/YYYY format')
        .test(
            'Not before from date',
            'To date must be on or after the from date',
            (value, context) => {
                const endValue = value || '';
                const startValue = context.parent.fromDate;
                return endValue >= startValue;
            }
        )
        .required('Required'),
});

export default validationSchema;
